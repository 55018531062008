import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import BasicInfo from "./BasicInfo/index"
import Sustainability from "./Sustainability/index"
import Management from "./Management";
import Scenarios from "./Scenarios";
import Productivity from "./Productivity";
import * as Theme from "../../../statics/theme"

function ExpandedPages({expandedPage,closePage,selectedShed,shedData}) {
  if(!expandedPage)
  {
    return <div/>
  }

  var page = <div/>
  switch(expandedPage)
  {
    case "basicInfo":
      page = <BasicInfo selectedShed={selectedShed} shedData={shedData} />
      break;
    case "sustainability":
      page = <Sustainability selectedShed={selectedShed} shedData={shedData} />
      break;
    case "management":
      page = <Management selectedShed={selectedShed} shedData={shedData} />
      break;
    case "scenarios":
      page = <Scenarios selectedShed={selectedShed} shedData={shedData} />
      break;
    case "productivity":
      page = <Productivity selectedShed={selectedShed} shedData={shedData} />
      break;
    default:
      page = <div/>
      break;
  }
  return (
    <div style={{position:"absolute",top:0,left:318,width:730,zIndex:10000000,height:"100%",display:"flex",flexDirection:"column",pointerEvents:"none"}}>
              {/* <div style={{position:'absolute',height:'100%',left:730,top:80,width:'100%',backgroundColor:'#161B26',opacity:.7}}/> */}

        <div style={{flex:1,marginTop:80,boxShadow:"1px 0px 20px rgba(48, 68, 120, 0.5)",backgroundColor:"white",pointerEvents:"all"}}>
          <div style={{position:"absolute",top:90,right:10,padding:5,cursor:"pointer"}} onClick={closePage}>
            <CloseIcon style={{color:"#3D414B",fontSize:20,color:Theme.BLACK_4}}/>
          </div>
          {page}
        </div>
    </div>
  );
}
export default ExpandedPages;
