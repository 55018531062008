import "./App.css";
import MainPage from "./containers/MainPage";
import Amplify from "aws-amplify";
import * as auth_statics from "./statics/auth";
import { BrowserRouter, Routes, Route,Navigate } from "react-router-dom";
import store from "./redux";
import { Provider } from "react-redux";

//Configure AWS Cognito, our current authentication provider
//This should be done here, at the top-level, because it is used in every sub-level page
Amplify.configure({
  Auth: {
    userPoolId: auth_statics.AWS_COGNITO_USER_POOL_ID,
    region: auth_statics.AWS_COGNITO_REGION,
    userPoolWebClientId: auth_statics.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

function App() {
  return (
    <div className="App" style={{ height: "100%", width: "100%" }}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<MainPage/>} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
