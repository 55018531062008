import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotateLabels from 'chartjs-plugin-annotation'
import * as Theme from "../../../../statics/theme"
import 'chart.js/auto'
import ShedsApi from "../../../../api/shedsService";


import { Line } from 'react-chartjs-2';

// let closeDataPoints = [] 
// function checkForClose(value, index, dataset) {
//   let close = false;
//   for (let i = 0; i < dataset.length; i++) {
//       if(i !== index) {
//           let compareValue = dataset[i];
//           if (Math.abs(compareValue - value) < (0.05 * value)) {
//               close = true;
//               break;
//           }
//       }
//   }
//   return close;
// }


// const options =  {
//   responsive: true,
//   maintainAspectRatio:true,
//   plugins: {
//     "tooltip": {
//       "enabled": false
//     },
//     legend: {
//       display:false
//     },
//     title: {
//       display: false,
//     },
//     datalabels: {
//       display: true,
//       color: function(context) {
//           return context.dataset.borderColor
//       },
//       formatter: function(value,{dataIndex}) {
//           return parseInt(value*100)
//       },
//       font: function(context) {
//           return {
//             size:12,
//             weight:'500'
//           }
//       },
//       anchor: "end",
//       offset: -23,
//       align: "start"
//     }
//   },
//   scales: {
//     y: {
//         max: Math.max(...data.datasets[0].data);
       
//         ticks: {
//             // Include a dollar sign in the ticks
//             callback: function(value, index, ticks) {
//               if(Number.isInteger(value*10) && value >= 0)
//               {
//                 return parseInt(value*100);
//               }
//             },
//             color:'#B4B8C4',
//         },
//         grid: {
//           borderColor:'#DCE0E7',
//           color:'#DCE0E7',
//           borderWidth:1,
//           lineWidth:1
//         },
//         beginAtZero:false,
//     },
//     x: {
//         grid: {
//           borderColor:'#DCE0E7',
//           color:'#DCE0E7',
//           borderWidth:0,
//           lineWidth:0
//         }
//     },
//   },
// };


const ChangeBox = ({label,managementData}) => {
  if(!managementData)
  {
    return <div/>
  }

  var averageVal = managementData["last_five_years"] ? managementData["last_five_years"] : 0

  if(averageVal < .10 || averageVal > -.10)
  {
    averageVal = parseInt(averageVal*1000)/10
  }else {
    averageVal = parseInt(averageVal*100)
  }

    return (
      <div style={{borderRadius:10,boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)',border:'1px solid #DCE0E7',height:137,flex:1,display:'flex',flexDirection:'column'}}>
        <div style={{width:'100%',display:'flex'}}>
          <label style={{marginLeft:24,marginTop:14,marginRight:24,color:Theme.BLACK_4,fontSize:16,fontWeight:'500',textAlign:'left'}}>{label + " Adoption"}</label>
        </div>
        <div style={{flex:1}}/>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems: "center", marginBottom:10}}>
          <label style={{marginLeft:24,color:Theme.DARK_GREEN_1,fontWeight:'700',fontSize:56}}>{averageVal + "%"}</label>
          <div style={{flex:1}}/>
          <div style={{marginBottom:10,display:'flex',marginRight:21,display:'flex',flexDirection:'column',alignItems:'center'}}>
            <label style={{color:Theme.BLACK_4,fontWeight:'500',fontSize:14, marginLeft: 2, marginTop: 15}}>average over last 5 years</label>
          </div>
        </div>
      </div>
    )
}
const GrowthBox = ({label,managementData}) => {
  if(!managementData)
  {
    return <div/>
  }

  var averageVal = managementData["annual_change"] ? managementData["annual_change"] : 0

  var prefix = "+"
  if(averageVal < 0)
  {
    prefix = ""
  }
  if(averageVal < .10 || averageVal > -.10)
  {
    averageVal = parseInt(averageVal*1000)/10
  }else {
    averageVal = parseInt(averageVal*100)
  }
  if (averageVal > 0) {
    return (
      <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',marginLeft:12,borderRadius:10,boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)',border:'1px solid #DCE0E7',height:137,flex:1}}>
        <div style={{width:'100%',display:'flex'}}>
          <label style={{marginLeft:24,maxWidth:150,marginTop:14,marginRight:24,color:Theme.BLACK_4,fontSize:16,fontWeight:'500',textAlign:'left'}}>{"Change in Adoption"}</label>
        </div>
        <div style={{flex:1}}/>
        <div style={{marginBottom:10}}>
        <div style={{display:'flex',flexDirection:'row', alignItems:'center', marginLeft:24}}>
          <label style={{color:Theme.DARK_GREEN_1,fontWeight:'700',fontSize:56}}>{prefix + averageVal + "%"}</label>
          <div style={{flex:1}}/>
          <div style={{marginBottom:10,display:'flex',marginRight:21,display:'flex',flexDirection:'column',alignItems:'center'}}>
            <label style={{color:Theme.BLACK_4,fontWeight:'500',fontSize:14, marginLeft: 2, marginTop: 15}}>within last 5 years</label>
          </div>
        </div>
        </div>
      </div>
    )
  } else {
    return (
      <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',marginLeft:12,borderRadius:10,boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)',border:'1px solid #DCE0E7',height:137,flex:1}}>
        <div style={{width:'100%',display:'flex'}}>
          <label style={{marginLeft:24,maxWidth:150,marginTop:14,marginRight:24,color:Theme.BLACK_4,fontSize:16,fontWeight:'500',textAlign:'left'}}>{"Change in Adoption"}</label>
        </div>
        <div style={{flex:1}}/>
        <div style={{marginBottom:10}}>
        <div style={{display:'flex',flexDirection:'row', alignItems:'center', marginLeft:24}}>
          <label style={{color:Theme.RED_1,fontWeight:'700',fontSize:56}}>{prefix + averageVal + "%"}</label>
          <div style={{marginBottom:10,display:'flex',marginRight:21,display:'flex',flexDirection:'column',alignItems:'center'}}>
            <label style={{color:Theme.BLACK_4,fontWeight:'500',fontSize:14, marginLeft: 2, marginTop: 15}}>within last 5 years</label>
          </div>
        </div>
        </div>
      </div>
    )
  }

}

const Graph = ({managementData}) =>{
  if(!managementData || !managementData["adoption"])
  {
    return
  }
  var data = {
    labels:Object.keys(managementData["adoption"]),
    datasets: [
      {
        data: Object.keys(managementData["adoption"]).map(year=>managementData["adoption"][year]["shed"]),
        borderRadius:6,
        borderColor: Theme.DARK_GREEN_1,
        tension:.1,
        fill:false,
        pointBackgroundColor:Theme.DARK_GREEN_1
      },
      {
        data: Object.keys(managementData["adoption"]).map(year=>managementData["adoption"][year]["national"]),
        borderRadius:6,
        // borderColor: Theme.LIGHT_BLUE_1,
        borderColor: Theme.POMERANGE_ORANGE,
        borderDash: [5,5],
        tension:.1,
        fill:false,
        // only displaying 2017 of "national" dataset
        pointRadius: function(context) {
          if (context.dataIndex === 0) {
            return 3
          } 
          return 0
        },
        pointBackgroundColor: Theme.POMERANGE_ORANGE,
      },
    ]
  };
  const options =  {
    layout: {
      padding: {
          left: 10,
          right: 20,
          top: 20,
      }
    },
    responsive: true,
    maintainAspectRatio:true,
    plugins: {
      "tooltip": {
        "enabled": false
      },
      legend: {
        display:false
      },
      title: {
        display: false,
      },
      datalabels: {
        display: function(context) {
          if (context.datasetIndex === 1 && context.dataIndex != 0) {
            return false
          }
          return true 
        },
        color: function(context) {
            return context.dataset.borderColor
        },
        formatter: function(value,{dataIndex}) {
            if (value > 0.1) {
              return parseInt(value*100)
            } else {
              return (value*100).toFixed(1)
            }
        },
        font: function(context) {
            return {
              size:12,
              weight:'500'
            }
        },
        anchor: "end",
        // alternating data point labels on top/bottom of the lines
        offset: function(context) {
          if (context.datasetIndex === 1) {
            // national (below)
            return 10;
          } else {
            // shed (above)
            return -23;
          }
        },
        align: "start"
      }
    },
    scales: {
      y: {
          max: Math.max(Math.max(...data.datasets[0].data), Math.max(...data.datasets[1].data)) * 1.4,
          min: Math.min(Math.min(...data.datasets[0].data), Math.min(...data.datasets[1].data)) * 0.6 - 0.005,
          ticks: {
              padding: 20, 
              // Include a dollar sign in the ticks
              callback: function(value, index, ticks) {
                if(Number.isInteger(value*100) && value > 0)
                {
                  return parseInt(value*100);
                }
              },
              // Commented this to keep font color of the y-axis ticks same
              // color:'#B4B8C4',
          },
          grid: {
            borderColor:'#DCE0E7',
            color:'#DCE0E7',
            borderWidth:1,
            lineWidth:1
          },
          beginAtZero:true,
      },
      x: {
          grid: {
            borderColor:'#DCE0E7',
            color:'#DCE0E7',
            borderWidth:0,
            lineWidth:0
          }
      },
    },
  };
  return (
    <div style={{flex:1,height:300,marginTop:30,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
      <label style={{color:'#999DA9',fontSize:12,fontWeight:'500',float:'left',marginLeft:30}}>Adoption %</label>
      <div style={{width:'90%',height:330}}>
        <Line options={options} plugins={[ChartDataLabels]} data={data}/>
      </div>
      <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginTop:15}}>
        <div style={{backgroundColor:Theme.POMERANGE_ORANGE,width:16,height:16,borderRadius:16}}/>
        <label style={{fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4,marginLeft:8}}>National</label>
        <div style={{backgroundColor:Theme.DARK_GREEN_1,width:16,height:16,borderRadius:16,marginLeft:35}}/>
        <label style={{fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4,marginLeft:8}}>This shed</label>
      </div>
    </div>
  )
} 

function ManagementImage({image_key}) {
  const [imageUrl, setImageURL] = useState(null);

  useEffect(() => {
    // Make the API call to fetch the image URL
    ShedsApi.getShedImage(image_key)
    .then(response => {
      // console.log(response.image_url)
      setImageURL(response.image_url)
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);
  // Three different images are hardcoded now for mgmt page demo, change in future. 
  if (image_key == "jpg/no_till.jpg") {
    return (
      <div>
        <div style={{ width: '100%' }}>
          {imageUrl && <img src={imageUrl} style={{ width: '100%', objectFit: 'contain' }} />}
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'left', justifyContent: 'left', marginTop:15}}>
          <div style={{backgroundColor:'#00bfff',width:16,height:16,borderRadius:16}}/>
          <label style={{fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4,marginLeft:8}}>No-till</label>
        </div>
      </div>
    );
  } else if (image_key == "jpg/cover_crop.jpg") {
    return (
      <div>
        <div style={{ width: '100%' }}>
          {imageUrl && <img src={imageUrl} style={{ width: '100%', objectFit: 'contain' }} />}
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'left', justifyContent: 'left', marginTop:15}}>
          <div style={{backgroundColor:'#98fa98',width:16,height:16,borderRadius:16}}/>
          <label style={{fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4,marginLeft:8}}>Cover Crop</label>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ width: '100%' }}>
          {imageUrl && <img src={imageUrl} style={{ width: '100%', objectFit: 'contain' }} />}
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'left', justifyContent: 'left', marginTop:15}}>
          <div style={{backgroundColor:'#98fa98',width:16,height:16,borderRadius:16}}/>
          <label style={{fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4,marginLeft:8}}>Reduced-till</label>
          <div style={{backgroundColor:'#00bfff',width:16,height:16,borderRadius:16,marginLeft:35}}/>
          <label style={{fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4,marginLeft:8}}>No-till</label>
        </div>
      </div>
    );
  }
}

function Practice({label,managementData}) {
    // dev 
  if(!managementData)
  {
    return <div/>
  }
  var image_path = null
  if (label == "No-Till") {
    image_path = "jpg/no_till.jpg"
  } else if (label == "No-Till + Reduced-Till") {
    image_path = "jpg/no_till_and_reduced.jpg"
  } else if (label == "Cover Crop") {
    image_path = "jpg/cover_crop.jpg"
  } 
  return (
    <div style={{borderRadius:14,overflow:'hidden',marginBottom:42,position:'relative',marginLeft:45,marginRight:45,height:'fit-content',display:"flex",flexDirection:"column",alignItems:"flex-start",boxShadow:"0px 2px 15px rgba(48, 68, 120, 0.16)"}}>
          <div style={{marginLeft:34,marginBottom:-16,display:"flex",flexDirection:'row',alignItems:'center',justifyContent:'flex-start',marginTop:25}}>
                <label style={{fontSize:32,fontWeight:"700",fontSize:22,color:Theme.BLACK_4}}>{label + " Practice"}</label>
                {/* removed "i" button */}
                  {/* <div style={{marginLeft:7,width:16,height:16,border:'1px solid grey',cursor:'pointer', borderColor:Theme.DARK_GRAY_1,borderRadius:16,display:'flex',alignItems:'center',justifyContent:'center',position:'relative'}}>
                    <label fitcon style={{color:Theme.DARK_GRAY_1,fontSize:12,cursor:'pointer'}}>i</label>
                  </div> */}
              </div>
          <div style={{width:'100%',marginTop:40,flex:1,display:'flex',flexDirection:'row'}}>
            <div style={{marginLeft:34,flex:1,marginRight:34}}>
              <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                <ChangeBox label={label} managementData={managementData} />

                <GrowthBox label={label} managementData={managementData} />
              </div>

              <Graph managementData={managementData}/>
              
              <div style={{width:'100%'}}>
                <label style={{marginTop:10,display:'block',fontSize:20,fontWeight:'700',color:Theme.BLACK_4,marginLeft:18,textAlign:'left', marginBottom:10, marginTop: 30}}>{label} Adoption Map (2021)</label>
              </div>
              <ManagementImage image_key={image_path} />

              <div style={{width:'100%',height:10, marginBottom: 20}}/>
            </div>
          </div>

        
        
       
    </div>
  );
}
export default Practice;
