import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import Topbar from "../Topbar"
import {GetPermissions} from "../../api/permissionsService/permissionsHelper"
import FieldsSidebar from "../FieldsSidebar"
import ShedSidebar from "../ShedsSidebar"
import Map from "../Map"
import FavoritesSidebar from "../FavoritesSidebar"
function MainPage() {
  const currentView = useSelector((state) => state.main.currentView);

  useEffect(()=>{
    GetPermissions('General')
  },[])
  return (
    <div style={{width:'100%',height:'100%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'column'}}>
      <Topbar/>
      <div style={{flex:1,width:"100%",display:"flex",flexDirection:"row"}}>
        {currentView == "fields" ? <FieldsSidebar/> : <ShedSidebar /> }
        <Map/>
        <FavoritesSidebar/>
      </div>
    </div>
  );
}
export default MainPage;
