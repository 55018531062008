import React from "react";
import { useSelector } from "react-redux";
import LegendConfig from "./legendConfig"


const CropTypeLegend = ({value}) =>{
  var color = LegendConfig.overlay_croptype.colorFunc(value)
  var labelText = LegendConfig.overlay_croptype.labelFunc(value)
  return (
    <div style={{marginLeft:10,display:'flex',float:'left',marginTop:5}}>
      <div style={{height:20,width:20,backgroundColor:color}}/>
      <label style={{marginLeft:10}}>{labelText}</label>
    </div>
  )
}
function FieldLevelLegend({type}) {
  const uniqueCropColors = useSelector((state) => state.fields.uniqueCropColors);
  
  if(!type || !LegendConfig[type] || !LegendConfig[type].showLegend)
  {
    return <div/>
  }

  var shouldShowUnits = LegendConfig[type].units != null

  return (
    <div style={{width:'100%',flexDirection:'row',display:'flex',alignItems:'center',justifyContent:'center',marginTop:10,marginBottom:type == 'overlay_croptype'?10:0}}>
    <div
      style={{
        width:290
      }}
    >
      <div style={{height:29,width:290,display:'flex',flexdirection:'row'}}>
        {
          type == 'overlay_croptype' ? 
          (
            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
            <div style={{flex:1}}/>
              {uniqueCropColors.map(value=><CropTypeLegend value={value}/>)}
      <div style={{flex:1}}/>
            </div>
          )
          :
          (
            <div style={{width:'100%',display:'flex',flexDirection:'row',borderRadius:6,overflow:'hidden',position:"relative"}}>
              {LegendConfig[type].domain.map(x=><div style={{flex:1,height:29,backgroundColor:LegendConfig[type].colorFunc(LegendConfig[type].min+x)}}/>)}
              <label style={{position:"absolute",top:8.5,left:12,color:"black",fontWeight:'700',fontSize:10}}>{LegendConfig[type].min}</label>
              <label style={{position:"absolute",top:8.5,right:12,color:"black",fontWeight:'700',fontSize:10}}>{LegendConfig[type].max}</label>
              {shouldShowUnits && 
                <div style={{display:'flex',width:'100%',height:'100%',top:0,left:0,position:'absolute',display:"flexed",justifyContent:'center',alignItems:'center',display:"flex"}}>
                  <label style={{color:"black",fontWeight:'700',fontSize:10}}>{LegendConfig[type].units}</label>
                </div>
              }
            </div>
          )
        }

      </div>
    </div>
    </div>
  );
}
export default FieldLevelLegend;
