import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotateLabels from 'chartjs-plugin-annotation'
import * as Theme from "../../../../statics/theme"
import 'chart.js/auto'
import ShedsApi from "../../../../api/shedsService";


import { Line, Bar } from 'react-chartjs-2';
import { current } from "@reduxjs/toolkit";


const bushelsOptions = {
  responsive: true,
  maintainAspectRatio:false,
  plugins: {
    "tooltip": {
      "enabled": false
    },
    legend: {
      position: 'top',
      display:false
    },
    title: {
      display: 'false'
    },
    datalabels: {
      display: true,
      color: "black",
      formatter: function(value,{dataIndex}) {
          return value.toFixed(1)
      },
      color: Theme.DARK_GREEN_1,
      font: function(context) {
          return {
            size:12,
            weight:'500'
          }
      },
      anchor: "end",
      offset: -23,
      align: "start"
    }
  },
  scales: {
    y: {
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
              if(Number.isInteger(value))
              {
                return value.toFixed(1);
              }
            },
            // color:'#B4B8C4',
            beginAtZero:true
        },
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:1,
          lineWidth:1
        }
    },
    x: {
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:0,
          lineWidth:0
        }
    },
  }
};
// graph with plotting fixes (point and label overlap) 2/4/23
const AdoptionGraph = ({data}) =>{
  if(!data || !data["yield"])
  {
    return
  }
  var data = {
    labels:Object.keys(data["yield"]),
    datasets: [
      {
        data: Object.keys(data["yield"]).map(year=>data["yield"][year]["shed"]),
        borderRadius:6,
        borderColor: Theme.DARK_GREEN_1,
        tension:.1,
        fill:false,
        pointBackgroundColor:Theme.DARK_GREEN_1
      },
      {
        data: Object.keys(data["yield"]).map(year=>data["yield"][year]["national"]),
        borderRadius:6,
        borderColor: Theme.POMERANGE_ORANGE,
        tension:.1,
        fill:false,
        pointBackgroundColor:Theme.POMERANGE_ORANGE
      },
    ]
  };
  const offsetSwitch = 5;
  const yieldOptions = {
    responsive: true,
    maintainAspectRatio:false,
    plugins: {
      "tooltip": {
        "enabled": false
      },
      legend: {
        display:false
      },
      title: {
        display: false,
      },
      datalabels: {
        display: true,
        color: function(context) {
            return context.dataset.borderColor
        },
        formatter: function(value,{dataIndex}) {
            return parseInt(value*100)
        },
        font: function(context) {
            return {
              size:12,
              weight:'500'
            }
        },
        anchor: "end",
        offset: function(context) {
        // implement the plot logic below
          // get points from both datasets
          var datasetOneData = context.dataset.data[context.dataIndex];
          var datasetTwoData;
          
          if (context.datasetIndex === 0) {
            datasetTwoData = data.datasets[1].data[context.dataIndex];
          } else {
            datasetTwoData = data.datasets[0].data[context.dataIndex];
          }

          // now the conditionals
          // if currdata > otherdata we want to put d1 on top, d2 on bottom
          // otherwise we want to put currdata on bottom, otherdata on top
          // but issue is this runs through ALL points so curr and other is switched
          if (context.dataIndex === 1) {
            return (datasetOneData > datasetTwoData ? -23 : 23);
          } else {
            return (datasetTwoData < datasetOneData ? -23 : 23);
          }
        },
        align: "start"
      }
    },
    scales: {
      y: {
          max: Math.max(Math.max(...data.datasets[0].data), Math.max(...data.datasets[1].data)) + 0.4,
          min: Math.min(Math.min(...data.datasets[0].data), Math.min(...data.datasets[1].data)) - 0.4,
          ticks: {
              padding: 20, 
              // Include a dollar sign in the ticks
              callback: function(value, index, ticks) {
                if(Number.isInteger(value*10) && value >= 0)
                {
                  return parseInt(value*100);
                }
              },
              // Removed color to make y-axis ticks more readable
              // color:'#B4B8C4', 
          },
          grid: {
            borderColor:'#DCE0E7',
            color:'#DCE0E7',
            borderWidth:1,
            lineWidth:1
          },
          beginAtZero:true
      },
      x: {
          grid: {
            borderColor:'#DCE0E7',
            color:'#DCE0E7',
            borderWidth:0,
            lineWidth:0
          }
      },
    }
  };
  return (
    <div style={{flex:1,height:390,width:'90%',marginTop:5,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
      <label style={{color:'#999DA9',fontSize:12,fontWeight:'500',float:'left',marginLeft:30}}>bushels / acre</label>
      <div style={{width:'100%',height:300}}>
        <Line options={yieldOptions} plugins={[ChartDataLabels]} data={data}/>
      </div>
      <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginTop:15}}>
        <div style={{backgroundColor:Theme.POMERANGE_ORANGE,width:16,height:16,borderRadius:16}}/>
        <label style={{fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4,marginLeft:8}}>National</label>
        <div style={{backgroundColor:Theme.DARK_GREEN_1,width:16,height:16,borderRadius:16,marginLeft:35}}/>
        <label style={{fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4,marginLeft:8}}>This shed</label>
      </div>
    </div>
  )
} 
const BushelsGraph = ({data}) =>{
  if(!data || !data["yield"])
  {
    return
  }
  var data = {
    labels:Object.keys(data["yield"]),
    datasets: [
      {
        data: Object.keys(data["yield"]).map(year=>data["yield"][year]["bushels"]),
        borderRadius:6,
        backgroundColor: Theme.DARK_GREEN_1,
        barThickness:50,
      }
    ]
  };

  return (
    <div style={{flex:1,height:390,width:'90%',marginTop:25,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
      <label style={{color: Theme.BLACK_4,fontSize:20,fontWeight:'700',float:'left', marginLeft: 10, marginTop: 20}}>Total Production by Year</label>
      <label style={{color:'#999DA9',fontSize:12,fontWeight:'500',float:'left',marginLeft:15, marginTop: 30, marginBottom: -10}}>Million Bushels</label>
      <div style={{width:'100%',height:300}}>
        <Bar options={bushelsOptions} plugins={[ChartDataLabels]} data={data}/>
      </div>
    </div>
  )
} 

function YieldImage({image_key}) {
  const [imageUrl, setImageURL] = useState(null);

  useEffect(() => {
    // Make the API call to fetch the image URL
    ShedsApi.getShedImage(image_key)
    .then(response => {
      console.log(response.image_url)
      setImageURL(response.image_url)
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <div>
      <div style={{ width: '100%' }}>
        {imageUrl && <img src={imageUrl} style={{ width: '100%', objectFit: 'contain' }} />}
      </div>
      <div style={{ width: '100%', height: 30 }} />
    </div>
  );
}
function Yield({data,label}) {
  var image_path = null;
  if (label == "Corn") {
    image_path = "jpg/corn_yield.jpg"
  } else if (label == "Soybeans") {
    image_path = "jpg/soy_yield.jpg"
  }

  if(!data)
  {
    return <div/>
  }
  
  return (
    <div style={{marginTop:38,borderRadius:14,overflow:'hidden',marginBottom:42,position:'relative',marginLeft:45,marginRight:45,height:'fit-content',display:"flex",flexDirection:"column",boxShadow:"0px 2px 15px rgba(48, 68, 120, 0.16)"}}>
              <div style={{marginLeft:34,marginBottom:-16,display:"flex",flexDirection:'row',alignItems:'center',justifyContent:'flex-start',marginTop:25}}>
                <label style={{fontSize:32,fontWeight:"700",fontSize:20,color:Theme.BLACK_4}}>{label + " yield"}</label>
                  <div style={{marginLeft:7,width:16,height:16,border:'1px solid grey',cursor:'pointer', borderColor:Theme.DARK_GRAY_1,borderRadius:16,display:'flex',alignItems:'center',justifyContent:'center',position:'relative'}}>
                    <label fitcon style={{color:Theme.DARK_GRAY_1,fontSize:12,cursor:'pointer'}}>i</label>
                  </div>
              </div>
          <div style={{width:'100%',flex:1,display:'flex',flexDirection:'row',marginTop:42}}>
            <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>


              <AdoptionGraph data={data} />

              <BushelsGraph data={data} />

              <div style={{width:'100%',marginTop:20,display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                <label style={{fontWeight:'700',fontSize:20,color:Theme.BLACK_4,display:'block',marginLeft:48}}>{label} yield map (2021)</label>
              </div>

              {/* <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',marginTop:15}}>
                <div style={{borderRadius:10,backgroundColor:Theme.DARK_GREEN_1,width:'90%',height:250}}/>
              </div> */}
              {<YieldImage image_key={image_path}/>}

              <div style={{width:'100%',height:0}}/>
            </div>
          </div>

        
        
       
    </div>
  );
}
export default Yield;
