import React, { useEffect,useState, useRef } from "react";
import { useSelector } from "react-redux";
import TableViewIcon from '@mui/icons-material/TableView';
import * as Theme from "../../../../statics/theme"
import MajorCrops from "./MajorCrops"
import Yield from "./Yield"
import "../../../../App.css"

const DataBox = ({label, value, unit,marginLeft}) => {


    return (
      <div style={{marginLeft,borderRadius:10,boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)',border:'1px solid #DCE0E7',height:137,width:296,display:'flex',flexDirection:'column'}}>
        <div style={{width:'100%',display:'flex'}}>
          <label style={{marginLeft:24,marginTop:14,marginRight:24,color:Theme.BLACK_4,fontSize:18,fontWeight:'600',textAlign:'left'}}>{label}</label>
        </div>
        <div style={{flex:1}}/>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'flex-end',marginBottom:10}}>
          <label style={{marginLeft:24,color:Theme.DARK_GREEN_1,fontWeight:'700',fontSize:56,minWidth:130,textAlign:'left'}}>{value}</label>
          <div style={{marginBottom:10,display:'flex',flex:1,marginLeft:10,marginRight:21,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
            <label style={{color:Theme.BLACK_4,fontWeight:'500',fontSize:16}}>{unit}</label>
          </div>
        </div>
      </div>
    )
}

function Productivity({selectedShed,shedData}) {
  if(!selectedShed || !shedData)
  {
    return <div/>
  }
  var cornData = null
  if(shedData.productivity && shedData.productivity.corn)
  {
    cornData = shedData.productivity.corn
  }
  var soybeansData = null
  if(shedData.productivity && shedData.productivity.soybeans)
  {
    soybeansData = shedData.productivity.soybean
  }

  var acreage = 0
  if(shedData && shedData["basic_info"] && shedData["basic_info"]["acreage"])
  {
    acreage = shedData["basic_info"]["acreage"]
  }
  acreage = Math.round((acreage / 1000)).toLocaleString("en-US") ; 

  var fieldCount = 0
  if(shedData && shedData["basic_info"] && shedData["basic_info"]["field_count"])
  {
    fieldCount = shedData["basic_info"]["field_count"]
  }
  fieldCount = fieldCount.toLocaleString("en-US");

  var cornData = null
  var soybeansData = null
  if(shedData && shedData["productivity"])
  {
    cornData = shedData["productivity"]["corn"]
    soybeansData = shedData["productivity"]["soybean"]
  }
  return (
    <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <label style={{fontSize:32,fontWeight:"700",marginTop:30,color:Theme.BLACK_4}}>Productivity</label>
        <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:25}}/>

        <div className='custom-scrollbar' style={{width:'100%',height:'100%',marginBottom:34,overflowY:'scroll'}}>
          <div style={{flex:1,width:'100%',display:'flex',marginBottom:20,alignItems:'center'}}>
            <div style={{width:'100%',paddingTop:5,height:400,marginTop:33,position:'relative'}}>
              <div style={{width:'100%',marginTop:18,display:'flex',flexDireciton:'row',justifyContent:'center',alignItems:'center'}}>

                <DataBox value={acreage} unit={'thousand acres'} label={"Total cropland acreage"} marginLeft={14}/>
                <DataBox value={fieldCount} unit={'fields'} label={"Number of fields"} marginLeft={14}/>
              </div>
              <MajorCrops selectedShed={selectedShed} shedData={shedData} />

              <Yield data={cornData} label={"Corn"} />

              <Yield data={soybeansData} label={"Soybeans"} />
              <div style={{width:10,height:5,marginTop:-38}}/>
            </div>
          </div>
        </div>
    </div>
  );
}
export default Productivity;
