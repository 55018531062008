import axios from "axios";
import * as api_statics from "../../statics/api";


//These methods are the actual methods that call into the Fields API.
//They are called by the wrapper functions in the FieldsApi
class PermissionsService {
  static getPermissions(accessToken, { permissionsApp }) {
    var permissionsEndpoint = api_statics.FIELDS_API + "/permissions";
    var params = {
      permissionsApp
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };


    return axios.get(permissionsEndpoint, {
      params,
      headers,
    });
  }
}

export default PermissionsService;
