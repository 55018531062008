import React, {useEffect,useState} from "react";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import * as Theme from "../../../../statics/theme"
import {setCurrentView} from "../../../../redux/actions/main"
import SpaIcon from '@mui/icons-material/Spa';
import AppsIcon from '@mui/icons-material/Apps';
function ViewSelect() {
  const [open, setOpen] = useState(true)
  const currentView = useSelector((state) => state.main.currentView);

  var isFields = currentView == "fields"

  useEffect(()=>{
    if(window.innerWidth < 1415)
    {
      setOpen(false)
    } else {
      setOpen(true)
    }
    window.onresize = function(event) {
      if(event.target.innerWidth < 1415)
      {
        setOpen(false)
      } else {
        setOpen(true)
      }
  }
  },[])

  if(!open)
  {
    return <div />
  }

  return (
    <div id={"viewSelect"} style={{zIndex:10000,position:"absolute",left:20,top:20,display:'flex',flexDirection:'row'}}>
      <div style={{cursor:'pointer',backgroundColor:isFields?Theme.GRASS_GREEN:'transparent',width:113,height:38,borderRadius:46,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',border:"2.5px solid white",borderColor:isFields?Theme.GRASS_GREEN:'white'}}
        onClick={()=>{setCurrentView("fields")}}>
          <SpaIcon style={{color:'white',fontSize:15}} />
          <label style={{color:'white',fontSize:14,fontWeight:'600',cursor:'pointer',marginLeft:10}}>Fields</label>
      </div>

      <div style={{cursor:'pointer',marginLeft:5,backgroundColor:!isFields?Theme.POMERANGE_ORANGE:'transparent',width:113,height:38,borderRadius:46,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',border:'2.5px solid white',borderColor:!isFields?Theme.POMERANGE_ORANGE:'white'}}
        onClick={()=>{setCurrentView("sheds")}}>
          <AppsIcon style={{color:'white',fontSize:15}} />
          <label style={{color:'white',fontSize:14,fontWeight:'600',cursor:'pointer',marginLeft:10}}>Sheds</label>
      </div>
    </div>
  );
}
export default ViewSelect;
