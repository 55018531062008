import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import FieldLevelLegend from "../../../Map/Overlays/FieldOverlay/legend"
import TransparencySlider from "../../../Map/Overlays/FieldOverlay/transparencySlider"
import OverlaySelect from "../../../Map/Overlays/FieldOverlay/overlaySelect";
import LegendConfig from "../../../Map/Overlays/FieldOverlay/legendConfig";
import * as Theme from "../../../../statics/theme"
import ShedsApi from "../../../../api/shedsService";

var mapI;
function CropTypeImage({image_key}) {
  const [imageUrl, setImageURL] = useState(null);

  useEffect(() => {
    // Make the API call to fetch the image URL
    ShedsApi.getShedImage(image_key)
    .then(response => {
      console.log(response.image_url)
      setImageURL(response.image_url)
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <div>
      <div style={{ width: '100%' }}>
        {imageUrl && <img src={imageUrl} style={{ width: '100%', objectFit: 'contain' }} />}
      </div>
      <div style={{ width: '100%', height: 30 }} />
    </div>
  );
}
function BasicInfo({selectedShed,shedData}) {
  /*const [overlayType,setOverlayType] = useState("satellite")
  const [transparency,setTransparency] = useState(0.5)
  const [map,setMap] = useState(null)
  useEffect(()=>{
    setupMap(selectedField,fieldData);
  },[selectedField,fieldData])
  useEffect(()=>{
    updateOverlay(overlayType,transparency)
  },[overlayType,transparency])
  useEffect(()=>{
    if(overlayType && LegendConfig[overlayType])
    {
      setTransparency(LegendConfig[overlayType].defaultTransparency)
    }
  },[overlayType])

  const setupMap = (selectedField,fieldData) => {
    if(mapI != null)
    {
      mapI.remove()
    }
    if(selectedField && selectedField.properties && fieldData && fieldData.geometry)
    {
      mapI = new window.maplibregl.Map({
        container: "mini_map",
        style:
          "https://api.maptiler.com/maps/hybrid/style.json?key=kKXD4OdNK4kH76yz9rU4",
        center: [selectedField.properties.lng, selectedField.properties.lat],
        zoom: 13.5,
        dragPan: false,
      });
      mapI.scrollZoom.disable();

      mapI.on("load",()=>{
        setMap(mapI);
        var outline_properties = {
          id: "outline",
          type: "line",
          source: "field-source",
          paint: {
            "line-color": "#0088dc",
            "line-opacity": 0.6,
            "line-width": 2,
          },
          minzoom:11
        };
        var layer_properties = {
          id: "overlay",
          type: "fill",
          source: "field-source",
          paint: {
            "fill-color": ["get", overlayType + "_color"],

            "fill-opacity": [
              "case",
              ["==", ["get", overlayType + "_color"], "clear"],
              0,
              transparency
            ],
          },
          minzoom:11
        };
        mapI.addSource("field-source", {
          type: "geojson",
          data: fieldData.geometry
        });
        mapI.fitBounds(fieldData.geometry.bbox,{padding:40,duration:0})
        mapI.addLayer(layer_properties);
        mapI.addLayer(outline_properties);
      })
    }
  }

  const updateOverlay = (overlayType, transparency) => {
    if(map && map.getLayer("outline") && map.getLayer("overlay"))
    {
      map.setPaintProperty("overlay", "fill-color", ["get", overlayType + "_color"]);
        map.setPaintProperty("overlay", "fill-opacity", [
          "case",
          ["==", ["get", overlayType + "_color"], "clear"],
          0,
          transparency
        ]);
    }
  }*/

  if(!selectedShed || !shedData)
  {
    return <div/>
  }
  var image_path = "jpg/crop_type.jpg"
  var shedBasicInfo = shedData["basic_info"]
  return (
    <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <label style={{fontSize:32,fontWeight:"700",marginTop:30,color:Theme.BLACK_4}}>Basic Information</label>
        <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:25}}/>


        <div style={{width:"100%",display:'flex',flexDirection:'row'}}>
          <div style={{flex:1,marginLeft:45,marginRight:45}}>
            <div style={{display:"flex",flexDirection:"row"}}>
              <div style={{width:245}}>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:30}}>
                  <label style={{fontWeight:"700",fontSize:16,marginRight:10,color:Theme.DARK_GREEN_2}}>{'Shed ID:'}</label>
                  <label style={{fontWeight:"500",fontSize:16,color:Theme.DARK_GRAY_4}}>{shedBasicInfo["shed_id"]?shedBasicInfo["shed_id"]:""}</label>
                </div>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:20}}>
                  <label style={{fontWeight:"700",fontSize:16,marginRight:10,color:Theme.DARK_GREEN_2}}>{'County Name:'}</label>
                  <label style={{fontWeight:"500",fontSize:16,color:Theme.DARK_GRAY_4}}>{shedBasicInfo["name"]?shedBasicInfo["name"]:""}</label>
                </div>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:20}}>
                  <label style={{fontWeight:"700",fontSize:16,marginRight:10,color:Theme.DARK_GREEN_2}}>{'State:'}</label>
                  <label style={{fontWeight:"500",fontSize:16, color:Theme.DARK_GRAY_4}}>{shedBasicInfo["state_name"]}</label>
                </div>
              </div>
              <div style={{}}>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:30}}>
                  <label style={{fontWeight:"700",fontSize:16,marginRight:10,color:Theme.DARK_GREEN_2}}>{'Total Cropland Acreage:'}</label>
                  <label style={{fontWeight:"500",fontSize:16,color:Theme.DARK_GRAY_4}}>{shedBasicInfo["acreage"]?(parseInt(parseFloat(shedBasicInfo["acreage"])*100)/100).toLocaleString("en-US") + " acres":""}</label>
                </div>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:20}}>
                  <label style={{fontWeight:"700",fontSize:16,marginRight:10,color:Theme.DARK_GREEN_2}}>{'Number of Fields:'}</label>
                  <label style={{fontWeight:"500",fontSize:16,color:Theme.DARK_GRAY_4}}>{shedBasicInfo["field_count"]?parseInt(shedBasicInfo["field_count"]).toLocaleString("en-US") + " fields":""}</label>
                </div>
              {/* percentage of land use changes below, flex: crop_percentage*/}
              <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",marginTop:20}}>
                <label style={{fontWeight:"700",fontSize:16,marginRight:10,color:Theme.DARK_GREEN_2}}>{'Percentage of Land Use:'}</label>
                  {shedData["productivity"] && shedData["productivity"]["land_use"] && shedData["productivity"]["land_use"]["5year"] && shedData["productivity"]["land_use"]["5year"]['list'] &&
                    <div style={{width:346,marginTop:10}}>
                      <div style={{width:'100%',height:15,display:'flex',flexDirection:'row',overflow:'hidden',borderRadius:6}}>
                        {shedData["productivity"]["land_use"]["5year"]["list"].map(crop=>
                          <div style={{flex:crop.percentage ,height:'100%',display:'flex',alignItems:'center',justifyContent:'center',
                                backgroundColor:(crop.crop == null) ? Theme.LIGHT_GRAY_1 : LegendConfig.overlay_croptype.colorFunc(crop.crop)}}>
                          </div>
                        )}
                      </div>
                      <div style={{width:'100%',marginTop:6,display:'flex',flexDirection:'column'}}>
                          {shedData["productivity"]["land_use"]["5year"]["list"].map(crop=>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                              <div style={{display:'flex',alignItems:'center'}}>
                              <div style={{width:10,height:10,borderRadius:10,backgroundColor:(crop.crop == null) ? Theme.LIGHT_GRAY_1 : LegendConfig.overlay_croptype.colorFunc(crop.crop)}}>
                          </div>
                            <label style={{color:Theme.BLACK_4,fontWeight:'700',fontSize:12,marginLeft:10}}>{(crop.crop == null) ? 'Other crops and use' : LegendConfig.overlay_croptype.labelFunc(crop.crop)}</label>
                            </div>
                            <label style={{color:Theme.BLACK_4,fontWeight:'700',fontSize:12,alignSelf:'flex-end',marginRight:10}}>{parseInt(crop.percentage*100) + "%"}</label>
                            </div>
                            )}
                        </div>
                </div>
                }
              </div>
              </div>
            </div>
            {<CropTypeImage image_key={image_path}/>}
          </div>
        </div>
    </div>
  );
}
export default BasicInfo;
