import React from "react";
import LogoImage from '../../images/Logo.png';
function Logo() {
  return (
    <div style={{left:10,top:0,height:80,position:'absolute'}}>
      <div style={{height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <img src={ LogoImage } style={{height:160,width:160}}/>
      </div>
    </div>
  );
}
export default Logo;
