import React, { useEffect,useState } from "react";
import Practice from "./Practice"
import * as Theme from "../../../../statics/theme"

function Management({selectedShed,shedData}) {
  if(!selectedShed || !shedData)
  {
    return <div/>
  }
  var noTill = null
  if(shedData['management'] && shedData['management']['no-till'])
  {
    noTill = shedData['management']['no-till']
  }
  var no_reducedTill = null
  if(shedData['management'] && shedData['management']['no-till_reduced-till'])
  {
    no_reducedTill = shedData['management']['no-till_reduced-till']
  }
  var coverCrop = null
  if(shedData['management'] && shedData['management']['cover_crop'])
  {
    coverCrop = shedData['management']['cover_crop']
  }
  return (
    <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <label style={{fontSize:32,fontWeight:"700",marginTop:30,color:Theme.BLACK_4}}>Field Management</label>
        <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:25}}/>
        
        <div className='custom-scrollbar' style={{width:'100%',height:'100%',marginBottom:34,overflowY:'scroll'}}>
          <div style={{flex:1,width:'100%',display:'flex',marginBottom:20}}>
            <div style={{width:'100%',paddingTop:5,height:400,marginTop:33,position:'relative'}}>
              <Practice managementData={noTill} label={"No-Till"}/>
              <Practice managementData={no_reducedTill} label={"No-Till + Reduced-Till"}/>
              <Practice managementData={coverCrop} label={"Cover Crop"}/>
              <div style={{width:10,height:5,marginTop:-38}}/>
            </div>
          </div>
        </div>
        
    </div>
  );
}
export default Management;
