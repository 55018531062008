import * as d3 from "d3";
import evaluate_cmap from "./colormap"
const cropTypeScale = {
  "0":{
              "color":"#000000",
              "label":"Background"
          },
          "1":{
              "color":"#ffd400",
              "label":"Corn"
          },
          "2":{
              "color":"#ff2626",
              "label":"Cotton"
          },
          "3":{
              "color":"#00a9e6",
              "label":"Rice"
          },
          "4":{
              "color":"#ff9e0f",
              "label":"Sorghum"
          },
          "5":{
              "color":"#267300",
              "label":"Soybeans"
          },
          "6":{
              "color":"#ffff00",
              "label":"Sunflower"
          },
          "7":{
              "color":"#000000",
              "label":"Other"
          },
          "8":{
              "color":"#000000",
              "label":"Other"
          },
          "9":{
              "color":"#000000",
              "label":"Other"
          },
          "10":{
              "color":"#70a800",
              "label":"Peanuts"
          },
          "11":{
              "color":"#00af4d",
              "label":"Tobacco"
          },
          "12":{
              "color":"#e0a60f",
              "label":"Sweet Corn"
          },
          "13":{
              "color":"#e0a60f",
              "label":"Pop or Orn Corn"
          },
          "14":{
              "color":"#80d4ff",
              "label":"Mint"
          },
          "15":{
              "color":"#000000",
              "label":"Other"
          },
          "16":{
              "color":"#000000",
              "label":"Other"
          },
          "17":{
              "color":"#000000",
              "label":"Other"
          },
          "18":{
              "color":"#000000",
              "label":"Other"
          },
          "19":{
              "color":"#000000",
              "label":"Other"
          },
          "20":{
              "color":"#000000",
              "label":"Other"
          },
          "21":{
              "color":"#e2007f",
              "label":"Barley"
          },
          "22":{
              "color":"#8a6453",
              "label":"Durum Wheat"
          },
          "23":{
              "color":"#d9b56c",
              "label":"Spring Wheat"
          },
          "24":{
              "color":"#a87000",
              "label":"Winter Wheat"
          },
          "25":{
              "color":"#d69dbc",
              "label":"Other Small Grains"
          },
          "26":{
              "color":"#737300",
              "label":"Dbl Crop WinWht/Soybeans"
          },
          "27":{
              "color":"#ae017e",
              "label":"Rye"
          },
          "28":{
              "color":"#a15889",
              "label":"Oats"
          },
          "29":{
              "color":"#73004c",
              "label":"Millet"
          },
          "30":{
              "color":"#d69dbc",
              "label":"Speltz"
          },
          "31":{
              "color":"#d1ff00",
              "label":"Canola"
          },
          "32":{
              "color":"#8099ff",
              "label":"Flaxseed"
          },
          "33":{
              "color":"#d6d600",
              "label":"Safflower"
          },
          "34":{
              "color":"#d1ff00",
              "label":"Rape Seed"
          },
          "35":{
              "color":"#00af4d",
              "label":"Mustard"
          },
          "36":{
              "color":"#ffa8e3",
              "label":"Alfalfa"
          },
          "37":{
              "color":"#a5f58d",
              "label":"Other Hay/Non Alfalfa"
          },
          "38":{
              "color":"#00af4d",
              "label":"Camelina"
          },
          "39":{
              "color":"#d69dbc",
              "label":"Buckwheat"
          },
          "40":{
              "color":"#000000",
              "label":"Other"
          },
          "41":{
              "color":"#a900e6",
              "label":"Sugarbeets"
          },
          "42":{
              "color":"#a80000",
              "label":"Dry Beans"
          },
          "43":{
              "color":"#732600",
              "label":"Potatoes"
          },
          "44":{
              "color":"#00af4d",
              "label":"Other Crops"
          },
          "45":{
              "color":"#b380ff",
              "label":"Sugarcane"
          },
          "46":{
              "color":"#732600",
              "label":"Sweet Potatoes"
          },
          "47":{
              "color":"#ff6666",
              "label":"Misc Vegs & Fruits"
          },
          "48":{
              "color":"#ff6666",
              "label":"Watermelons"
          },
          "49":{
              "color":"#ffcc66",
              "label":"Onions"
          },
          "50":{
              "color":"#ff6666",
              "label":"Cucumbers"
          },
          "51":{
              "color":"#00af4d",
              "label":"Chick Peas"
          },
          "52":{
              "color":"#00deb0",
              "label":"Lentils"
          },
          "53":{
              "color":"#55ff00",
              "label":"Peas"
          },
          "54":{
              "color":"#f5a27a",
              "label":"Tomatoes"
          },
          "55":{
              "color":"#ff6666",
              "label":"Caneberries"
          },
          "56":{
              "color":"#00af4d",
              "label":"Hops"
          },
          "57":{
              "color":"#80d4ff",
              "label":"Herbs"
          },
          "58":{
              "color":"#e8beff",
              "label":"Clover/Wildflowers"
          },
          "59":{
              "color":"#b2ffde",
              "label":"Sod/Grass Seed"
          },
          "60":{
              "color":"#00af4d",
              "label":"Switchgrass"
          },
          "61":{
              "color":"#bfbf7a",
              "label":"Fallow/Idle Cropland"
          },
          "62":{
              "color":"#000000",
              "label":"Other"
          },
          "63":{
              "color":"#95ce93",
              "label":"Forest"
          },
          "64":{
              "color":"#c7d79e",
              "label":"Shrubland"
          },
          "65":{
              "color":"#ccbfa3",
              "label":"Barren"
          },
          "66":{
              "color":"#ff00ff",
              "label":"Cherries"
          },
          "67":{
              "color":"#ff91ab",
              "label":"Peaches"
          },
          "68":{
              "color":"#b90050",
              "label":"Apples"
          },
          "69":{
              "color":"#704489",
              "label":"Grapes"
          },
          "70":{
              "color":"#007878",
              "label":"Christmas Trees"
          },
          "71":{
              "color":"#b39c70",
              "label":"Other Tree Crops"
          },
          "72":{
              "color":"#ffff80",
              "label":"Citrus"
          },
          "73":{
              "color":"#000000",
              "label":"Other"
          },
          "74":{
              "color":"#b6705c",
              "label":"Pecans"
          },
          "75":{
              "color":"#00a884",
              "label":"Almonds"
          },
          "76":{
              "color":"#ebd6b0",
              "label":"Walnuts"
          },
          "77":{
              "color":"#b39c70",
              "label":"Pears"
          },
          "78":{
              "color":"#000000",
              "label":"Other"
          },
          "79":{
              "color":"#000000",
              "label":"Other"
          },
          "80":{
              "color":"#000000",
              "label":"Other"
          },
          "81":{
              "color":"#f7f7f7",
              "label":"Clouds/No Data"
          },
          "82":{
              "color":"#9c9c9c",
              "label":"Developed"
          },
          "83":{
              "color":"#4d70a3",
              "label":"Water"
          },
          "84":{
              "color":"#000000",
              "label":"Other"
          },
          "85":{
              "color":"#000000",
              "label":"Other"
          },
          "86":{
              "color":"#000000",
              "label":"Other"
          },
          "87":{
              "color":"#80b3b3",
              "label":"Wetlands"
          },
          "88":{
              "color":"#e9ffbe",
              "label":"Nonag/Undefined"
          },
          "89":{
              "color":"#000000",
              "label":"Other"
          },
          "90":{
              "color":"#000000",
              "label":"Other"
          },
          "91":{
              "color":"#000000",
              "label":"Other"
          },
          "92":{
              "color":"#00ffff",
              "label":"Aquaculture"
          },
          "93":{
              "color":"#000000",
              "label":"Other"
          },
          "94":{
              "color":"#000000",
              "label":"Other"
          },
          "95":{
              "color":"#000000",
              "label":"Other"
          },
          "96":{
              "color":"#000000",
              "label":"Other"
          },
          "97":{
              "color":"#000000",
              "label":"Other"
          },
          "98":{
              "color":"#000000",
              "label":"Other"
          },
          "99":{
              "color":"#000000",
              "label":"Other"
          },
          "100":{
              "color":"#000000",
              "label":"Other"
          },
          "101":{
              "color":"#000000",
              "label":"Other"
          },
          "102":{
              "color":"#000000",
              "label":"Other"
          },
          "103":{
              "color":"#000000",
              "label":"Other"
          },
          "104":{
              "color":"#000000",
              "label":"Other"
          },
          "105":{
              "color":"#000000",
              "label":"Other"
          },
          "106":{
              "color":"#000000",
              "label":"Other"
          },
          "107":{
              "color":"#000000",
              "label":"Other"
          },
          "108":{
              "color":"#000000",
              "label":"Other"
          },
          "109":{
              "color":"#000000",
              "label":"Other"
          },
          "110":{
              "color":"#000000",
              "label":"Other"
          },
          "111":{
              "color":"#4d70a3",
              "label":"Open Water"
          },
          "112":{
              "color":"#d4e3fc",
              "label":"Perennial Ice/Snow "
          },
          "113":{
              "color":"#000000",
              "label":"Other"
          },
          "114":{
              "color":"#000000",
              "label":"Other"
          },
          "115":{
              "color":"#000000",
              "label":"Other"
          },
          "116":{
              "color":"#000000",
              "label":"Other"
          },
          "117":{
              "color":"#000000",
              "label":"Other"
          },
          "118":{
              "color":"#000000",
              "label":"Other"
          },
          "119":{
              "color":"#000000",
              "label":"Other"
          },
          "120":{
              "color":"#000000",
              "label":"Other"
          },
          "121":{
              "color":"#9c9c9c",
              "label":"Developed/Open Space"
          },
          "122":{
              "color":"#9c9c9c",
              "label":"Developed/Low Intensity"
          },
          "123":{
              "color":"#9c9c9c",
              "label":"Developed/Med Intensity"
          },
          "124":{
              "color":"#9c9c9c",
              "label":"Developed/High Intensity"
          },
          "125":{
              "color":"#000000",
              "label":"Other"
          },
          "126":{
              "color":"#000000",
              "label":"Other"
          },
          "127":{
              "color":"#000000",
              "label":"Other"
          },
          "128":{
              "color":"#000000",
              "label":"Other"
          },
          "129":{
              "color":"#000000",
              "label":"Other"
          },
          "130":{
              "color":"#000000",
              "label":"Other"
          },
          "131":{
              "color":"#ccbfa3",
              "label":"Barren"
          },
          "132":{
              "color":"#000000",
              "label":"Other"
          },
          "133":{
              "color":"#000000",
              "label":"Other"
          },
          "134":{
              "color":"#000000",
              "label":"Other"
          },
          "135":{
              "color":"#000000",
              "label":"Other"
          },
          "136":{
              "color":"#000000",
              "label":"Other"
          },
          "137":{
              "color":"#000000",
              "label":"Other"
          },
          "138":{
              "color":"#000000",
              "label":"Other"
          },
          "139":{
              "color":"#000000",
              "label":"Other"
          },
          "140":{
              "color":"#000000",
              "label":"Other"
          },
          "141":{
              "color":"#95ce93",
              "label":"Deciduous Forest"
          },
          "142":{
              "color":"#95ce93",
              "label":"Evergreen Forest"
          },
          "143":{
              "color":"#95ce93",
              "label":"Mixed Forest"
          },
          "144":{
              "color":"#000000",
              "label":"Other"
          },
          "145":{
              "color":"#000000",
              "label":"Other"
          },
          "146":{
              "color":"#000000",
              "label":"Other"
          },
          "147":{
              "color":"#000000",
              "label":"Other"
          },
          "148":{
              "color":"#000000",
              "label":"Other"
          },
          "149":{
              "color":"#000000",
              "label":"Other"
          },
          "150":{
              "color":"#000000",
              "label":"Other"
          },
          "151":{
              "color":"#000000",
              "label":"Other"
          },
          "152":{
              "color":"#c7d79e",
              "label":"Shrubland"
          },
          "153":{
              "color":"#000000",
              "label":"Other"
          },
          "154":{
              "color":"#000000",
              "label":"Other"
          },
          "155":{
              "color":"#000000",
              "label":"Other"
          },
          "156":{
              "color":"#000000",
              "label":"Other"
          },
          "157":{
              "color":"#000000",
              "label":"Other"
          },
          "158":{
              "color":"#000000",
              "label":"Other"
          },
          "159":{
              "color":"#000000",
              "label":"Other"
          },
          "160":{
              "color":"#000000",
              "label":"Other"
          },
          "161":{
              "color":"#000000",
              "label":"Other"
          },
          "162":{
              "color":"#000000",
              "label":"Other"
          },
          "163":{
              "color":"#000000",
              "label":"Other"
          },
          "164":{
              "color":"#000000",
              "label":"Other"
          },
          "165":{
              "color":"#000000",
              "label":"Other"
          },
          "166":{
              "color":"#000000",
              "label":"Other"
          },
          "167":{
              "color":"#000000",
              "label":"Other"
          },
          "168":{
              "color":"#000000",
              "label":"Other"
          },
          "169":{
              "color":"#000000",
              "label":"Other"
          },
          "170":{
              "color":"#000000",
              "label":"Other"
          },
          "171":{
              "color":"#000000",
              "label":"Other"
          },
          "172":{
              "color":"#000000",
              "label":"Other"
          },
          "173":{
              "color":"#000000",
              "label":"Other"
          },
          "174":{
              "color":"#000000",
              "label":"Other"
          },
          "175":{
              "color":"#000000",
              "label":"Other"
          },
          "176":{
              "color":"#e9ffbe",
              "label":"Grass/Pasture"
          },
          "177":{
              "color":"#000000",
              "label":"Other"
          },
          "178":{
              "color":"#000000",
              "label":"Other"
          },
          "179":{
              "color":"#000000",
              "label":"Other"
          },
          "180":{
              "color":"#000000",
              "label":"Other"
          },
          "181":{
              "color":"#000000",
              "label":"Other"
          },
          "182":{
              "color":"#000000",
              "label":"Other"
          },
          "183":{
              "color":"#000000",
              "label":"Other"
          },
          "184":{
              "color":"#000000",
              "label":"Other"
          },
          "185":{
              "color":"#000000",
              "label":"Other"
          },
          "186":{
              "color":"#000000",
              "label":"Other"
          },
          "187":{
              "color":"#000000",
              "label":"Other"
          },
          "188":{
              "color":"#000000",
              "label":"Other"
          },
          "189":{
              "color":"#000000",
              "label":"Other"
          },
          "190":{
              "color":"#80b3b3",
              "label":"Woody Wetlands"
          },
          "191":{
              "color":"#000000",
              "label":"Other"
          },
          "192":{
              "color":"#000000",
              "label":"Other"
          },
          "193":{
              "color":"#000000",
              "label":"Other"
          },
          "194":{
              "color":"#000000",
              "label":"Other"
          },
          "195":{
              "color":"#80b3b3",
              "label":"Herbaceous Wetlands"
          },
          "196":{
              "color":"#000000",
              "label":"Other"
          },
          "197":{
              "color":"#000000",
              "label":"Other"
          },
          "198":{
              "color":"#000000",
              "label":"Other"
          },
          "199":{
              "color":"#000000",
              "label":"Other"
          },
          "200":{
              "color":"#000000",
              "label":"Other"
          },
          "201":{
              "color":"#000000",
              "label":"Other"
          },
          "202":{
              "color":"#000000",
              "label":"Other"
          },
          "203":{
              "color":"#000000",
              "label":"Other"
          },
          "204":{
              "color":"#00ff8c",
              "label":"Pistachios"
          },
          "205":{
              "color":"#d69dbc",
              "label":"Triticale"
          },
          "206":{
              "color":"#ff6666",
              "label":"Carrots"
          },
          "207":{
              "color":"#ff6666",
              "label":"Asparagus"
          },
          "208":{
              "color":"#ff6666",
              "label":"Garlic"
          },
          "209":{
              "color":"#ff6666",
              "label":"Cantaloupes"
          },
          "210":{
              "color":"#ff91ab",
              "label":"Prunes"
          },
          "211":{
              "color":"#344a34",
              "label":"Olives"
          },
          "212":{
              "color":"#e67525",
              "label":"Oranges"
          },
          "213":{
              "color":"#ff6666",
              "label":"Honeydew Melons"
          },
          "214":{
              "color":"#ff6666",
              "label":"Broccoli"
          },
          "215":{
              "color":"#66994d",
              "label":"Avocados"
          },
          "216":{
              "color":"#ff6666",
              "label":"Peppers"
          },
          "217":{
              "color":"#b39c70",
              "label":"Pomegranates"
          },
          "218":{
              "color":"#ff91ab",
              "label":"Nectarines"
          },
          "219":{
              "color":"#ff6666",
              "label":"Greens"
          },
          "220":{
              "color":"#ff91ab",
              "label":"Plums"
          },
          "221":{
              "color":"#ff6666",
              "label":"Strawberries"
          },
          "222":{
              "color":"#ff6666",
              "label":"Squash"
          },
          "223":{
              "color":"#ff91ab",
              "label":"Apricots"
          },
          "224":{
              "color":"#00af4d",
              "label":"Vetch"
          },
          "225":{
              "color":"#ffd400",
              "label":"Dbl Crop WinWht/Corn"
          },
          "226":{
              "color":"#ffd400",
              "label":"Dbl Crop Oats/Corn"
          },
          "227":{
              "color":"#ff6666",
              "label":"Lettuce"
          },
          "228":{
              "color":"#ffd400",
              "label":"Dbl Crop Triticale/Corn"
          },
          "229":{
              "color":"#ff6666",
              "label":"Pumpkins"
          },
          "230":{
              "color":"#8a6453",
              "label":"Dbl Crop Lettuce/Durum Wht"
          },
          "231":{
              "color":"#ff6666",
              "label":"Dbl Crop Lettuce/Cantaloupe"
          },
          "232":{
              "color":"#ff2626",
              "label":"Dbl Crop Lettuce/Cotton"
          },
          "233":{
              "color":"#e2007f",
              "label":"Dbl Crop Lettuce/Barley"
          },
          "234":{
              "color":"#ff9e0f",
              "label":"Dbl Crop Durum Wht/Sorghum"
          },
          "235":{
              "color":"#ff9e0f",
              "label":"Dbl Crop Barley/Sorghum"
          },
          "236":{
              "color":"#a87000",
              "label":"Dbl Crop WinWht/Sorghum"
          },
          "237":{
              "color":"#ffd400",
              "label":"Dbl Crop Barley/Corn"
          },
          "238":{
              "color":"#a87000",
              "label":"Dbl Crop WinWht/Cotton"
          },
          "239":{
              "color":"#267300",
              "label":"Dbl Crop Soybeans/Cotton"
          },
          "240":{
              "color":"#267300",
              "label":"Dbl Crop Soybeans/Oats"
          },
          "241":{
              "color":"#ffd400",
              "label":"Dbl Crop Corn/Soybeans"
          },
          "242":{
              "color":"#000099",
              "label":"Blueberries"
          },
          "243":{
              "color":"#ff6666",
              "label":"Cabbage"
          },
          "244":{
              "color":"#ff6666",
              "label":"Cauliflower"
          },
          "245":{
              "color":"#ff6666",
              "label":"Celery"
          },
          "246":{
              "color":"#ff6666",
              "label":"Radishes"
          },
          "247":{
              "color":"#ff6666",
              "label":"Turnips"
          },
          "248":{
              "color":"#ff6666",
              "label":"Eggplants"
          },
          "249":{
              "color":"#ff6666",
              "label":"Gourds"
          },
          "250":{
              "color":"#ff6666",
              "label":"Cranberries"
          },
          "251":{
              "color":"#ffd400",
              "label":"Other"
          },
          "252":{
              "color":"#267300",
              "label":"Other"
          },
          "253":{
              "color":"#a87000",
              "label":"Other"
          },
          "254":{
              "color":"#267300",
              "label":"Dbl Crop Barley/Soybeans"
          },
          "255":{
              "color":"#000000",
              "label":"Other"
          },
          }
const ndviScale = d3.schemeSpectral[9].filter((x,index) =>index < d3.schemeSpectral[9].length - 1).map((x,index) =>d3.scaleLinear().range([d3.schemeSpectral[9][index],d3.schemeSpectral[9][index+1]]).domain([0, 31]))

export default {
  satellite: {
    label:"Satellite (2020)",
    showLegend:false,
    showTransparency:false,
    colorFunc: (value) => {
      return 'blue'
    },
    selectedColor:'orange',
    defaultTransparency:0.0
  },
  overlay_croptype: {
    label:"Crop Type (2020)",
    showLegend:true,
    showTransparency:true,
    colorFunc: (value) => {
      if(value)
      {
        var col = cropTypeScale[value]
        if(col)
        {
          return col.color
        }
      }
      return "clear"
    },
    labelFunc: (value) => {
      if(value)
      {
        var col = cropTypeScale[value]
        if(col)
        {
          return col.label
        }
      }
      return ""
    },
    selectedColor:'red',
    defaultTransparency:0.7
  },
  overlay_ndvi: {
    label:"NDVI (2020)",
    showLegend:true,
    showTransparency:true,
    min: 0,
    max: 1,
    units: null,
    colorFunc: (value) => {
      if(value != null)
      {
        var valD = Math.floor(value/32)
        if(ndviScale[valD])
        {
            var col = ndviScale[valD](value%32)
            return col
        }
      }
      return "clear"
    },
    domain: [...Array(255).keys()],
    selectedColor:'blue',
    defaultTransparency:0.7
  },
  overlay_yield_corn: {
    label:"Corn Yield (2020)",
    showLegend:true,
    showTransparency:true,
    min: 110,
    max: 270,
    units: "bushels/acre",
    colorFunc: (value) => {
      if(value != null)
      {
          var val = Math.max(110,value)
          val = Math.min(val,270)
          var color = evaluate_cmap((val-110)/160, 'RdYlGn', false)
          return 'rgb(' + color.join(', ') + ')'
      }
      return "clear"
    },
    domain: [...Array(160).keys()],
    selectedColor:'blue',
    defaultTransparency:0.7
  },
  overlay_yield_soybean: {
    label:"Soybean Yield (2020)",
    showLegend:true,
    showTransparency:true,
    min: 35,
    max: 90,
    units: "bushels/acre",
    colorFunc: (value) => {
      if(value != null)
      {
        var val = Math.max(35,value)
        val = Math.min(val,90)
        var color = evaluate_cmap((val-35)/55, 'RdYlGn', false)
        return 'rgb(' + color.join(', ') + ')'
      }
      return "clear"
    },
    domain: [...Array(100).keys()].map(x=>55*x/100),
    selectedColor:'blue',
    defaultTransparency:0.7
  },
  overlay_n2o: {
    label:"N\u2082O (2020)",
    showLegend:true,
    showTransparency:true,
    min: -0.5,
    max: 0.5,
    units: "Tons CO\u2082eq /ac/year",
    colorFunc: (value) => {
      if(value != null)
      {
        if(value == -10)
        {
            return 'clear'
        }
        var val = Math.max(-0.5,value)
        val = Math.min(val,0.5)
        var color = evaluate_cmap((val+0.5)/1.0, 'coolwarm', false)
        return 'rgb(' + color.join(', ') + ')'
      }
      return "clear"
    },
    domain: [...Array(100).keys()].map(x=>1.0*x/100),
    selectedColor:'blue',
    defaultTransparency:0.7
  },
  overlay_ch4: {
    label:"CH\u2084 (2020)",
    showLegend:true,
    showTransparency:true,
    min: -0.07,
    max: 0.07,
    units: "Tons CO\u2082eq /ac/year",
    colorFunc: (value) => {
      if(value != null)
      {
        if(value == -10)
        {
            return 'clear'
        }
        var val = Math.max(-.07,value)
        val = Math.min(val,.07)
        var color = evaluate_cmap((val+.07)/.14, 'coolwarm', false)
        return 'rgb(' + color.join(', ') + ')'
      }
      return "clear"
    },
    domain: [...Array(100).keys()].map(x=>.14*x/100),
    selectedColor:'blue',
    defaultTransparency:0.7
  },
  overlay_soc: {
    label:"SOC (2020)",
    showLegend:true,
    showTransparency:true,
    min: -1.5,
    max: 1.5,
    units: "Tons CO\u2082eq /ac/year",
    colorFunc: (value) => {
        
      if(value != null)
      {

        if(value == -10)
        {
            return 'clear'
        }

        var val = Math.max(-1.5,value)
        val = Math.min(val,1.5)
        var color = evaluate_cmap((val+1.5)/3, 'coolwarm', false)
        return 'rgb(' + color.join(', ') + ')'
      }
      return "clear"
    },
    domain: [...Array(100).keys()].map(x=>3*x/100),
    selectedColor:'blue',
    defaultTransparency:0.7
  },
  overlay_ghg: {
    label:"GHG (2020)",
    showLegend:true,
    showTransparency:true,
    min: -1.5,
    max: 1.5,
    units: "Tons CO\u2082eq /ac/year",
    colorFunc: (value) => {
      if(value != null)
      {
        if(value == -10)
        {
            return 'clear'
        }
        var val = Math.max(-1.5,value)
        val = Math.min(val,1.5)
        var color = evaluate_cmap((val+1.5)/3, 'coolwarm', false)
        return 'rgb(' + color.join(', ') + ')'
      }
      return "clear"
    },
    domain: [...Array(100).keys()].map(x=>3*x/100),
    selectedColor:'blue',
    defaultTransparency:0.7
  },
  overlay_n2o_avg: {
    label:"Avg N\u2082O (2016 - 2020)",
    showLegend:true,
    showTransparency:true,
    min: -0.5,
    max: 0.5,
    units: "Tons CO\u2082eq /ac/year",
    colorFunc: (value) => {
      if(value != null)
      {
        if(value == -10)
        {
            return 'clear'
        }
        var val = Math.max(-0.5,value)
        val = Math.min(val,0.5)
        var color = evaluate_cmap((val+0.5)/1.0, 'coolwarm', false)
        return 'rgb(' + color.join(', ') + ')'
      }
      return "clear"
    },
    domain: [...Array(100).keys()].map(x=>1.0*x/100),
    selectedColor:'blue',
    defaultTransparency:0.7
  },
  overlay_ch4_avg: {
    label:"Avg CH\u2084 (2016 - 2020)",
    showLegend:true,
    showTransparency:true,
    min: -0.07,
    max: 0.07,
    units: "Tons CO\u2082eq /ac/year",
    colorFunc: (value) => {
      if(value != null)
      {
        if(value == -10)
        {
            return 'clear'
        }
        var val = Math.max(-.07,value)
        val = Math.min(val,.07)
        var color = evaluate_cmap((val+.07)/.14, 'coolwarm', false)
        return 'rgb(' + color.join(', ') + ')'
      }
      return "clear"
    },
    domain: [...Array(100).keys()].map(x=>.14*x/100),
    selectedColor:'blue',
    defaultTransparency:0.7
  },
  overlay_soc_avg: {
    label:"Avg SOC (2016 - 2020)",
    showLegend:true,
    showTransparency:true,
    min: -1.5,
    max: 1.5,
    units: "Tons CO\u2082eq /ac/year",
    colorFunc: (value) => {
        
      if(value != null)
      {

        if(value == -10)
        {
            return 'clear'
        }

        var val = Math.max(-1.5,value)
        val = Math.min(val,1.5)
        var color = evaluate_cmap((val+1.5)/3, 'coolwarm', false)
        return 'rgb(' + color.join(', ') + ')'
      }
      return "clear"
    },
    domain: [...Array(100).keys()].map(x=>3*x/100),
    selectedColor:'blue',
    defaultTransparency:0.7
  },
  overlay_ghg_avg: {
    label:"Avg GHG (2016 - 2020)",
    showLegend:true,
    showTransparency:true,
    min: -1.5,
    max: 1.5,
    units: "Tons CO\u2082eq /ac/year",
    colorFunc: (value) => {
      if(value != null)
      {
        if(value == -10)
        {
            return 'clear'
        }
        var val = Math.max(-1.5,value)
        val = Math.min(val,1.5)
        var color = evaluate_cmap((val+1.5)/3, 'coolwarm', false)
        return 'rgb(' + color.join(', ') + ')'
      }
      return "clear"
    },
    domain: [...Array(100).keys()].map(x=>3*x/100),
    selectedColor:'blue',
    defaultTransparency:0.7
  },
};
