import React, {useState,useEffect} from "react";
import { useSelector } from "react-redux";
import { setSelectedField, setZoomTo} from "../../redux/actions/fields";

const fieldPressed = (field) => {
  if(!field)
  {
    return
  }

  setSelectedField({
    geometry:JSON.parse(field.geometry),
    properties:{
      field_id:field.field_id,
      lat:field.lat,
      lng:field.lon
    }
  })
}
const countyPressed = (county) => {
  if(!county)
  {
    return
  }
  setZoomTo(county.bounds)
}
function FieldsList() {
  const fieldsMap = useSelector((state) => state.fields.favoriteFields);
  const selectedField = useSelector((state) => state.fields.selectedField);

  var selectedFieldId = null;
  if(selectedField && selectedField.properties)
  {
    selectedFieldId = selectedField.properties.field_id
  }
  
  var fieldList = []
  if(fieldsMap)
  {
    /*Object.keys(fieldsMap).map(statefp=>
      {
        Object.keys(fieldsMap[statefp]["counties"]).map(countyfp=>
          {
            Object.keys(fieldsMap[statefp]['counties'][countyfp]['fields']).map(field_id=>
              {
                fieldList.push(fieldsMap[statefp]['counties'][countyfp]['fields'][field_id])
              }
            )
          }
        )
      }
    );*/
    if(fieldsMap && fieldsMap['fields'])
    {
      Object.keys(fieldsMap['fields']).map(field_id=>{
        fieldList.push(fieldsMap['fields'][field_id])
      })
    }
  }
  var countyList = []
  if(fieldsMap)
  {
    /*Object.keys(fieldsMap).map(statefp=>
      {
        Object.keys(fieldsMap[statefp]["counties"]).map(countyfp=>
          {
            Object.keys(fieldsMap[statefp]['counties'][countyfp]['fields']).map(field_id=>
              {
                fieldList.push(fieldsMap[statefp]['counties'][countyfp]['fields'][field_id])
              }
            )
          }
        )
      }
    );*/
    if(fieldsMap && fieldsMap['counties'])
    {
      Object.keys(fieldsMap['counties']).map(fips=>{
        countyList.push(fieldsMap['counties'][fips])
      })
    }
  }
  return (
    <div style={{marginTop:22,width:274,height:391,backgroundColor:'#F1F3F8',borderRadius:14,display:'flex'}}>
      <div className="custom-scrollbar" style={{flex:1,overflowY:'auto',marginLeft:38,marginBottom:25,marginRight:25,marginTop:25,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
        {fieldList.map(field=>
          <div style={{cursor:'pointer',padding:5}} onClick={()=>fieldPressed(field)}>
              <label style={{cursor:'pointer',fontWeight:'400',fontSize:16,color:selectedFieldId==field.field_id ? '#3EB96C' : '#3D414B'}}>
                {field.name ? field.name : ('Field ' + field.field_id)}
              </label>
          </div>
          )}
          <div style={{width:10,marginTop:20}}/>
          {countyList.map(county=>
            <div style={{cursor:'pointer',padding:5}} onClick={()=>countyPressed(county)}>
                <label style={{cursor:'pointer',fontWeight:'400',fontSize:16,color:'#3D414B'}}>
                  {county.name}
                </label>
            </div>
            )}
      </div>
    </div>
  );
}
export default FieldsList;
