import React from "react";
import { useSelector } from "react-redux";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LegendConfig from "./legendConfig"
import { makeStyles } from '@material-ui/styles';
import * as Theme from "../../../../statics/theme"
const useStyles = makeStyles({
  customOutline: {
    "& .MuiOutlinedInput-input": {
      fontSize: 10,
      color:"#585A61",
      fontSize:12,
      fontWeight:'500',
      textAlign:'left'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth:0
    }
  }
});
function OverlaySelect({type,setType}) {
  const classes = useStyles();
  return (
    <div style={{width:290,display:'flex',flexDirection:'row',alignItems:'center',marginBottom:10}}>
      <div style={{}}>
        <label style={{fontWeight:'600',fontSize:14,color:Theme.BLACK_4}}>{'Overlay: '}</label>
      </div>
      <div style={{flex:1}}/>
    <Select
        value={type}
        style={{
          backgroundColor:'#DCE0E7',
          borderRadius:6,
          height:35,
          width:226,
          borderColor:'red',
          fontWeight:'600',
          fontSize:12
        }}
        classes={{ root: classes.customOutline }}
        onChange={(event)=>setType(event.target.value)}
      >
        {Object.keys(LegendConfig).map(value=>
            <MenuItem value={value}>{LegendConfig[value].label}</MenuItem>
          )}
      </Select>
      </div>
  );
}
export default OverlaySelect;
