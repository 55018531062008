import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import ScenarioList from './ScenarioList';
import Scenario from "./Scenario";
import * as Theme from "../../../../statics/theme"
const tempScenarios = {
  "Baseline": {
    label:"Baseline",
    name:"Baseline",
    coverCrop:false,
    noTill:false,
    improvedFertilizer:false,
    soc:.33,
    n20:2.69,
    ch4:-.21,
    total:2.81,
    tonsCO2:.42,
    tonnesTotal:42
  },
  "Covercrop": {
    label:"Scenario 1",
    name:"Cover Crop",
    coverCrop:true,
    noTill:false,
    improvedFertilizer:false,
    soc:.30,
    n20:2.56,
    ch4:-.19,
    total:2.75,
    tonsCO2:.45,
    tonnesTotal:45,
    savings:20
  },
  "No Till": {
    label:"Scenario 2",
    name:"No Till",
    coverCrop:false,
    noTill:true,
    improvedFertilizer:false,
    soc:.30,
    n20:2.56,
    ch4:-.19,
    total:2.75,
    tonsCO2:.45,
    tonnesTotal:45,
    savings:20
  },
  "Fertilizer Management": {
    label:"Scenario 3",
    name:"Fertilizer Management",
    coverCrop:false,
    noTill:false,
    improvedFertilizer:true,
    soc:.30,
    n20:2.56,
    ch4:-.19,
    total:2.75,
    tonsCO2:.45,
    tonnesTotal:45,
    savings:20
  },
  "All": {
    label:"Scenario 4",
    name:"Covercrop, No Till, Fertilizer Management",
    coverCrop:true,
    noTill:true,
    improvedFertilizer:true,
    soc:.30,
    n20:2.56,
    ch4:-.19,
    total:2.75,
    tonsCO2:.45,
    tonnesTotal:45,
    savings:20
  }
}
function Scenarios({selectedField,fieldData}) {
  if(!selectedField || !fieldData || !fieldData.scenarios)
  {
    return <div/>
  }
  var baseline = fieldData.scenarios['baseline']
  var nonBaseline = Object.keys(fieldData.scenarios).filter(scenario=>scenario!="baseline")
  return (
    <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <label style={{fontSize:32,fontWeight:"700",marginTop:30,color:Theme.BLACK_4}}>Scenarios</label>
        <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:25}}/>
        <div  className="custom-scrollbar" style={{width:'100%',flex:1,overflowY:'scroll'}}>
          <div style={{marginLeft:45,marginRight:45,height:200}} >
            <div style={{display:'flex',flexDirection:'column',height:'fit-content',marginBottom:60}}>
              <ScenarioList scenarios={fieldData.scenarios}/>
              {nonBaseline.map(scenario =>
                <Scenario scenario={fieldData.scenarios[scenario]} baseline={baseline} />
              )}

              <div style={{width:10,height:40}}/>
            </div>
          </div>

        </div>
    </div>
  );
}
export default Scenarios;
