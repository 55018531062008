import React from "react";
import { useSelector } from "react-redux";
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import {
  setView
} from "../../redux/actions/main";
import * as Theme from "../../statics/theme" 

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2.5,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: Theme.TOPBAR_NAVY,
        opacity: 1,
        border: '1px solid #fff',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-root': {
    overflow:"visible"
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: Theme.TOPBAR_NAVY,
    opacity: 1,
    border: '1px solid #fff',
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
function ViewSelect() {
  const selectedView = useSelector((state) => state.main.view);
  return (
    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
      <div style={{padding:10,width:150}} >
        <label style={{color:"white",float:'right',fontWeight:selectedView=="Map"?"700":"500",fontSize:16}}>Map view</label>
      </div>
      <IOSSwitch onChange={(event)=>setView(event.target.checked?"Tabular":"Map")}
       sx={{ m: 1,overflow:'visible' }}/>
      <div style={{padding:10,width:150}} >
        <label style={{color:"white",float:'left',fontWeight:selectedView=="Tabular"?"700":"500",fontSize:16}}>Tabular view</label>
      </div>
    </div>
  );
}
export default ViewSelect;
