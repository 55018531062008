import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import FieldLevelLegend from "../../../Map/Overlays/FieldOverlay/legend"
import TransparencySlider from "../../../Map/Overlays/FieldOverlay/transparencySlider"
import OverlaySelect from "../../../Map/Overlays/FieldOverlay/overlaySelect";
import LegendConfig from "../../../Map/Overlays/FieldOverlay/legendConfig";
import * as Theme from "../../../../statics/theme"
const TextLabel = ({label,value,singleLine}) => {
  return (
    <div style={{display:"flex",flexDirection:singleLine?"row":"column",alignItems:singleLine?"center":"flex-start",marginTop:20}}>
      <label style={{fontWeight:"700",fontSize:16,marginRight:10,color:Theme.DARK_GREEN_2}}>{label}</label>
      <label style={{fontWeight:"500",fontSize:14, marginTop:singleLine?0:10,color:Theme.DARK_GRAY_4}}>{value}</label>
    </div>
  )
}
var mapI;
var popup;
function BasicInfo({selectedField,fieldData}) {
  const [overlayType,setOverlayType] = useState("satellite")
  const [transparency,setTransparency] = useState(0.5)
  const [map,setMap] = useState(null)
  useEffect(()=>{
    setupMap(selectedField,fieldData);
  },[selectedField,fieldData])
  useEffect(()=>{
    updateOverlay(overlayType,transparency)
  },[overlayType,transparency])
  useEffect(()=>{
    if(overlayType && LegendConfig[overlayType])
    {
      setTransparency(LegendConfig[overlayType].defaultTransparency)
    }
  },[overlayType])

  const setupMap = (selectedField,fieldData) => {
    if(mapI != null)
    {
      mapI.remove()
    }
    if(selectedField && selectedField.properties && fieldData && fieldData.geometry)
    {
      mapI = new window.maplibregl.Map({
        container: "mini_map",
        style:
          "https://api.maptiler.com/maps/hybrid/style.json?key=kKXD4OdNK4kH76yz9rU4",
        center: [selectedField.properties.lng, selectedField.properties.lat],
        zoom: 13.5,
        //dragPan: false,
      });
      mapI.scrollZoom.disable();

      mapI.on("load",()=>{
        setMap(mapI);
        var outline_properties = {
          id: "outline",
          type: "line",
          source: "field-source",
          paint: {
            "line-color": "#0088dc",
            "line-opacity": 0.6,
            "line-width": 2,
          },
          minzoom:11
        };
        var layer_properties = {
          id: "overlay",
          type: "fill",
          source: "field-source",
          paint: {
            "fill-color": ["get", overlayType + "_color"],

            "fill-opacity": [
              "case",
              ["==", ["get", overlayType + "_color"], "clear"],
              0,
              transparency
            ],
          },
          minzoom:11
        };
        mapI.addSource("field-source", {
          type: "geojson",
          data: fieldData.geometry
        });
        mapI.fitBounds(fieldData.geometry.bbox,{padding:40,duration:0})
        mapI.addLayer(layer_properties);
        mapI.addLayer(outline_properties);

        popup = new window.maplibregl.Popup({
          closeButton: false,
          closeOnClick: false
          });

        
        mapI.on('mouseleave', 'overlay', () => {
          mapI.getCanvas().style.cursor = '';
          popup.remove();
          });
      })
    }
  }

  const updateOverlay = (overlayType, transparency) => {
    if(map && map.getLayer("outline") && map.getLayer("overlay"))
    {
      map.setPaintProperty("overlay", "fill-color", ["get", overlayType + "_color"]);
        map.setPaintProperty("overlay", "fill-opacity", [
          "case",
          ["==", ["get", overlayType + "_color"], "clear"],
          0,
          transparency
        ]);

        map.on('mouseenter', 'overlay', (e) => {
          map.getCanvas().style.cursor = 'pointer';
 
          // Copy coordinates array.
          if(selectedField && selectedField.properties && selectedField.properties.lat && selectedField.properties.lng && overlayType)
          {
            const coordinates = [selectedField.properties.lng,selectedField.properties.lat];
            //const description = e.features[0].properties.description;
            
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            
            // Populate the popup and set its coordinates
            // based on the feature found.
            if(fieldData && fieldData.overlays && fieldData.overlays[overlayType])
            {
              if(overlayType == "overlay_croptype")
              {
                popup.setLngLat(coordinates).setHTML(LegendConfig.overlay_croptype.labelFunc(fieldData.overlays[overlayType])).addTo(map);
              }else {
                popup.setLngLat(coordinates).setHTML(parseInt(fieldData.overlays[overlayType]*100)/100 + (LegendConfig[overlayType].units ? (" " + LegendConfig[overlayType].units) : "")).addTo(map);

              }
            }
          }
        });
    }
  }

  if(!selectedField || !fieldData)
  {
    return <div/>
  }
  var fieldBasicInfo = fieldData["basic_info"]

  return (
    <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <label style={{fontSize:32,fontWeight:"700",marginTop:30,color:Theme.BLACK_4}}>Basic Information</label>
        <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:25}}/>

        <div style={{display:"flex",flexDirection:"row",paddingLeft:40,paddingRight:40,width:"100%"}}>
          <div style={{height:"100%",flex:1,display:"flex",flexDirection:"column",alignItems:"flex-start",paddingLeft:45,paddingTop:10}}>
            <TextLabel singleLine={true} label={"Field ID:"} value={fieldBasicInfo["field_id"]?fieldBasicInfo["field_id"]:""} />
            <TextLabel singleLine={true} label={"Field Name:"} value={"Field " + (fieldBasicInfo["field_id"]?fieldBasicInfo["field_id"]:"")} />
            <TextLabel singleLine={true} label={"County:"} value={(fieldBasicInfo&&fieldBasicInfo["state_name"]&&fieldBasicInfo["county_name"])?(fieldBasicInfo["county_name"] + ", " + fieldBasicInfo["state_name"]):""} />
            <TextLabel singleLine={true} label={"Total Acreage:"} value={(fieldBasicInfo&&fieldBasicInfo["acreage"])?(fieldBasicInfo["acreage"] + " acres"):""} />
            <TextLabel singleLine={true} label={"Area Utilization:"} value={(fieldBasicInfo&&fieldBasicInfo["area_utilization"])?(fieldBasicInfo["area_utilization"] + "%"):""} />
            <TextLabel singleLine={true} label={"NCCPI:"} value={(fieldBasicInfo&&fieldBasicInfo["soil_pi"])?(parseInt(fieldBasicInfo["soil_pi"]*100)):""} />
            <TextLabel singleLine={false} label={"Avg rainfall (Mar.-Sep.):"} value={(fieldBasicInfo&&fieldBasicInfo["avg_rainfall"])?(fieldBasicInfo["avg_rainfall"] + " inches"):""} />
            <TextLabel singleLine={false} label={"Avg GDD (Mar.-Sep.):"} value={(fieldBasicInfo&&fieldBasicInfo["avg_gdd"])?(fieldBasicInfo["avg_gdd"] + " degree days"):""} />
          </div>
          <div style={{height:"100%",flex:1,display:"flex",flexDirection:"column",justifyContent:'center',marginTop:25,paddingRight:40}}>
            <div style={{backgroundColor:"#FFF1E8",borderRadius:16,flex:1,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start"}}>
              <div id={"mini_map"} style={{width:290,height:238,marginTop:25,borderRadius:12, marginBottom:15}}>
              </div>
                <TransparencySlider type={overlayType} setTransparency={setTransparency} transparency={transparency}/>
                <OverlaySelect type={overlayType} setType={setOverlayType}/>
                <FieldLevelLegend type={overlayType}/>
            </div>
          </div>
        </div>
    </div>
  );
}
export default BasicInfo;
