import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotateLabels from 'chartjs-plugin-annotation'
import * as Theme from "../../../../statics/theme"
import 'chart.js/auto'

import { Bar } from 'react-chartjs-2';
import { thresholdFreedmanDiaconis } from "d3";

const options = {
  responsive: true,
  maintainAspectRatio:false,
  plugins: {
    "tooltip": {
      "enabled": false
    },
    legend: {
      display:false
    },
    title: {
      display: false,
    },
    datalabels: {
      display: true,
      color: function(context) {
          return context.dataset.color
      },
      formatter: function(value,{dataIndex}) {
          return parseFloat(value[1]).toFixed(2)
      },
      font: function(context) {
          return {
            size:12,
            weight:'500'
          }
      },
      anchor: "center",
      align: "center"
    }
  },
  scales: {
    y: {
      stacked: true,
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
              if(Number.isInteger(value*10) && value >= 0)
              {
                return parseFloat(value).toFixed(1);
              }
            },
            // color:'#B4B8C4',
        },
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:1,
          lineWidth:0
        },
        beginAtZero:true
    },
    x: {
      stacked: true,
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:1,
          lineWidth:0
        }
    },
  }
};

const YieldBox = ({label, color, backgroundColor, value,marginLeft}) => {


  return (
    <div style={{marginLeft,borderRadius:10,boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)',border:'1px solid #DCE0E7',height:137,width:296,display:'flex',flexDirection:'column'}}>
      <div style={{width:'100%',display:'flex'}}>
        <div style={{paddingLeft:14,marginLeft:24,marginTop:14,paddingRight:14,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:18,height:30,backgroundColor:backgroundColor}}>
            <label style={{color:color,fontSize:20,fontWeight:700}}>{label}</label>
        </div>
      </div>
      <div style={{flex:1}}/>
      <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'flex-end',marginBottom:16}}>
        <label style={{marginLeft:24,color:Theme.DARK_GREEN_1,fontWeight:'700',fontSize:56,minWidth:130,textAlign:'left'}}>{value}</label>
        <div style={{marginBottom:10,display:'flex',marginLeft:10,marginRight:21,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
          <label style={{color:Theme.DARK_GRAY_4,fontWeight:'600',fontSize:20}}>{"million"}</label>
          <label style={{color:Theme.DARK_GRAY_4,fontWeight:'500',fontSize:16,marginTop:-2}}>{"bushels / year"}</label>
        </div>
      </div>
    </div>
  )
}
const Graph = ({shedData}) =>{
  if(!shedData || !shedData["productivity"] || !shedData["productivity"]["land_use"])
  {
    return
  }

  var landUse = shedData["productivity"]["land_use"]

  var data = {
    labels:Object.keys(landUse),
    datasets: [
      {
        data: Object.keys(landUse).map(year=>[0,landUse[year]["other"]]),
        borderRadius:0,
        backgroundColor: Theme.LIGHT_GRAY_1,
        barThickness:50,
        color:Theme.DARK_GREEN_2
      },
      {
        data: Object.keys(landUse).map(year=>[0,landUse[year]["soybeans"]]),
        borderRadius:0,
        backgroundColor: Theme.DARK_GREEN_1,
        barThickness:50,
        color:'white'
      },
      {
        data: Object.keys(landUse).map(year=>[0,landUse[year]["corn"]]),
        borderRadius:{
          topLeft: 6,
          bottomLeft: 0,
          topRight: 6,
          bottomRight: 0,
        },
        backgroundColor: Theme.YELLOW,
        barThickness:50,
        color:Theme.DARK_GREEN_2
      },
    ]
  };

  return (
    <div style={{flex:1,height:330,marginTop:30,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
      <label style={{color:'#999DA9',fontSize:12,fontWeight:'500',float:'left',marginLeft:70}}>Million Acres</label>
      <div style={{height:300,width:'100%',display:'flex',flexDirection:'row'}}>
        <div style={{height:300,width:450,flex:1,marginLeft:35}}>
          <Bar options={options} plugins={[ChartDataLabels]} data={data}/>
        </div>
        <div style={{marginTop: 50, width:130,marginLeft:20,height:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
            <div style={{width:16,height:16,borderRadius:16,backgroundColor:Theme.YELLOW}}/>
            <label style={{marginLeft:8,fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4}}>Corn</label>
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:18}}>
            <div style={{width:16,height:16,borderRadius:16,backgroundColor:Theme.DARK_GREEN_1}}/>
            <label style={{marginLeft:8,fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4}}>Soybeans</label>
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:18}}>
            <div style={{width:16,height:16,borderRadius:16,backgroundColor:Theme.LIGHT_GRAY_1}}/>
            <label style={{marginLeft:8,fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4, textAlign:'left'}}>Other Uses<br></br>(Ex: fallow)</label>
          </div>
        </div>
      </div>
    </div>
  )
} 


function MajorCrops({selectedShed,shedData}) {
  if(!selectedShed || !shedData)
  {
    return <div/>
  }

  var cornYield = 0
  if(shedData["productivity"] && shedData["productivity"]["corn_bushels"])
  {
    cornYield = parseInt(shedData["productivity"]["corn_bushels"]*10)/10
  }
  var soybeansYield = 0
  if(shedData["productivity"] && shedData["productivity"]["soybeans_bushels"])
  {
    soybeansYield = parseInt(shedData["productivity"]["soybeans_bushels"]*10)/10
  }

  return (
    <div style={{marginTop:34,borderRadius:14,overflow:'hidden',marginBottom:42,position:'relative',marginLeft:45,marginRight:45,height:'fit-content',display:"flex",flexDirection:"column",alignItems:"center",boxShadow:"0px 2px 15px rgba(48, 68, 120, 0.16)"}}>
          <label style={{fontWeight:"700",fontSize:20,color:Theme.BLACK_4,marginTop:24}}>{'Major crops and their production'}</label>
          <div style={{width:'100%',flex:1,display:'flex',flexDirection:'row',marginTop:42}}>
            <div style={{flex:1,display:'flex',flexDirection:'column'}}>
              <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <YieldBox label={'corn'} color={Theme.DARK_GREEN_2} backgroundColor={Theme.YELLOW} value={cornYield} />

                <YieldBox label={'soybeans'} color={'white'} backgroundColor={Theme.DARK_GREEN_1} value={soybeansYield} marginLeft={12} />
              </div>

              <div style={{width:'100%',marginTop:38}}>
                <label style={{fontWeight:"700",fontSize:20,color:Theme.BLACK_4,textAlign:'left',display:'block',marginLeft:34}}>{'Cropland use'}</label>
              </div>

              <Graph shedData={shedData} />

              <div style={{width:'100%',height:35}}/>
            </div>
          </div>

        
        
       
    </div>
  );
}
export default MajorCrops;
