import { createSlice } from "@reduxjs/toolkit";

export const shedsSlice = createSlice({
  name: "sheds",
  initialState: {
    selectedShed: null,
    selectedShedData: null,
    favoriteSheds: null
  },
  reducers: {
    reducerSetSelectedShed: (state, action) => {
      state.selectedShed = action.payload;
    },
    reducerSetSelectedShedData: (state, action) => {
      state.selectedShedData = action.payload;
    },
    reducerSetFavoriteSheds: (state, action) => {
      state.favoriteSheds = action.payload;
    },
  },
});


export default shedsSlice.reducer;

