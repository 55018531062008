import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotateLabels from 'chartjs-plugin-annotation'
import * as Theme from "../../../../statics/theme"

import { Bar } from 'react-chartjs-2';
const colors = ["#2697FF","#FB7F2D","#3EB96C","#33674E","#3D93A6","#1A9275"]
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartAnnotateLabels
);
//ChartJS.plugins.register(ChartAnnotateLabels);
const options = {
  responsive: true,
  maintainAspectRatio:false,
  plugins: {
    annotation: {
      annotations: [
        {
          type: 'line',
          scaleID: 'x',
          borderWidth: 1,
          borderColor: '#B4B8C4',
          value: 3.5,
        }
      ]
    },
    "tooltip": {
      "enabled": false
    },
    legend: {
      position: 'top',
      display:false
    },
    title: {
      display: false,
    },
    datalabels: {
      display: true,
      color: "black",
      formatter: function(value,{dataIndex}) {
          return value.toFixed(2)
      },
      color: function(context) {
        var index = context.dataIndex;
        return colors[index]
      },
      font: function(context) {
        var index = context.dataIndex;
        if(index == 3)
        {
          return {
            size:12,
            weight:'700'
          }
        }
        else {
          return {
            size:12,
            weight:'500'
          }
        }
      },
      anchor: "end",
      offset: -23,
      align: "start"
    }
  },
  scales: {
    y: {
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
              if(Number.isInteger(value) && value >= 0)
              {
                return value.toFixed(0);
              }
            },
            color:'#B4B8C4',
            beginAtZero:true
        },
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:1,
          lineWidth:1
        }
    },
    x: {
        ticks: {
          font: {
           size: 14
          }
        },
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:0,
          lineWidth:0
        }
    },
  }
};
const labels = ['SOC','N\u2082O','CH\u2084','Total','County','State'];


const EmissionsBoxes = ({label, emissionsData}) => {
  if(!emissionsData)
  {
    return <div/>
  }
  var showTotal = label == null ? true : false
  var averageLabel = "Average emissions in this field"
  var averageUnits = "CO\u2082 eq / ac / year"
  var boxColor = Theme.WHITE_3
  var showBorder = true
  var showShadow = false
  if(label != null)
  {
    showBorder = false
    showShadow = true
    averageLabel = "Average emissions of " + label + " in this field"
    averageUnits = "CO\u2082 eq / ac / year"
    boxColor = 'white'
  }

  var averageVal = emissionsData["average_emissions"]
  var totalVal = emissionsData["total_emissions"]

  const Box = ({title, units, value, color}) => {
    return (
      <div style={{borderRadius:10,boxShadow:showShadow?'0px 2px 15px rgba(48, 68, 120, 0.16)':'',border:showBorder?'1px solid #DCE0E7':'',height:123,width:'100%',backgroundColor:color,display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <div style={{width:'100%',display:'flex'}}>
          <label style={{marginLeft:30,color:Theme.BLACK_4,fontSize:14,fontWeight:'500'}}>{title}</label>
        </div>
        <div style={{width:'100%',display:'flex',alignItems:'center',marginTop:5}}>
          <label style={{marginLeft:30,color:Theme.DARK_GREEN_1,fontWeight:'700',fontSize:56}}>{value.toFixed(2)}</label>
          <div style={{display:'flex',marginLeft:15,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
            <label style={{color:Theme.DARK_GRAY_4,fontWeight:'600',fontSize:20}}>Tons</label>
            <label style={{color:Theme.DARK_GRAY_4,fontSize:11.5,fontWeight:'500',marginTop:5}}>{units}</label>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{width:'100%',display:'flex',flexDirection:'row',marginTop:36}}>
        <div style={{flex:1,maxWidth:350}}>
          <Box title={averageLabel} units={averageUnits} value={averageVal} color={boxColor}/>
        </div>
        {showTotal && <div style={{flex:1,marginLeft:12}}>
          <Box title={"Total emissions for this field"} units={"CO\u2082 / eq / year"} value={totalVal} color={boxColor}/>
        </div>}
    </div>
  )
}

const Graph = ({data}) =>{
  if(!data)
  {
    return
  }
  var baseData = {
    labels,
    datasets: [
      {
        data: [],
        borderRadius:6,
        backgroundColor: colors,
        barThickness:50
      },
    ],
  };

  baseData.datasets[0]['data'] = [data['soc'],data['n2o'],data['ch4'],data['ghg'],data['county_average'],data['state_average']]
  return (
    <div style={{width:'100%',height:275,marginTop:30}}>
      <label style={{color:'#999DA9',fontSize:12,fontWeight:'500',float:'left',marginLeft:20}}>Tons CO<sub>2</sub>eq / ac / yr</label>
      <Bar options={options} plugins={[ChartDataLabels]}  data={baseData}/>
    </div>
  )
}


function Emissions({allowClose,label,emissionsData,hint}) {
  const [open, setOpen] = useState(true);
  /*if(!emissionsData)
  {
    return <div/>
  }*/
  var labelColor = "white"
  var labelTextColor = Theme.DARK_GREEN_2
  if(label != null)
  { 
    if(label == "corn")
    {
      labelColor = Theme.YELLOW
    }
    else
    {
      labelColor = "#33674E"
      labelTextColor = "white"
    }
  }
  return (
    <div style={{borderRadius:14,overflow:'hidden',marginBottom:38,position:'relative',marginLeft:45,marginRight:45,height:open?573:80,display:"flex",flexDirection:"column",alignItems:"flex-start",boxShadow:"0px 2px 15px rgba(48, 68, 120, 0.16)"}}>
          <div style={{marginLeft:34,display:"flex",flexDirection:'row',alignItems:'center',justifyContent:'flex-start',marginTop:25}}>
                <label style={{fontSize:32,fontWeight:"700",fontSize:20,color:Theme.BLACK_4}}>Average Emissions</label>
                {hint != null &&
                  <div style={{marginLeft:7,width:16,height:16,border:'1px solid grey',cursor:'pointer', borderColor:Theme.DARK_GRAY_1,borderRadius:16,display:'flex',alignItems:'center',justifyContent:'center',position:'relative'}}
                    onMouseOver={()=>document.getElementById("hidden-div-sustainability").style.display="block"} onMouseOut={()=>document.getElementById("hidden-div-sustainability").style.display="none"}>
                    <label fitcon style={{color:Theme.DARK_GRAY_1,fontSize:12,cursor:'pointer'}}>i</label>
                    <div id="hidden-div-sustainability" style={{display:'none',height:30,width:500,position:'absolute',top:16,left:16}}>
                      <div style={{width:'fit-content',boxShadow:"1px 0px 20px rgba(82, 92, 118, 0.1)",borderRadius:"0px 6px 6px 6px",border:"1px solid gray",borderColor:Theme.LIGHT_GRAY_1,padding:'2px 12px 6px 12px'}}>
                        <label style={{color:Theme.DARK_GRAY_4,fontSize:12,width:'100%'}} >{hint}</label>
                      </div>
                    </div>
                  </div>
                }
                {label != null && 
                  <div style={{paddingLeft:14,marginLeft:10,paddingRight:14,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:18,height:30,backgroundColor:labelColor}}>
                      <label style={{color:labelTextColor,fontSize:20,fontWeight:700}}>{label}</label>
                  </div>
                }
              </div>
        {allowClose && <div style={{cursor:'pointer',position:'absolute',padding:5,right:32,top:32}} onClick={()=>setOpen(!open)}>
            {
              open
              ?
              <KeyboardArrowUpIcon style={{cursor:'pointer',fontSize:20, color:Theme.DARK_GRAY_4}}/>
              :
              <KeyboardArrowDownIcon style={{cursor:'pointer',fontSize:20, color:Theme.DARK_GRAY_4}}/>
            }
          </div>
        }
        {open &&
          <div style={{flex:1,display:'flex',flexDirection:'row'}}>
            <div style={{marginLeft:34,flex:1,marginRight:34}}>
              
              <Graph data={emissionsData}/>
              <div style={{width:'100%',height:0,border:'0px solid #E8EBF1',borderColor:Theme.WHITE_4,borderBottomWidth:1,marginTop:25}}/>
              <EmissionsBoxes label={label} emissionsData={emissionsData}/>

            </div>
          </div>

        }

        
        
       
    </div>
  );
}
export default Emissions;
