import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AddFavoriteField, RemoveFavoriteField } from "../../api/fieldsService/fieldsHelper";
import { setFavoriteFields } from "../../redux/actions/fields";

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
function FavoriteButton({selectedField}) {
  const favoriteFields = useSelector((state) => state.fields.favoriteFields);

  var isFavorite = false;
  var selectedFieldId = null;
  if (selectedField != null && selectedField.properties != null) {
    selectedFieldId = selectedField.properties.field_id;
  }
  if(favoriteFields && favoriteFields['fields'])
  {
    /*isFavorite = Object.keys(favoriteFields).filter(statefp=>{
        return Object.keys(favoriteFields[statefp].counties).filter(countyfp=>{
          return Object.keys(favoriteFields[statefp].counties[countyfp].fields).filter(field_id=>field_id==selectedFieldId).length > 0
        }).length>0
    }).length>0*/
    isFavorite = Object.keys(favoriteFields['fields']).filter(field_id=>field_id == selectedFieldId).length > 0
  }
  

  const setIsFavorite = () => {
    AddFavoriteField(selectedFieldId);
  };
  const removeIsFavorite = () => {
    RemoveFavoriteField(selectedFieldId)
  };
  const favoriteButtonPressed = () => {
    if (isFavorite) {
      removeIsFavorite();
    } else {
      setIsFavorite();
    }
  };
  return (
        <div style={{border:"1px solid #F8BB37",borderRadius:20/2,height:20,width:20,display:"flex",justifyContent:"center",alignItems:"center",
          cursor:'pointer'}} onClick={favoriteButtonPressed}>
          { isFavorite
          ?
            <StarIcon style={{fontSize:16,color:"#F8BB37"}}/>
          :
            <StarBorderIcon style={{fontSize:16,color:"#F8BB37"}}/>
          }
        </div>
  );
}
export default FavoriteButton;
