import ShedsApi from "./index"
import {setFavoriteSheds} from "../../redux/actions/sheds"


export const GetFavoriteSheds = () => {
    ShedsApi.getFavorites().then((response) => {
        if(response.status != 200 || !response.data)
        {
          console.log("GetFavoriteSheds Error: " + response.status)
          setFavoriteSheds(null);
          return;
        }

        var sheds = response.data
        if (sheds != null) {
            setFavoriteSheds(sheds);
        }
      })
      .catch((error)=>{
        console.log("GetFavoriteSheds Error: " + error.toString())
      });
}
