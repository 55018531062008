import React, {useEffect} from "react";
import MapComponent from "./MapComponent";
import {GetFavoriteFields,GetFieldsMap, GetCountiesNASS} from "../../api/fieldsService/fieldsHelper"
import {GetFavoriteSheds} from "../../api/shedsService/shedsHelper"
import { useSelector } from "react-redux";
//This is currently the main page for our website. It displays a map with
//overlays to interact with our fields
function MapPageLibre() {
  const loggedIn = useSelector((state) => state.main.loggedIn);
  useEffect(()=>{
    GetFavoriteFields()
    GetFavoriteSheds()
    GetFieldsMap()
    GetCountiesNASS()
  },[loggedIn])
  return (
    <div style={{flex:1,height:"100%",position:'relative'}}>
      <MapComponent />
    </div>
  );
}
export default MapPageLibre;
