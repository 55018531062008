import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import * as Theme from "../../../../statics/theme"
import Slider from '@mui/material/Slider';
import { withStyles } from '@material-ui/styles';
const CustomSlider = withStyles({
  root: {
    height: 4,
    borderRadius: 10,
    color:Theme.LIGHT_GRAY_3
  },
  track: {
      height: 4,
      borderRadius: 10,
      color:Theme.DARK_GREEN_3
  },
  thumb: {
      height: 12,
      width: 12,
      backgroundColor: Theme.DARK_GREEN_4,
      color: Theme.DARK_GREEN_4,
      boxShadow:null
  },
  valueLabel: {
    fontSize: 16,
    fontWeight: '500',
    top: 1,
    backgroundColor: 'unset',
    color: Theme.DARK_GREEN_4,
  },
})(Slider);
const DrawBar = ({label, baseValue, value, setValue}) =>
{
  return (
    <div style={{width:'100%',marginTop:30,marginBottom:15,display:'flex',flexDirection:'row',justifyContent:'center'}}>
      <label style={{marginLeft:10,fontSize:14,fontWeight:'700',color:Theme.BLACK_4,width:135,textAlign:'left'}}>{label}</label>

      <div style={{flex:1,marginLeft:10,marginRight:10,position:'relative'}} >
        <div style={{position:'absolute',left:0,top:-14}}>
          <label style={{fontWeight:'400',fontSize:11,color:Theme.LIGHT_GRAY_3}}>0</label>
        </div>
        <div style={{position:'absolute',right:0,top:-14}}>
          <label style={{fontWeight:'400',fontSize:11,color:Theme.LIGHT_GRAY_3}}>100</label>
        </div>
        <div style={{position:'absolute',left:baseValue/100*440,top:22,display:'flex',flexDirection:'column',alignItems:'center'}}>
          <div style={{width:0,height:0,borderLeft:'3px solid transparent',borderRight:'3px solid transparent',borderBottom:'6px solid '+Theme.POMERANGE_ORANGE}}/>
          <label style={{fontWeight:'500',fontSize:14,color:Theme.POMERANGE_ORANGE,marginTop:4}}>{baseValue}</label>
        </div>
        <CustomSlider value={value} onChange={(event,newValue)=>setValue(newValue)} min={0} max={100}
        valueLabelDisplay="on"/>
      </div>
    </div>
  )
}
function ChangeBars({shedData}) {
  const [coverCrop,setCoverCrop] = useState(0)
  const [till,setTill] = useState(0)
  const [nitrogen,setNitrogen] = useState(0)
  useEffect(()=>setCoverCrop(coverCropBase),[coverCropBase])
  useEffect(()=>setTill(tillBase),[tillBase])
  useEffect(()=>setNitrogen(nitrogenFertilizerBase),[nitrogenFertilizerBase])
  if(!shedData)
  {
    return <div/>
  }


  var coverCropBase = 5
  var tillBase = 60
  var nitrogenFertilizerBase = 0
  return (
    <div style={{display:'flex',flexDirection:'column',width:'100%',marginTop:20}}>
          <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
            <label style={{color:Theme.BLACK_4,fontWeight:'700',fontSize:20,marginLeft:10}}>Change over 10 years</label>
          </div>

          <div style={{width:'100%',display:'flex',alignItems:'flex-start'}}>
            <label style={{marginTop:34,marginLeft:155,color:Theme.LIGHT_GRAY_4,fontSize:12}}>Adoption %</label>
          </div>

          <DrawBar label={"Cover crop"} value={coverCrop} setValue={setCoverCrop} baseValue={coverCropBase}/>
          <DrawBar label={"No/Low Till"} value={till} setValue={setTill} baseValue={tillBase} />

          <div style={{width:'100%',display:'flex',alignItems:'flex-start'}}>
            <label style={{marginTop:24,marginLeft:155,color:Theme.LIGHT_GRAY_4,fontSize:12}}>Reduction %</label>
          </div>
          <DrawBar label={"Nitrogen Fertiizer Use Reduction"} value={nitrogen} setValue={setNitrogen} baseValue={nitrogenFertilizerBase} />

          <div style={{marginTop:30,height:41,width:132,backgroundColor:Theme.GRASS_GREEN,cursor:'pointer',borderRadius:7,display:'flex',alignItems:'center',justifyContent:'center',marginLeft:10}}>
            <label style={{color:'white',fontSize:14,fontWeight:'600',cursor:'pointer'}}>RUN</label>
          </div>
        </div>


    
  );
}
export default ChangeBars;
