import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import * as Theme from "../../../../statics/theme"

function ScenarioList({scenarios}) {
  if(!scenarios)
  {
    return <div/>
  }
  return (
    <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:40}}>
          <div style={{marginLeft:10,width:160,display:'flex',flexDirection:'column',alignItems:'center'}}>
            <div style={{width:10,height:50}}/>
            <div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'center',height:252}}>
              <div style={{flex:1,width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                <label style={{fontWeight:'700',fontSize:14,color:Theme.BLACK_4}}>Cover crop</label>
              </div>
              <div style={{flex:1,width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                <label style={{fontWeight:'700',fontSize:14,color:Theme.BLACK_4}}>No-Till</label>
              </div>
              <div style={{flex:1,width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start',textAlign:'left'}}>
                <label style={{fontWeight:'700',fontSize:14,color:Theme.BLACK_4}}>Improved Fertilizer Management</label>
              </div>
            </div>
          </div>
          {Object.keys(scenarios).map((scenario,index) => 
            <div style={{display:'flex',flex:1,flexDirection:'column',alignItems:'center',paddingLeft:3,paddingRight:3}}>
              <div style={{width:'100%',height:50,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <label style={{fontWeight:'700',fontSize:12,color:Theme.BLACK_4}}>{scenarios[scenario].label}</label>
              </div>
              <div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'center',height:252,borderRadius:8,background:'linear-gradient(180deg, #F1F3F8 52.08%, rgba(241, 243, 248, 0) 100%)'}}>
                <div style={{width:'100%',flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {
                    scenarios[scenario]["cover_crop"]
                    ?
                      <CheckIcon style={{fontSize:25,fontWeight:'700',color:'#3EB96C'}}/>
                    :
                      <CloseIcon style={{fontSize:25,fontWeight:'700',color:'#F45353'}}/>
                  }
                </div><div style={{width:'100%',flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {
                    scenarios[scenario]["no_till"]
                    ?
                      <CheckIcon style={{fontSize:25,fontWeight:'700',color:'#3EB96C'}}/>
                    :
                      <CloseIcon style={{fontSize:25,fontWeight:'700',color:'#F45353'}}/>
                  }
                </div><div style={{width:'100%',flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {
                    scenarios[scenario]["improved_fertilizer_management"]
                    ?
                      <CheckIcon style={{fontSize:25,fontWeight:'700',color:'#3EB96C'}}/>
                    :
                      <CloseIcon style={{fontSize:25,fontWeight:'700',color:'#F45353'}}/>
                  }
                </div>
              </div>
            </div>
          )}
        </div>


    
  );
}
export default ScenarioList;
