import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    profile:{
      firstName:"Yizhi",
      lastName:"Huang",
      email:"yizhi.huang@habiterre.com",
    },
    loggedIn:false
  },
  reducers: {
    reducerSetProfile: (state, action) => {
      state.profile = action.payload;
    },
    reducerSetLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
  },
});


export default userSlice.reducer;

