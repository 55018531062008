import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DownloadIcon from '@mui/icons-material/Download';
import * as Theme from "../../statics/theme"
function DownloadReport({selectedShed}) {
  return (
    <div style={{marginBottom:25,cursor:"pointer",marginTop:35,width:275,height:40,display:'flex',flexDirection:"row",justifyContent:'center',alignItems:'center',borderRadius:28,border:"2px solid #18442F",borderColor:selectedShed?Theme.DARK_GREEN_2:Theme.DARK_GRAY_1}}>
      <DownloadIcon style={{cursor:"pointer",color:selectedShed?Theme.DARK_GREEN_2:Theme.DARK_GRAY_1,fontSize:15}}/>
      <label style={{cursor:"pointer",marginLeft:5,fontSize:14,color:selectedShed?Theme.BLACK_4:Theme.DARK_GRAY_1}}>Download full report</label>
    </div>
  );
}
export default DownloadReport;
