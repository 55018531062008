import PermissionsAPI from "./index"
import {setPermissions} from "../../redux/actions/main"


export const GetPermissions = (permissionsApp) => {
  PermissionsAPI.getPermissions(permissionsApp).then((response) => {
        if(response.status != 200 || !response.data)
        {
          console.log("GetPermissions Error: " + response.status)
          return;
        }
        setPermissions(response.data)
      })
      .catch((error)=>{
        console.log("GetPermissions Error: " + error.toString())
      });
}