import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import Emissions from './Emissions';
import * as Theme from "../../../../statics/theme"


function Sustainability({selectedShed,shedData}) {
  if(!selectedShed || !shedData)
  {
    return <div/>
  }

  var totalData = null
  if(shedData['sustainability'] && shedData['sustainability']['total'])
  {
    totalData = shedData['sustainability']['total']
  }
  var cornData = null
  if(shedData['sustainability'] && shedData['sustainability']['corn'])
  {
    cornData = shedData['sustainability']['corn']
  }
  var soybeansData = null
  if(shedData['sustainability'] && shedData['sustainability']['soybeans'])
  {
    soybeansData = shedData['sustainability']['soybeans']
  }
  return (
    <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <label style={{fontSize:32,fontWeight:"700",marginTop:30,color:Theme.BLACK_4}}>Sustainability</label>
        <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:25}}/>
        <div className="custom-scrollbar" style={{marginTop:34,width:'100%',height:'100%',marginTop:34,marginBottom:34,overflowY:'scroll'}}>
          <label style={{fontWeight:700,fontSize:24,color:Theme.DARK_GREEN_2}}>Historical carbon outcome</label>
          <div style={{flex:1,width:'100%',display:'flex',marginBottom:20}}>
            <div style={{width:'100%',paddingTop:5,height:400,marginTop:33,position:'relative'}}>
              <Emissions allowClose={false} label={"all crops"} emissionsData={totalData} hint={"(2017-2021) by acre"}/>
              <Emissions allowClose={true} label={"corn"} emissionsData={cornData} />
              <Emissions allowClose={true} label={"soybeans"} emissionsData={soybeansData}/>
              <div style={{width:10,height:5,marginTop:-38}}/>
            </div>
          </div>
        </div>
    </div>
  );
}
export default Sustainability;
