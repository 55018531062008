import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import FieldsApi from "../../api/fieldsService"
//import OpenButton from "./OpenButton";
import FieldHeader from "./FieldHeader"
import DownloadReport from "./DownloadReport"
import ExpandButton from "./ExpandButton"
import ExpandedPages from "./ExpandedPages"
import { map } from "leaflet";
import LegendConfig from '../Map/Overlays/FieldOverlay/legendConfig'
const sidebarWidth = 318;

const expandedPagesConfig = [
  {
    "label":"Basic Information",
    "value":"basicInfo",
    "locked":false,
    "selectedColor":"#3EB96C"
  },
  {
    "label":"Sustainability",
    "value":"sustainability",
    "locked":false,
    "selectedColor":"#FB7F2D"
  },
  {
    "label":"Management",
    "value":"management",
    "locked":false,
    "selectedColor":"#2697FF"
  },
  {
    "label":"Productivity",
    "value":"productivity",
    "locked":false,
    "selectedColor":"#A745F4"
  },
  {
    "label":"Scenarios",
    "value":"scenarios",
    "locked":true,
    "selectedColor":"#F249E1"
  }
]
const getColor = (fieldOverlayType,properties) => {
  try {
    var value = properties[fieldOverlayType]
    if(!value)
    {
      color = "clear"
    }
    var color = LegendConfig[fieldOverlayType].colorFunc(value)

    if(fieldOverlayType == "overlay_yield_corn" && properties["overlay_croptype"] != 1)
    {
      color = "clear"
    }else if(fieldOverlayType == "overlay_yield_soybean" && properties["overlay_croptype"] != 5)
    {
      color = "clear"
    }
    return color
  }catch(error){}
}
function FieldsSidebar() {
  const [selectedFieldData,setSelectedFieldData] = useState(null);
  const [fieldLoading,setFieldLoading] = useState(null);
  const [expandedPage,setExpandedPage] = useState(null);

  const sidebarOpen = useSelector((state) => state.main.sidebarOpen);
  const selectedField = useSelector((state) => state.fields.selectedField);
 
  useEffect(()=>selectedFieldChange(selectedField),[selectedField])
  const selectedFieldChange = (field) => {
    setSelectedFieldData(null)
    if (field != null && field.properties) {
      setFieldLoading(true)
      var selectedFieldId = field.properties.field_id
      FieldsApi.getField(selectedFieldId).then((response) => {
        if(response.status != 200 || !response.data)
        {
          console.log("GetFieldData Error: " + response.status)
          setSelectedFieldData(null)
          setFieldLoading(false)
          return;
        }

        if(response.data.geometry)
        {
          try {
            response.data.geometry = JSON.parse(response.data.geometry)
          }catch
          {

          }
        }

        if(response.data && response.data['overlays'] && response.data.geometry && response.data.geometry.features && response.data.geometry.features.length > 0 && response.data.geometry.features[0].properties)
        {
          var feature = response.data.geometry.features[0]
          feature.properties["overlay_croptype_color"] = getColor("overlay_croptype",response.data['overlays'])
          feature.properties["overlay_ndvi_color"] = getColor("overlay_ndvi",response.data['overlays'])
          feature.properties["overlay_yield_corn_color"] = getColor("overlay_yield_corn",response.data['overlays'])
          feature.properties["overlay_yield_soybean_color"] = getColor("overlay_yield_soybean",response.data['overlays'])
          feature.properties["overlay_soc_color"] = getColor("overlay_soc",response.data['overlays'])
          feature.properties["overlay_ghg_color"] = getColor("overlay_ghg",response.data['overlays'])
          feature.properties["overlay_ch4_color"] = getColor("overlay_ch4",response.data['overlays'])
          feature.properties["overlay_n2o_color"] = getColor("overlay_n2o",response.data['overlays'])
          feature.properties["overlay_soc_avg_color"] = getColor("overlay_soc_avg",response.data['overlays'])
          feature.properties["overlay_ghg_avg_color"] = getColor("overlay_ghg_avg",response.data['overlays'])
          feature.properties["overlay_ch4_avg_color"] = getColor("overlay_ch4_avg",response.data['overlays'])
          feature.properties["overlay_n2o_avg_color"] = getColor("overlay_n2o_avg",response.data['overlays'])
          feature.properties["satellite_color"] = getColor("satellite",response.data['overlays'])
        }

        setSelectedFieldData(response.data)
        setFieldLoading(false)
      })
      .catch((error)=>{
        console.log("GetFieldData Error: " + error.toString())
        setSelectedFieldData(null)
        setFieldLoading(false)
      });
    }
  }

  if(!sidebarOpen)
  {
    return <div/>
  }

  const pageClicked = (value) => {
    if(selectedField)
    {
      setExpandedPage(value)
    }
  }

  return (
    <div style={{zIndex:1000000, width:sidebarOpen ? sidebarWidth : 0,height:'100%', display: "inline-block", backgroundColor:'white',left:0,top:0, transition: "width 0.5s",
        display:"flex",flexDirection:"column",alignItems:"center",paddingTop:30}}>
        {/*<OpenButton sidebarOpen={sidebarOpen}/>*/}
        <FieldHeader selectedField={selectedField} fieldData={selectedFieldData}/>
        <DownloadReport selectedField={selectedField}/>
        {expandedPagesConfig.map(page =>
          <ExpandButton label={page.label} isLocked={page.locked} isEnabled={selectedField?true:false} isExpanded={expandedPage==page.value} buttonPressed={()=>pageClicked(page.value)} selectedColor={page.selectedColor}/>
        )}
        <ExpandedPages selectedField={selectedField} fieldData={selectedFieldData} expandedPage={expandedPage} closePage={()=>pageClicked(null)} />
    </div>
  );
}
export default FieldsSidebar;
