import axios from "axios";
import * as api_statics from "../../statics/api";


//These methods are the actual methods that call into the Fields API.
//They are called by the wrapper functions in the FieldsApi
class ShedsService {
  static shedCancelToken = null;
  static processShedCancelToken() {
    //This cancel token allows us to cancel any in progress requests using it.
    //This way, a user can't be searching for fields at two different
    //locations at once; only the last request will be valid and return to us
    if (ShedsService.shedCancelToken != null && typeof ShedsService.shedCancelToken != typeof undefined) {
      ShedsService.shedCancelToken.cancel("Shed search canceled due to new request.");
    }
    ShedsService.shedCancelToken = axios.CancelToken.source();
  }
  static getShed(accessToken, { shed_id }) {
    var field_endpoint = api_statics.FIELDS_API + "/shed";

    var params = {
      shed_id,
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    ShedsService.processShedCancelToken();

    return axios.get(field_endpoint, {
      params,
      headers,
      cancelToken: ShedsService.shedCancelToken.token,
    });
  }
  static getFavorites(accessToken, {}) {
    var favorites_endpoint = api_statics.FIELDS_API + "/getFavoriteSheds";

    var headers = {
      Authorization: "Bearer " + accessToken,
    };


    return axios.get(favorites_endpoint, {
      headers,
    });
  }

  // testing fetch images from S3 1-15-23
  static getShedImage(accessToken, { image }) {
    var management_endpoint = api_statics.FIELDS_API + "/getShedImage";

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    return axios.get(management_endpoint, {
      headers,
      params: {
        image: image
      }
    }).then(response => response.data);
  }
}

export default ShedsService;
