export const TOPBAR_NAVY = "#161b26"
export const BLACK_3 = "#292D36"
export const BLACK_4 = "#3D414B"
export const DARK_GRAY_1 = "#8C909B"
export const DARK_GRAY_3 = "#6D707B"
export const DARK_GRAY_4 = "#585A61"
export const DARK_GREEN_1 = "#33674E"
export const DARK_GREEN_2 = "#18442F"
export const DARK_GREEN_3="#467146"
export const DARK_GREEN_4="#274627"
export const LIGHT_GRAY_1 = "#DCE0E7"
export const LIGHT_GRAY_2 = "#CCD0DA"
export const LIGHT_GRAY_3 = "#B4B8C4"
export const LIGHT_GRAY_4 = "#999DA9"
export const WHITE_3 = "#F1F3F8"
export const WHITE_4 = "#E8EBF1"
export const YELLOW = "#F8BB37"
export const GRASS_GREEN = "#3EB96C"
export const POMERANGE_ORANGE = "#FB7F2D"
export const LIGHT_ORANGE='#FFF1E8'
export const LIGHT_GREEN_1="#47ac68"
export const LIGHT_GREEN_2="#229c4a"
export const RED_1="#e44e4e"
export const LIGHT_BLUE_1 = "#2697FF"
