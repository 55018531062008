import axios from "axios";
import * as api_statics from "../../statics/api";


//These methods are the actual methods that call into the Fields API.
//They are called by the wrapper functions in the FieldsApi
class FieldsService {
  static fieldCancelToken = null;
  static fieldSearchCancelToken = null;
  static processFieldSearchCancelToken() {
    //This cancel token allows us to cancel any in progress requests using it.
    //This way, a user can't be searching for fields at two different
    //locations at once; only the last request will be valid and return to us
    if (FieldsService.fieldSearchCancelToken != null && typeof FieldsService.fieldSearchCancelToken != typeof undefined) {
      FieldsService.fieldSearchCancelToken.cancel("Field search canceled due to new request.");
    }
    FieldsService.fieldSearchCancelToken = axios.CancelToken.source();
  }
  static processFieldCancelToken() {
    //This cancel token allows us to cancel any in progress requests using it.
    //This way, a user can't be searching for fields at two different
    //locations at once; only the last request will be valid and return to us
    if (FieldsService.fieldCancelToken != null && typeof FieldsService.fieldCancelToken != typeof undefined) {
      FieldsService.fieldCancelToken.cancel("Field search canceled due to new request.");
    }
    FieldsService.fieldCancelToken = axios.CancelToken.source();
  }
  static getFields(accessToken, { tile_x,tile_y,zoom }) {
    var fields_endpoint = api_statics.FIELDS_API + "/fields";
    //var fields_endpoint = "https://yield-platform-s3-overlays.s3.us-west-1.amazonaws.com/geojson-fields/geojson_fields_x_"+tile_x+"_y_"+tile_y+"_z_12.pbf"
    var params = {
      tile_x,tile_y,zoom
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    FieldsService.processFieldSearchCancelToken();

    return axios.get(fields_endpoint, {
      params,
      responseType: 'arraybuffer',
      //cancelToken: FieldsService.fieldSearchCancelToken.token,
      headers,
    });
  }
  static getField(accessToken, { field_id }) {
    var field_endpoint = api_statics.FIELDS_API + "/fieldNew";

    var params = {
      field_id,
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    FieldsService.processFieldCancelToken();

    return axios.get(field_endpoint, {
      params,
      headers,
      cancelToken: FieldsService.fieldCancelToken.token,
    });
  }
  static getFavorites(accessToken, {}) {
    var favorites_endpoint = api_statics.FIELDS_API + "/getFavoriteFields";

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    FieldsService.processFieldSearchCancelToken();

    return axios.get(favorites_endpoint, {
      headers,
    });
  }
  static getFieldsMap(accessToken, {}) {
    var fieldsMap_endpoint = api_statics.FIELDS_API + "/fieldsMap";

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    FieldsService.processFieldSearchCancelToken();

    return axios.get(fieldsMap_endpoint, {
      headers,
    });
  }

  static getCounty(accessToken, { fips }) {
    var searchCountyStateEndpoint = api_statics.FIELDS_API + "/county";

    var params = {
      fips,
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    return axios.get(searchCountyStateEndpoint, {
      params,
      headers,
    });
  }
  static getCountiesNASS(accessToken, {  }) {
    var searchCountyStateEndpoint = api_statics.FIELDS_API + "/countiesNASS";

    var params = {
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    return axios.get(searchCountyStateEndpoint, {
      params,
      responseType: 'arraybuffer',
      headers,
    });
  }
  static searchCountyState(accessToken, { term }) {
    var searchCountyStateEndpoint = api_statics.FIELDS_API + "/searchCountyState";

    var params = {
      term,
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    return axios.get(searchCountyStateEndpoint, {
      params,
      headers,
    });
  }
  static requestOverlay(accessToken, { field_id }) {
    var requestOverly_endpoint = api_statics.FIELDS_API + "/requestOverlay";

    var params = {
      field_id,
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    return axios.get(requestOverly_endpoint, {
      params,
      headers,
    });
  }
  static addFavorite(accessToken, { field_id }) {
    var addFavorites_endpoint = api_statics.FIELDS_API + "/addFavoriteField";

    var params = {
      field_id,
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    return axios.get(addFavorites_endpoint, {
      params,
      headers,
    });
  }
  static removeFavorite(accessToken, { field_id }) {
    var addFavorites_endpoint = api_statics.FIELDS_API + "/removeFavoriteField";

    var params = {
      field_id,
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    return axios.get(addFavorites_endpoint, {
      params,
      headers,
    });
  }

  static createFavorite(accessToken, { field_json }) {
    var addFavorites_endpoint = api_statics.FIELDS_API + "/createFavoriteField";

    var params = {
      field_json,
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    return axios.get(addFavorites_endpoint, {
      params,
      headers,
    });
  }
  static getTableData(accessToken, { filters, sorts, page, pageSize }) {
    var searchCountyStateEndpoint = api_statics.FIELDS_API + "/fieldTable";

    var params = {
      
    };
    var body = {
      "filters":filters,
      "sorts":sorts,
      "page":page,
      "pageSize":pageSize
    }

    var headers = {
      Authorization: "Bearer " + accessToken,
    };

    return axios.post(searchCountyStateEndpoint, body, {
      params,
      headers
    });
  }
  static downloadReport(accessToken, { field_id }) {
    var searchCountyStateEndpoint = api_statics.FIELDS_API + "/fieldReport";

    var params = {
      field_id
    };

    var headers = {
      Authorization: "Bearer " + accessToken,
      //responseType: 'blob'
    };

    return axios.get(searchCountyStateEndpoint, {
      params,
      headers
    });
  }
}

export default FieldsService;
