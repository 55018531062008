import React from "react";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function ZoomControl({map}) {
  const sidebarOpen = useSelector((state) => state.main.favoritesSidebarOpen);
  if(!map)
  {
    return <div/>
  }
  return (
    <div style={{zIndex:10000,position:"absolute",right:20+(sidebarOpen?318:53),bottom:20,backgroundColor:"white",height:78,width:34,borderRadius:27, transition: "right 0.5s"}}>
      <div style={{position:"absolute",top:5,left:0,cursor:"pointer",position:"flex",width:35,height:35,alignItems:"center",justifyContent:"center"}}
        onClick={()=>{map.flyTo({
          center: map.getCenter(),
          zoom:map.getZoom()+1,
          essential: true // this animation is considered essential with respect to prefers-reduced-motion
          });}}>
        <label style={{cursor:"pointer",fontWeight:'200',color:"#1A1A1A",fontSize:25}}>+</label>
      </div>
      <div style={{position:"absolute",top:39,left:7,width:20,height:0,border:"0px solid #CCD0DA",borderBottomWidth:1}}/>
      <div style={{position:"absolute",bottom:0,left:0,cursor:"pointer",position:"flex",width:35,alignItems:"center",justifyContent:"center"}}
        onClick={()=>{map.flyTo({
          center: map.getCenter(),
          zoom:map.getZoom()-1,
          essential: true // this animation is considered essential with respect to prefers-reduced-motion
          });}}>
        <label style={{cursor:"pointer",fontWeight:'200',color:"#1A1A1A",fontSize:35}}>-</label>
      </div>
    </div>
  );
}
export default ZoomControl;
