import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotateLabels from 'chartjs-plugin-annotation'
import * as Theme from "../../../../statics/theme"

import { Bar } from 'react-chartjs-2';
import { VideoOverlay } from "leaflet";
const colors = ["#2697FF","#FB7F2D","#3EB96C","#33674E"]
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartAnnotateLabels
);
//ChartJS.plugins.register(ChartAnnotateLabels);
const options = {
  responsive: true,
  maintainAspectRatio:false,
  plugins: {
    annotation: {
      annotations: [
        {
          type: 'line',
          scaleID: 'x',
          borderWidth: 1,
          borderColor: '#B4B8C4',
          value: 3.5,
        }
      ]
    },
    "tooltip": {
      "enabled": false
    },
    legend: {
      position: 'top',
      display:false
    },
    title: {
      display: false,
    },
    datalabels: {
      display: true,
      color: "black",
      formatter: function(value,{dataIndex}) {
        if(value)
        {
          return value.toFixed(2)
        }
      },
      color: function(context) {
        var index = context.dataIndex;
        return colors[index]
      },
      font: function(context) {
        var index = context.dataIndex;
        if(index == 3)
        {
          return {
            size:12,
            weight:'700'
          }
        }
        else {
          return {
            size:12,
            weight:'500'
          }
        }
      },
      anchor: "end",
      offset: -23,
      align: "start"
    }
  },
  scales: {
    y: {
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
              if(Number.isInteger(value) && value >= 0)
              {
                return value.toFixed(0);
              }
            },
            color:'#B4B8C4',
            beginAtZero:true
        },
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:1,
          lineWidth:1
        }
    },
    x: {
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:0,
          lineWidth:0
        }
    },
  }
};
const labels = ['SOC','N2O','CH4','Total'];

const Graph = ({scenario,baseline,current}) =>{
  

  return (
    <div style={{flex:1}}>
    </div>
  )
}
const OptionButton = ({label, value, currentValue, setOption, activeBackground, background, activeColor, color,marginLeft, marginRight}) =>
{
  var isActive = value == currentValue
  return (
    <div style={{cursor:"pointer",marginLeft:marginLeft, marginRight:marginRight,backgroundColor:isActive?activeBackground:background,borderRadius:48,height:25,display:'flex',alignItems:'center',justifyContent:'center',paddingLeft:16,paddingRight:16,
      boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)'}}
      onClick={(()=>setOption(value))}>
      <label style={{cursor:"pointer",fontSize:14,fontWeight:isActive?'600':'400',color:isActive?activeColor:color}}>{label}</label>
    </div>
  )
}
function Scenario({label,data}) {
  if(!data) 
  {
    return <div/>
  }

  var perAcre = data["per_acre"]
  var total = data["total"]
  var savings = data["savings"]

  
  return (
    <div style={{borderRadius:14,overflow:'scroll',marginTop:35,position:'relative',display:"flex",flexDirection:"column",alignItems:"flex-start",boxShadow:"0px 2px 15px rgba(48, 68, 120, 0.16)"}}>
        <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center'}}>
          <label style={{fontWeight:"700",fontSize:20,marginTop:30,marginLeft:30,color:Theme.BLACK_4}}>{label}</label>
        
        </div>
          <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
            <div style={{marginLeft:34,flex:1,marginRight:34}}>
              <div style={{width:'100%',height:0,border:'0px solid #E8EBF1',borderColor:Theme.WHITE_4,borderBottomWidth:1,marginTop:20}}/>
              
              <div style={{width:'100%',display:'flex',flexDirection:'row',marginTop:35}}>
                <Graph />
                <div style={{display:'flex',flexDirection:'column',marginLeft:25,alignItems:'flex-start'}}>
                  <label style={{fontSize:14,fontWeight:'700',color:Theme.BLACK_4,maxWidth:179,textAlign:'left'}}>Annual GHG emission reduction</label>
                  <div style={{marginTop:16,width:179,height:65,borderRadius:10,border:'1px solid #DCE0E7',borderColor:Theme.LIGHT_GRAY_1,backgroundColor:Theme.WHITE_3,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                    <label style={{marginLeft:16,marginBottom:2,fontSize:24,fontWeight:'700',color:Theme.GRASS_GREEN}}>{Math.floor(perAcre*100)/100}</label>
                    <label style={{marginLeft:16,fontSize:12,fontWeight:'500',color:Theme.DARK_GRAY_4}}>tons CO2e/ acre</label>
                  </div>
                  <div style={{marginTop:16,width:179,height:65,borderRadius:10,border:'1px solid #DCE0E7',borderColor:Theme.LIGHT_GRAY_1,backgroundColor:Theme.WHITE_3,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                    <label style={{marginLeft:16,marginBottom:2,fontSize:24,fontWeight:'700',color:Theme.GRASS_GREEN}}>{Math.floor(total*100)/100}</label>
                    <label style={{marginLeft:16,fontSize:12,fontWeight:'500',color:Theme.DARK_GRAY_4}}>tons across whole plot</label>
                  </div>
                  <div style={{marginTop:16,width:179,height:83,borderRadius:10,border:'1px solid #DCE0E7',borderColor:Theme.LIGHT_GRAY_1,backgroundColor:Theme.WHITE_3,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                    <label style={{marginLeft:16,fontSize:12,marginBottom:2,fontWeight:'500',color:Theme.DARK_GRAY_4,textAlign:'left'}}>Estimated monetary opportunity per year</label>
                    <label style={{marginLeft:16,fontSize:24,fontWeight:'700',color:Theme.GRASS_GREEN}}>{"$" + Math.floor(savings*100)/100}</label>
                  </div>
                </div>
              </div>

              <div style={{width:'100%',height:25}}/>
            </div>
          </div>

        
        
       
    </div>
  );
}
export default Scenario;
