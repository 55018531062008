import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import ChangeBars from './ChangeBars';
import * as Theme from "../../../../statics/theme"
import Scenario from "./Scenario"
function Scenarios({selectedShed,shedData}) {
  if(!selectedShed || !shedData || !shedData)
  {
    return <div/>
  }

  var coverCropData = {
    "per_acre":-.03,
    "total":3,
    'savings':100,
    "soc":.42,
    "n2o":2.4,
    "ch4":-.2,
    "ghg":2.25
  }
  var tillData = {
    "per_acre":-.03,
    "total":3,
    'savings':100,
    "soc":.42,
    "n2o":2.4,
    "ch4":-.2,
    "ghg":2.25
  }
  var nitrogenData = {
    "per_acre":-.03,
    "total":3,
    'savings':100,
    "soc":.42,
    "n2o":2.4,
    "ch4":-.2,
    "ghg":2.25
  }
  return (
    <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <label style={{fontSize:32,fontWeight:"700",marginTop:30,color:Theme.BLACK_4}}>Scenarios</label>
        <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:25}}/>
        <div style={{width:'100%',flex:1,overflow:'scroll'}}>
          <div style={{marginLeft:45,marginRight:45,height:200}} >
            <div style={{display:'flex',flexDirection:'column',height:'fit-content',marginBottom:60}}>
              
              <ChangeBars shedData={shedData} />

              <Scenario label={"Cover crop"} data={coverCropData} />
              <Scenario label={"No/Low Till"} data={tillData} />
              <Scenario label={"Nitrogen Fertilizer Use Reduction"} data={nitrogenData} />

              <div style={{width:10,height:40}}/>
            </div>
          </div>

        </div>
    </div>
  );
}
export default Scenarios;
