import React, {useState,useEffect} from "react";
import { useSelector } from "react-redux";
import { setZoomTo } from "../../redux/actions/fields";
import { setSelectedShed} from "../../redux/actions/sheds";

const shedPressed = (shed) => {
  if(!shed)
  {
    return
  }

  setSelectedShed(shed);
  // setZoomTo([-88.471188, 39.171054, -88.007766, 39.378968]);
  setZoomTo(shed.bounds)
}
function ShedsList() {
  const shedsList = useSelector((state) => state.sheds.favoriteSheds);
  const selectedShed = useSelector((state) => state.sheds.selectedShed);

  var selectedShedId = null;
  if(selectedShed && selectedShed.shed_id)
  {
    selectedShedId = selectedShed.shed_id
  }

  if(!shedsList)
  {
    return
  }
  // need to somehow store shedName instead of hardcode in the future
  return (
    <div style={{marginTop:22,width:274,height:391,backgroundColor:'#F1F3F8',borderRadius:14,display:'flex'}}>
      <div className="custom-scrollbar" style={{flex:1,overflowY:'auto',marginLeft:38,marginBottom:25,marginRight:25,marginTop:25,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
        {shedsList.map(shed=>
          <div style={{cursor:'pointer',padding:5}} onClick={()=>shedPressed(shed)}>
              <label style={{cursor:'pointer',fontWeight:'400',fontSize:16,color:selectedShedId==shed.shed_id ? '#3EB96C' : '#3D414B'}}>
                {'Cumberland, Illinois'}
              </label>
          </div>
          )}
      </div>
    </div>
  );
}
export default ShedsList;
