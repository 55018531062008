import store from "../index"
import {mainSlice} from "../reducers/mainReducer"

export const setPage = (page) => {
  store.dispatch(mainSlice.actions.reducerSetPage(page));
};
export const setPermissions = (permissions) => {
  store.dispatch(mainSlice.actions.reducerSetPermissions(permissions));
};
export const setSidebarOpen = (open) => {
  store.dispatch(mainSlice.actions.reducerSetSidebarOpen(open));
};
export const setView = (view) => {
  store.dispatch(mainSlice.actions.reducerSetView(view));
};
export const setFavoritesSidebarOpen = (open) => {
  store.dispatch(mainSlice.actions.reducerSetFavoritesSidebarOpen(open));
};
export const setCurrentView = (view) => {
  store.dispatch(mainSlice.actions.reducerSetCurrentView(view));
};