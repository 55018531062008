import React from "react";
import { useSelector } from "react-redux";
import { setCurrentView} from "../../redux/actions/main";
import * as Theme from "../../statics/theme"
import SpaIcon from '@mui/icons-material/Spa';
import AppsIcon from '@mui/icons-material/Apps';

function ViewSelectTopbar() {
  const currentView = useSelector((state) => state.main.currentView);

  var isFields = currentView == "fields"
  return (
    <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:30}}>
      <div style={{marginLeft:22,cursor:'pointer',backgroundColor:isFields?Theme.GRASS_GREEN:'transparent',flex:1,height:33,borderRadius:7,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',border:"2px solid #8C909B",borderColor:isFields?Theme.GRASS_GREEN:Theme.DARK_GRAY_1}}
        onClick={()=>{setCurrentView("fields")}}>
          <SpaIcon style={{color:isFields?'white':Theme.DARK_GRAY_1,fontSize:15}} />
          <label style={{color:isFields?'white':Theme.DARK_GRAY_1,fontSize:14,fontWeight:'600',cursor:'pointer',marginLeft:10}}>Fields</label>
      </div>

      <div style={{marginRight:22,cursor:'pointer',marginLeft:5,backgroundColor:!isFields?Theme.POMERANGE_ORANGE:'transparent',flex:1,height:33,borderRadius:7,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',border:'2px solid #8C909B',borderColor:!isFields?Theme.POMERANGE_ORANGE:Theme.DARK_GRAY_1}}
        onClick={()=>{setCurrentView("sheds")}}>
          <AppsIcon style={{color:!isFields?'white':Theme.DARK_GRAY_1,fontSize:15}} />
          <label style={{color:!isFields?'white':Theme.DARK_GRAY_1,fontSize:14,fontWeight:'600',cursor:'pointer',marginLeft:10}}>Sheds</label>
      </div>
    </div>
  );
}
export default ViewSelectTopbar;