import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotateLabels from 'chartjs-plugin-annotation'
import * as Theme from "../../../../statics/theme"

import { Bar } from 'react-chartjs-2';
import { VideoOverlay } from "leaflet";
const colors = ["#2697FF","#FB7F2D","#3EB96C","#33674E"]
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartAnnotateLabels
);
//ChartJS.plugins.register(ChartAnnotateLabels);
const options = {
  responsive: true,
  maintainAspectRatio:false,
  plugins: {
    annotation: {
      annotations: [
        {
          type: 'line',
          scaleID: 'x',
          borderWidth: 1,
          borderColor: '#B4B8C4',
          value: 3.5,
        }
      ]
    },
    "tooltip": {
      "enabled": false
    },
    legend: {
      position: 'top',
      display:false
    },
    title: {
      display: false,
    },
    datalabels: {
      display: true,
      color: "black",
      formatter: function(value,{dataIndex}) {
        if(value)
        {
          return value.toFixed(2)
        }
      },
      color: function(context) {
        var index = context.dataIndex;
        return colors[index]
      },
      font: function(context) {
        var index = context.dataIndex;
        if(index == 3)
        {
          return {
            size:12,
            weight:'700'
          }
        }
        else {
          return {
            size:12,
            weight:'500'
          }
        }
      },
      anchor: "end",
      offset: -23,
      align: "start"
    }
  },
  scales: {
    y: {
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
              if(Number.isInteger(value) && value >= 0)
              {
                return value.toFixed(0);
              }
            },
            color:'#B4B8C4',
            beginAtZero:true
        },
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:1,
          lineWidth:1
        }
    },
    x: {
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:0,
          lineWidth:0
        }
    },
  }
};
const labels = ['SOC','N2O','CH4','Total'];

const Graph = ({scenario,baseline,current}) =>{
  var data = {
    labels,
    datasets: [
      {
        data: [],
        borderRadius:6,
        backgroundColor: colors,
        barThickness:50
      },
    ],
  };
  if(!baseline && current == 'baseline')
  {
    return
  } 
  if(!scenario && current == 'scenario')
  {
    return
  }
  if((!scenario || !baseline) && current == 'change')
  {
    return
  }
  if(current == 'baseline')
  {
    data.datasets[0].data = [baseline.soc,baseline.n2o,baseline.ch4,baseline.ghg]
  }else if (current == 'change'){
    data.datasets[0].data = [scenario.soc - baseline.soc,scenario.n2o - baseline.n2o,scenario.ch4-baseline.ch4,scenario.ghg - baseline.ghg]
  } else if(current == 'scenario'){
    data.datasets[0].data = [parseFloat(scenario.soc),parseFloat(scenario.n2o),parseFloat(scenario.ch4),parseFloat(scenario.ghg)]
  }

  return (
    <div style={{width:375,height:215,marginBottom:25}}>
      <label style={{color:Theme.LIGHT_GRAY_4,fontSize:12,fontWeight:'500',float:'left',marginLeft:30}}>Tons CO<sub>2</sub>eq / ac / yr</label>
      <Bar options={options} plugins={[ChartDataLabels]}  data={data}/>
    </div>
  )
}
const OptionButton = ({label, value, currentValue, setOption, activeBackground, background, activeColor, color,marginLeft, marginRight}) =>
{
  var isActive = value == currentValue
  return (
    <div style={{cursor:"pointer",marginLeft:marginLeft, marginRight:marginRight,backgroundColor:isActive?activeBackground:background,borderRadius:48,height:25,display:'flex',alignItems:'center',justifyContent:'center',paddingLeft:16,paddingRight:16,
      boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)'}}
      onClick={(()=>setOption(value))}>
      <label style={{cursor:"pointer",fontSize:14,fontWeight:isActive?'600':'400',color:isActive?activeColor:color}}>{label}</label>
    </div>
  )
}
function Scenario({baseline,scenario}) {
  const [open, setOpen] = useState(true); // set the auto state of the expanded scenarios. T = open, F = closed
  const [option, setOption] = useState("change");
  if(!baseline || !scenario) 
  {
    return <div/>
  }

  var showMoney = option == "change";
  var perAcre = 0
  var total = 0
  var money = scenario["savings"]
  if(money)
  {
    money = parseInt(money)
  }
  switch(option)
  {
    case "change": 
      perAcre = scenario["average_co2"] - baseline["average_co2"]
      total = scenario["total_co2"] - baseline["total_co2"]
      break;
    case "baseline": 
      perAcre = baseline["average_co2"]
      total = baseline["total_co2"]
      break;
    case "scenario": 
      perAcre = scenario["average_co2"]
      total = scenario["total_co2"]
      break;
  }
  
  return (
    <div  className="custom-scrollbar" style={{borderRadius:14,overflowY:'scroll',marginTop:26,position:'relative',height:open?'':80,display:"flex",flexDirection:"column",alignItems:"flex-start",boxShadow:"0px 2px 15px rgba(48, 68, 120, 0.16)"}}>
        <div style={{width:'100%',height:80,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
          <label style={{fontWeight:"700",fontSize:20,marginLeft:25,color:Theme.BLACK_4}}>{scenario.label + ": " + scenario.name}</label>
          <div style={{flex:1}}/>
            <div style={{marginRight:36,padding:5,cursor:'pointer'}} onClick={()=>setOpen(!open)}>
              {
                open
                ?
                <KeyboardArrowUpIcon style={{fontSize:20, color:Theme.DARK_GRAY_4}}/>
                :
                <KeyboardArrowDownIcon style={{fontSize:20, color:Theme.DARK_GRAY_4}}/>
              }
            </div>


        </div>
        {open &&
          <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
            <div style={{marginLeft:34,flex:1,marginRight:34}}>
              <div style={{width:'100%',height:0,border:'0px solid #E8EBF1',borderColor:Theme.WHITE_4,borderBottomWidth:1,marginTop:10}}/>
              

              <div style={{width:336,height:45,borderRadius:35,backgroundColor:'#F1F3F8',marginTop:35,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <OptionButton label={"Change"} marginLeft={16} marginRight={10} color={Theme.GRASS_GREEN} activeColor={"white"} background={'white'} activeBackground={Theme.GRASS_GREEN} currentValue={option} value={"change"} setOption={setOption} />
                <OptionButton label={"Baseline"} marginLeft={10} marginRight={10} color={Theme.BLACK_4} activeColor={"white"} background={'white'} activeBackground={Theme.DARK_GREEN_2} currentValue={option} value={"baseline"} setOption={setOption} />
                <OptionButton label={"Scenario"} marginLeft={10} marginRight={16} color={Theme.BLACK_4} activeColor={"white"} background={'white'} activeBackground={Theme.DARK_GREEN_2} currentValue={option} value={"scenario"} setOption={setOption} />
              </div>
              <div style={{width:'100%',display:'flex',flexDirection:'row',marginTop:35}}>
                <Graph scenario={scenario} baseline={baseline} current={option}/>
                <div style={{display:'flex',flexDirection:'column',marginLeft:25,alignItems:'flex-start'}}>
                  <label style={{fontSize:14,fontWeight:'700',color:Theme.BLACK_4}}>Annual GHG emission</label>
                  <div style={{marginTop:16,width:179,height:65,borderRadius:10,border:'1px solid #DCE0E7',borderColor:Theme.LIGHT_GRAY_1,backgroundColor:Theme.WHITE_3,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                    <label style={{marginLeft:16,marginBottom:2,fontSize:24,fontWeight:'700',color:option=='change'?Theme.GRASS_GREEN:Theme.DARK_GREEN_1}}>{Math.floor(perAcre*100)/100}</label>
                    <label style={{marginLeft:16,fontSize:12,fontWeight:'500',color:Theme.DARK_GRAY_4}}>Tons CO<sub>2</sub> / acre</label>
                  </div>
                  <div style={{marginTop:16,width:179,height:65,borderRadius:10,border:'1px solid #DCE0E7',borderColor:Theme.LIGHT_GRAY_1,backgroundColor:Theme.WHITE_3,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                    <label style={{marginLeft:16,marginBottom:2,fontSize:24,fontWeight:'700',color:option=='change'?Theme.GRASS_GREEN:Theme.DARK_GREEN_1}}>{Math.floor(total*100)/100}</label>
                    <label style={{marginLeft:16,fontSize:12,fontWeight:'500',color:Theme.DARK_GRAY_4}}>Tons across whole plot</label>
                  </div>
                  {showMoney && <div style={{marginBottom:25,marginTop:16,width:179,height:83,borderRadius:10,border:'1px solid #DCE0E7',borderColor:Theme.LIGHT_GRAY_1,backgroundColor:Theme.WHITE_3,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                    <label style={{marginLeft:16,fontSize:12,marginBottom:2,fontWeight:'500',color:Theme.DARK_GRAY_4,textAlign:'left'}}>Estimated monetary opportunity per year</label>
                    <label style={{marginLeft:16,fontSize:24,fontWeight:'700',color:option=='change'?Theme.GRASS_GREEN:Theme.DARK_GREEN_1}}>{"$" + Math.floor(money*100)/100}</label>
                  </div>}
                </div>
              </div>

            </div>
          </div>

        }

        
        
       
    </div>
  );
}
export default Scenario;
