import React from "react";
import Chart from "react-google-charts";
import LegendConfig from "../Map/Overlays/FieldOverlay/legendConfig"
import FavoriteButton from "./FavoriteButton"
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import RoomIcon from '@mui/icons-material/Room';
import CropFreeIcon from '@mui/icons-material/CropFree';
import SpaIcon from '@mui/icons-material/Spa';
import * as Theme from "../../statics/theme"

function FieldHeader({ selectedField, fieldData }) {
  var selectedFieldId = null
  if(selectedField != null && selectedField.properties != null)
  {
    selectedFieldId = selectedField.properties.field_id
  }
  var fieldBasicInfo = null
  if(fieldData != null)
  {
    fieldBasicInfo = fieldData.basic_info
  }
  var acreage = 0
  if(fieldBasicInfo && fieldBasicInfo["acreage"] != null)
  {
    acreage = parseInt(parseFloat(fieldBasicInfo["acreage"])*100)/100
  }
  var crop = 'NA'
  if(fieldData && fieldData["history"] && fieldData["history"]['2020'])
  {
    crop = LegendConfig.overlay_croptype.labelFunc(fieldData["history"]['2020']['crop_type']).toUpperCase()
  }

  var svg = ''
  if(fieldData && fieldData.field_svg)
  {
    svg = fieldData.field_svg
    svg = svg.replaceAll('opacity="0.6"','opacity="1.0"').replaceAll('fill="#66cc99"','fill="#0F330F"').replaceAll('fill="#ff3333"','fill="#0F330F"').replaceAll('stroke="#555555"','stroke="#ffffff"')
  }

  var countyName = ''
  if(fieldBasicInfo && fieldBasicInfo.county_name)
  {
    countyName = fieldBasicInfo.county_name
  }
  var stateName = ''
  if(fieldBasicInfo && fieldBasicInfo.state_name)
  {
    stateName = fieldBasicInfo.state_name
  }

  

  return (
    <div style={{width:275,height:140,backgroundColor:"#F1F3F8",borderRadius:11}}>
      {(!selectedField || !fieldData)
      ?
        <div style={{width:"100%",height:"100%",display:"flex",flexDirection:'column',justifyContent:"center",alignItems:'center'}}>
          <ZoomInIcon style={{fontSize:60,marginBottom:10,color:Theme.DARK_GRAY_1}} />
            <label style={{color:Theme.DARK_GRAY_1,fontSize:13,fontWeight:'600'}}>Zoom in to see and select fields</label>
        </div>
      :
        <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"row",position:"relative"}}>
            <div style={{width:65,height:65,marginLeft:10,marginRight:10,marginTop:15,borderRadius:11,backgroundColor:"#0F330F",display:"flex",alignItems:"center",justifyContent:"center"}}>
              <img style={{width:50,height:50}} src={`data:image/svg+xml;base64,${btoa(svg)}`}/>
            </div>
            <div style={{height:"100%",flex:1,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}}>
              <label style={{fontWeight:'700',fontSize:18,color:Theme.BLACK_3}}>{'Field ' + selectedFieldId}</label>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:10}}>
                  <RoomIcon style={{color:Theme.DARK_GRAY_4,fontSize:15}} />
                  <label style={{color:Theme.DARK_GRAY_4,fontSize:12,marginLeft:5}}>{countyName + ", " + stateName}</label>
              </div>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:5}}>
                  <CropFreeIcon style={{color:Theme.DARK_GRAY_4,fontSize:15}} />
                  <label style={{color:Theme.DARK_GRAY_4,fontSize:12,marginLeft:5}}>{"Field Size: " + acreage + " acres"}</label>
              </div>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:5}}>
                  <SpaIcon style={{color:Theme.DARK_GRAY_4,fontSize:15}} />
                  <label style={{color:Theme.DARK_GRAY_4,fontSize:12,marginLeft:5}}>{"2020 Crop: " + crop}</label>
              </div>
            </div>
            <div style={{position:"absolute",top:15,right:15}}>
              <FavoriteButton selectedField={selectedField}/>
            </div>
        </div>
      }
    </div>
  )
}
export default FieldHeader;
