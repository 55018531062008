import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import TableViewIcon from '@mui/icons-material/TableView';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import LegendConfig from "../../../Map/Overlays/FieldOverlay/legendConfig";
import * as Theme from "../../../../statics/theme"
const config = {
  tillage: {
    options: {
      "0": {
        color: "#00bfff",
        label: "No tillage",
      },
      "1": {
        color: "#98fa98",
        label: "Reduced tillage",
      },
      '2': {
        color: "#ff7e4f",
        label: "Conventional tillage",
      }
    },
  },
  cover_crop: {
    options: {
      "1": {
        color: "#3EB96C",
        label: "Yes",
      },
      "0": {
        color: "red",
        label: "No",
      }
    },
  }
};
const LabelRow = ({marginTop,fontWeight,values,color}) =>{
  return (
    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
      {values.map(value=>
          <div style={{flex:1,alignItems:'center',justifyContent:'center',marginTop:marginTop}}>
            <label style={{fontSize:12,fontWeight:fontWeight,color:color}}>{value}</label>
          </div>
        )}
    </div>
  )
}
const GraphView = ({data}) => {
  return (
    <div style={{height:400,paddingBottom:50}}>
  <div style={{display:'flex',height:'fit-content',marginLeft:45,marginRight:45,flexDirection:'column',justifyContent:'center'}}>
    <label style={{fontSize:16,fontWeight:'700',color:Theme.BLACK_4}}>Crop History</label>
    <LabelRow marginTop={30} colors={Theme.DARK_GRAY_3} fontWeight={'500'} values={Object.keys(data)} />
    <div style={{width:'100%',borderRadius:6,overflow:'hidden',flexDirection:'row',display:'flex',height:40,marginTop:6}}>
      {Object.keys(data).map(year=>
        <div style={{flex:1,height:40,backgroundColor:(data[year]["crop_type"]!=null) ? LegendConfig.overlay_croptype.colorFunc(data[year]["crop_type"]) : '#DCE0E7',
            display:'flex',justifyContent:'center',alignItems:'center'}}>
            {data[year]["yield"]!= null && <label style={{fontSize:14,color:data[year]['crop_type'] == 5 ? 'white' : Theme.DARK_GREEN_2}}>{parseInt(data[year]["yield"]) + " bu/ac"}</label>}
        </div>
      )}
    </div>
    <LabelRow marginTop={6} fontWeight={'700'} color={Theme.BLACK_4} values={Object.keys(data).map(year=>{
      if(data[year]["crop_type"] == null)
      {
        return "No Crop"
      }
      return LegendConfig.overlay_croptype.labelFunc(data[year]["crop_type"])
    })
    } />
    <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:59}}/>



    <label style={{fontSize:16,fontWeight:'700',marginTop:47,color:Theme.BLACK_4}}>Tillage (Winter)</label>
    <LabelRow marginTop={30} color={Theme.DARK_GRAY_3} fontWeight={'500'} values={Object.keys(data)} />
    <div style={{width:'100%',borderRadius:6,overflow:'hidden',flexDirection:'row',display:'flex',height:22,marginTop:15}}>
      {Object.keys(data).map(year=>
        <div style={{flex:1,height:22,backgroundColor:(data[year]["spring_tillage"]!=null && config.tillage.options[data[year]["spring_tillage"]]) ? config.tillage.options[data[year]["spring_tillage"]].color : '#DCE0E7'}} />
      )}
    </div>
    <div style={{width:'100%',marginTop:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
      {Object.keys(config.tillage.options).map(tillage =>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginLeft:24,marginRight:24}}>
          <div style={{backgroundColor:config.tillage.options[tillage].color,width:16,height:16,borderRadius:8}}/>
          <label style={{fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4,marginLeft:8}}>{config.tillage.options[tillage].label}</label>
        </div>
      )}
    </div>
    <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:48}}/>


    <label style={{fontSize:16,fontWeight:'700',marginTop:47,color:Theme.BLACK_4}}>Cover Crop</label>
    <LabelRow marginTop={35} color={Theme.DARK_GRAY_3} fontWeight={'500'} values={Object.keys(data)} />
    <div style={{width:'100%',borderRadius:6,overflow:'hidden',flexDirection:'row',display:'flex',height:22,marginTop:15}}>
      {Object.keys(data).map(year=>
        <div style={{flex:1,height:22,backgroundColor:(data[year]["cover_crop"]!=null && config.cover_crop.options[data[year]["cover_crop"]]) ? config.cover_crop.options[data[year]["cover_crop"]].color : '#DCE0E7'}} />
      )}
    </div>
    <div style={{width:'100%',marginTop:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
      {Object.keys(config.cover_crop.options).map(cover_crop =>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginLeft:24,marginRight:24}}>
          <div style={{backgroundColor:config.cover_crop.options[cover_crop].color,width:16,height:16,borderRadius:8}}/>
          <label style={{fontSize:12,fontWeight:'400',color:Theme.DARK_GRAY_4,marginLeft:8}}>{config.cover_crop.options[cover_crop].label}</label>
        </div>
      )}
    </div>

    <div style={{height:30,width:10}}/>

  </div>
  </div>
  )
}
const TableView = ({data}) => {
  return (
    <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
          <div style={{marginLeft:45,width:150,display:'flex',flexDirection:'column',alignItems:'center'}}>
            <div style={{width:10,height:50}}/>
            <div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'center',height:252}}>
              <div style={{flex:1,width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                <label style={{fontWeight:'700',fontSize:14,color:Theme.BLACK_4}}>Cash crop</label>
              </div>
              <div style={{flex:1,width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                <label style={{fontWeight:'700',fontSize:14,color:Theme.BLACK_4}}>Crop yield</label>
              </div>
              <div style={{flex:1,width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                <label style={{fontWeight:'700',fontSize:14,color:Theme.BLACK_4}}>Cover Crop</label>
              </div>
              <div style={{flex:1,width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                <label style={{fontWeight:'700',fontSize:14,color:Theme.BLACK_4}}>Spring Tillage</label>
              </div>
            </div>
          </div>
          {Object.keys(data).map(year => 
            <div style={{display:'flex',flex:1,flexDirection:'column',alignItems:'center',paddingLeft:3,paddingRight:3}}>
              <div style={{width:'100%',height:50,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <label style={{fontWeight:'700',fontSize:12,color:Theme.BLACK_4}}>{year}</label>
              </div>
              <div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'center',height:252,borderRadius:8,background:'linear-gradient(180deg, #F1F3F8 52.08%, rgba(241, 243, 248, 0) 100%)'}}>
                <div style={{width:'100%',flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {
                    data[year]["crop_type"]
                    ?
                      <label style={{fontWeight:'700',fontSize:14,color:Theme.BLACK_4}}>{LegendConfig.overlay_croptype.labelFunc(data[year]["crop_type"])}</label>
                    :
                      <CloseIcon style={{fontSize:25,fontWeight:'700',color:'#585A61'}}/>
                  }
                </div>
                <div style={{width:'100%',flex:1,flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {
                    data[year]["yield"]
                    ?
                      <label style={{fontWeight:'500',fontSize:14,color:Theme.BLACK_4}}>{parseInt(data[year]["yield"]) + " bu/ac"}</label>
                    :
                      <CloseIcon style={{fontSize:25,fontWeight:'700',color:'#585A61'}}/>
                  }
                </div>
                <div style={{width:'100%',flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {
                    data[year]["cover_crop"] == null
                    ?
                      <CloseIcon style={{fontSize:25,fontWeight:'700',color:'#585A61'}}/>
                    :
                    (
                      data[year]["cover_crop"] == 1
                      ?
                        <CheckIcon style={{fontSize:25,fontWeight:'700',color:'#3EB96C'}}/>
                      :
                        <CloseIcon style={{fontSize:25,fontWeight:'700',color:'red'}}/>
                    )
                  }
                </div>
                <div style={{width:'100%',flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {
                    data[year]["spring_tillage"] == null
                    ?
                      <CloseIcon style={{fontSize:25,fontWeight:'700',color:'#585A61'}}/>
                    :
                    (
                      data[year]["spring_tillage"] == 2
                      ?
                        <div style={{display:'flex',flexDirection:'column',height:'100%',width:'100%',alignItems:'center',justifyContent:'center'}}>
                          <div style={{display:'flex',flexDirection:'row'}}>
                            <StarIcon style={{color:'#F45353',fontSize:8}} />
                          </div>
                          <label style={{fontWeight:'400',fontSize:14,color:Theme.BLACK_4}}>{"Conventional"}</label>
                        </div>
                      :
                        (
                          data[year]["spring_tillage"] == 1 
                          ?
                            <div style={{display:'flex',flexDirection:'column',height:'100%',width:'100%',alignItems:'center',justifyContent:'center'}}>
                              <div style={{display:'flex',flexDirection:'row'}}>
                                <StarIcon style={{color:'#FB7F2D',fontSize:8}} />
                                <StarIcon style={{color:'#FB7F2D',fontSize:8}} />
                              </div>
                              <label style={{fontWeight:'400',fontSize:14,color:Theme.BLACK_4}}>{"Reduced"}</label>
                            </div>
                          :
                            <div style={{display:'flex',flexDirection:'column',height:'100%',width:'100%',alignItems:'center',justifyContent:'center'}}>
                              <div style={{display:'flex',flexDirection:'row'}}>
                                <StarIcon style={{color:'#F8BB37',fontSize:8}} />
                                <StarIcon style={{color:'#F8BB37',fontSize:8}} />
                                <StarIcon style={{color:'#F8BB37',fontSize:8}} />
                              </div>
                              <label style={{fontWeight:'400',fontSize:14,color:Theme.BLACK_4}}>{"No tillage"}</label>
                            </div>
                        )
                    )
                  }
                </div>
              </div>
            </div>
          )}
          <div style={{width:40}}/>
        </div>
  )
}
function Management({selectedField,fieldData}) {
  const [showGraph,setShowGraph] = useState(false)
  if(!selectedField || !fieldData)
  {
    return <div/>
  }
  const data = fieldData['history']
  return (
    <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <label style={{fontSize:32,fontWeight:"700",marginTop:30,color:Theme.BLACK_4}}>Field Management</label>
        <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:25}}/>
        <div style={{width:'100%',marginTop:28}}>
          <label style={{fontSize:14,fontWeight:'600',marginLeft:45,float:'left',color:Theme.BLACK_4}}>Show:</label>
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row',marginTop:6}}>
          <div style={{marginLeft:45,width:226,height:35,borderRadius:6,border:'1px solid #DCE0E7'}}/>
          <div style={{flex:1}}/>
          <div style={{paddingTop:8,paddingBottom:8,paddingLeft:20,paddingRight:20,borderRadius:6,border:'1px solid #DCE0E7',display:'flex',
              flexDirection:'row',justifyContent:'center',alignItems:'center',marginRight:45,cursor:'pointer'}}
              onClick={()=>setShowGraph(!showGraph)}>
                <TableViewIcon style={{cursor:'pointer',color:'#6D707B',fontSize:12,color:Theme.DARK_GRAY_3}} />
                <label style={{cursor:'pointer',fontWeight:'500',fontSize:12,marginLeft:5,color:Theme.DARK_GRAY_4}}>{showGraph ? 'Table view' : 'Graph view'}</label>
          </div>
        </div>
          <div className="custom-scrollbar" style={{width:'100%',flex:1,overflowY:'auto',marginTop:50}}>

              {showGraph
              ?
                <GraphView data = {data}/>
              :
                <TableView data = {data}/>
              }
        </div>
        
        
    </div>
  );
}
export default Management;
