import { Auth } from "aws-amplify";
import ShedsService from "./service";

//These methods are the wrapper functions for the Fields API endpoints
class ShedsApi {
  static authenticationWrapper(args, serviceFunction) {
    if (args === null || serviceFunction == null) {
      console.log("ShedsApi: Invalid configs");

      //This function returns a JS Promise that the parent function is going to wait on.
      //If we return null here the parent function will throw on error trying to .then(...) it.
      return new Promise();
    }

    return Auth.currentSession()
      .then((res) => {
        let accessToken = res.idToken.jwtToken;

        return serviceFunction(accessToken, args);
      })
      .catch((error) => {
        // handle error.
        //TODO: Add external logging
        console.log("ShedsApi: Error getting fields: " + error.toString());
        return null;
      });
  }
  static getShed(shed_id) {
    return this.authenticationWrapper({ shed_id }, ShedsService.getShed);
  }
  static getFavorites() {
    return this.authenticationWrapper({}, ShedsService.getFavorites);
  }
  // dev below
  static getShedImage(image) {
    return this.authenticationWrapper({ image }, ShedsService.getShedImage);
  }
}

export default ShedsApi;
