import store from "../index"
import {fieldsSlice} from "../reducers/fieldsReducer"

export const setCurrentSelectedFieldArea = (fieldArea) => {
  store.dispatch(fieldsSlice.actions.reducerSetCurrentSelectedFieldArea(fieldArea));
};
export const setCountyData = (data) => {
  store.dispatch(fieldsSlice.actions.reducerSetCountyData(data));
};
export const setFIPS = (fips) => {
  store.dispatch(fieldsSlice.actions.reducerSetFIPS(fips));
};
export const setCoverCropTransparency = (transparency) => {
  store.dispatch(fieldsSlice.actions.reducerSetCoverCropTransparency(transparency));
};
export const setCountiesNASS = (nass) => {
  store.dispatch(fieldsSlice.actions.reducerSetCountiesNASS(nass));
};
export const setCountyNassYear = (year) => {
  store.dispatch(fieldsSlice.actions.reducerSetCountyNassYear(year));
};
export const setShowCountiesNASS = (show) => {
  store.dispatch(fieldsSlice.actions.reducerSetShowCountiesNASS(show));
};
export const setMarkerFields = (fields) => {
  store.dispatch(fieldsSlice.actions.reducerSetMarkerFields(fields));
};
export const setDrawOutline = (outline) => {
  store.dispatch(fieldsSlice.actions.reducerSetDrawOutline(outline));
};
export const setFieldsMap = (map) => {
  store.dispatch(fieldsSlice.actions.reducerSetFieldsMap(map));
};
export const setSidebarMapAll = (mapAll) => {
  store.dispatch(fieldsSlice.actions.reducerSetMapSidebarAll(mapAll));
};
export const setSelectedField = (field) => {
  store.dispatch(fieldsSlice.actions.reducerSetSelectedField(field));
};
export const setZoomTo = (bounds) => {
  store.dispatch(fieldsSlice.actions.reducerSetZoomTo(bounds));
};
export const setFavoriteFieldsOpen = (open) => {
  store.dispatch(fieldsSlice.actions.reducerSetFavoriteFieldsOpen(open));
};
export const setSelectedFieldData = (data) => {
  store.dispatch(fieldsSlice.actions.reducerSetSelectedFieldData(data));
};
export const setFieldOverlayType = (data) => {
  store.dispatch(fieldsSlice.actions.reducerSetFieldOverlayType(data));
};
export const clearNearbyFields = () => {
  store.dispatch(fieldsSlice.actions.reducerClearNearbyFields());
};
export const addNearbyFieldsTile = (tile,fields) => {
  store.dispatch(fieldsSlice.actions.reducerAddNearbyFieldsTile({tile,fields}));
};
export const setSearchingNearbyFieldsTile = (tile) => {
  store.dispatch(fieldsSlice.actions.reducerSetSearchingNearbyFieldsTile(tile));
};
export const unsetSearchingNearbyFieldsTile = (tile) => {
  store.dispatch(fieldsSlice.actions.reducerUnsetSearchingNearbyFieldsTile(tile));
};
export const setSelectedFavoriteField = (field) => {
  store.dispatch(fieldsSlice.actions.reducerSetSelectedFavoriteField(field));
};
export const setFavoriteFields = (fields) => {
  store.dispatch(fieldsSlice.actions.reducerSetFavoriteFields(fields));
}
export const setCreatingFavoriteField = (creating) => {
  store.dispatch(fieldsSlice.actions.reducerSetCreatingFavoriteField(creating));
}
export const setSelectedFavoriteFieldOverlayURL = (url) => {
  store.dispatch(fieldsSlice.actions.reducerSetSelectedFavoriteFieldOverlayURL(url));
}
export const setZoomInReminderVisible = (visible) => {
  store.dispatch(fieldsSlice.actions.reducerSetZoomInReminderVisible(visible));
}
export const setOverlayTransparency = (transparency) => {
  store.dispatch(fieldsSlice.actions.reducerSetOverlayTransparency(transparency));
}
export const setUniqueCropColors = (colors) => {
  store.dispatch(fieldsSlice.actions.reducerSetUniqueCropColors(colors));
}

