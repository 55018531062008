import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import FieldsApi from "../../api/fieldsService"
import OpenButton from "./OpenButton"
import { setFavoritesSidebarOpen } from "../../redux/actions/main";
import StarIcon from '@mui/icons-material/Star';
import FieldsList from "./FieldsList"
import ShedsList from "./ShedsList"
import ViewSelectTopbar from "./ViewSelectTopbar"
const sidebarWidth = 318;

function FieldsSidebar() {

  const sidebarOpen = useSelector((state) => state.main.favoritesSidebarOpen);
  const currentView = useSelector((state) => state.main.currentView);

  return (
    <div style={{zIndex:10000, width:sidebarOpen ? sidebarWidth :53,height:'100%', display: "inline-block", backgroundColor:'white',right:0,top:0, transition: "width 0.5s",
        paddingTop:28,position:'absolute'}}>
        <div style={{height:'100%',width:'100%',display:"flex",marginTop:80,flexDirection:"column",alignItems:"center",position:'relative'}}>
          <OpenButton open={sidebarOpen} setOpen={setFavoritesSidebarOpen}/>
          {sidebarOpen && 
          <div style={{width:'100%',height:'100%',overflow:'hidden',display:"flex",flexDirection:"column",alignItems:"center"}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <StarIcon style={{fontSize:26,color:"#F8BB37"}}/>
              <label style={{fontWeight:'700',fontSize:20,marginLeft:5}}>Saved Lists</label>
            </div>
            <ViewSelectTopbar />
            {currentView == 'fields' ?
            <FieldsList/>
            :
            <ShedsList/>
            }
          </div>
          }
          {!sidebarOpen && 
          <div style={{marginTop:60}}>
            <StarIcon style={{color:'#F8BB37',fontSize:30}} />
          </div>

          }
        </div>
    </div>
  );
}
export default FieldsSidebar;
