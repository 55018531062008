import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import ShedsApi from "../../api/shedsService"
//import OpenButton from "./OpenButton";
import ShedHeader from "./ShedHeader"
import DownloadReport from "./DownloadReport"
import ExpandButton from "./ExpandButton"
import ExpandedPages from "./ExpandedPages"
const sidebarWidth = 318;

const expandedPagesConfig = [
  {
    "label":"Basic Information",
    "value":"basicInfo",
    "locked":false,
    "selectedColor":"#3EB96C"
  },
  {
    "label":"Sustainability",
    "value":"sustainability",
    "locked":false,
    "selectedColor":"#FB7F2D"
  },
  {
    "label":"Management",
    "value":"management",
    "locked":false,
    "selectedColor":"#2697FF"
  },
  {
    "label":"Productivity",
    "value":"productivity",
    "locked":false,
    "selectedColor":"#A745F4"
  },
  // Remove this if we want to remove "scenarios" entirely
  {
    "label":"Scenarios",
    "value":"scenarios",
    "locked":true,
    "selectedColor":"#F249E1"
  }
]
function ShedsSidebar() {
  const [selectedShedData,setSelectedShedData] = useState(null);
  const [shedLoading,setShedLoading] = useState(null);
  const [expandedPage,setExpandedPage] = useState(null);

  const sidebarOpen = useSelector((state) => state.main.sidebarOpen);
  const selectedShed = useSelector((state) => state.sheds.selectedShed);
 
  useEffect(()=>selectedShedChange(selectedShed),[selectedShed])

  const selectedShedChange = (shed) => {
    setSelectedShedData(null)
    if (shed != null) {
      setShedLoading(true)
      var selectedShedId = shed.shed_id
      ShedsApi.getShed(selectedShedId).then((response) => {
        if(response.status != 200 || !response.data)
        {
          console.log("getShed Error: " + response.status)
          setSelectedShedData(null)
          setShedLoading(false)
          return;
        }

        setSelectedShedData(response.data)
        setShedLoading(false)
      })
      .catch((error)=>{
        console.log("getShed Error: " + error.toString())
        setSelectedShedData(null)
        setShedLoading(false)
      });
    }
  }

  if(!sidebarOpen)
  {
    return <div/>
  }

  const pageClicked = (value) => {
    // Added on 1/29/23 - Makes the "scenarios" tab unclickable but still showing
    if(selectedShed && value != "scenarios")
    {
      setExpandedPage(value)
    }
  }

  return (
    <div style={{zIndex:1000000, width:sidebarOpen ? sidebarWidth : 0,height:'100%', display: "inline-block", backgroundColor:'white',left:0,top:0, transition: "width 0.5s",
        display:"flex",flexDirection:"column",alignItems:"center",paddingTop:30}}>
        <ShedHeader selectedShed={selectedShed} shedData={selectedShedData}/>
        <DownloadReport selectedShed={selectedShed}/>
        {expandedPagesConfig.map(page =>
          <ExpandButton label={page.label} isLocked={page.locked} isEnabled={selectedShed?true:false} isExpanded={expandedPage==page.value} buttonPressed={()=>pageClicked(page.value)} selectedColor={page.selectedColor}/>
        )}
        <ExpandedPages selectedShed={selectedShed} shedData={selectedShedData} expandedPage={expandedPage} closePage={()=>pageClicked(null)} />
    </div>
  );
}
export default ShedsSidebar;
