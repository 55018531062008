import FieldsApi from "./index"
import {setCountiesNASS,setCountyData, clearNearbyFields,setFieldsMap,addNearbyFieldsTile, setFavoriteFields,setSearchingNearbyFieldsTile,unsetSearchingNearbyFieldsTile} from "../../redux/actions/fields"
import axios from "axios";
import geobuf from "geobuf"
import pbf from "pbf"
import * as d3 from "d3";
import LegendConfig from "../../containers/Map/Overlays/FieldOverlay/legendConfig"

const coverCropColorScale = d3.schemeRdYlGn[9].filter((x,index) =>index < d3.schemeRdYlGn[9].length - 1).map((x,index) =>d3.scaleLinear().range([d3.schemeRdYlGn[9][index],d3.schemeRdYlGn[9][index+1]]).domain([0, Math.floor(100*10/8)]))

const deg2num = (lat_deg, lon_deg, zoom) => {
  var x = Math.round(Math.floor((lon_deg+180)/360*Math.pow(2,zoom)))
  var y = Math.round(Math.floor((1-Math.log(Math.tan(lat_deg*Math.PI/180) + 1/Math.cos(lat_deg*Math.PI/180))/Math.PI)/2   *Math.pow(2,zoom)));
  //int x = (int) Math.Floor((lng+180)/360*Math.Pow(2,zoom));
	//int y = (int) Math.Floor((1-Math.Log(Math.Tan(lat*Math.PI/180) + 1/Math.Cos(lat*Math.PI/180))/Math.PI)/2 * Math.Pow(2,zoom));
  return [x, y]
}
export const GetNearbyFieldsTiles = (lat,lon) => {
  var xy = deg2num(lat,lon,10)


    axios.get("https://yield-platform-s3-overlays.s3.us-west-1.amazonaws.com/geojson-fields/geojson_fields_x_"+xy[0]+"_y_"+xy[1]+"_z_11.json")
    .then((response) => {
      console.log(response)
      if(response.status != 200 || !response.data)
      {
        console.log("GetNearbyFields Error: " + response.status)
        //setNearbyFields([]);
        //setShownTiles([]);
        return;
      }

      var fields = response.data
      if (fields != null) {
        //setNearbyFields(fields);
      }
    })
    .catch((error)=>{
      console.log("GetNearbyFields Error: " + error.toString())
    });
  
}
const getColor = (fieldOverlayType,properties) => {
    var value = properties[fieldOverlayType]
    if(!value)
    {
      color = "clear"
    }
    var color = LegendConfig[fieldOverlayType].colorFunc(value)

    if(fieldOverlayType == "overlay_yield_corn" && properties["overlay_croptype"] != 1)
    {
      color = "clear"
    }else if(fieldOverlayType == "overlay_yield_soybean" && properties["overlay_croptype"] != 5)
    {
      color = "clear"
    }
    return color
}
const SearchingTiles = {}
export const GetNearbyFields = (tile_x,tile_y,zoom) => {
  //setSearchingNearbyFieldsTile([tile_x,tile_y])
  var tile =[tile_x,tile_y] 
  if(SearchingTiles[tile])
  {
    return;
  }
  SearchingTiles[tile] = true
    FieldsApi.getFields(tile_x,tile_y,zoom).then((response) => {
        if(response.status != 200 || !response.data)
        {
          console.log("GetNearbyFields Error: " + response.status)
          unsetSearchingNearbyFieldsTile(tile)
          SearchingTiles[tile] = false
          return;
        }
        
        //var enc = new TextEncoder();
        //var encoded = enc.encode(response.data)
        var p = new pbf(response.data)
        var geojson = geobuf.decode(p);
console.log(geojson.features)
        if (geojson != null) {
          geojson.features = geojson.features.map(feature => {
            feature.properties["overlay_croptype_color"] = getColor("overlay_croptype",feature.properties)
            feature.properties["overlay_ndvi_color"] = getColor("overlay_ndvi",feature.properties)
            feature.properties["overlay_yield_corn_color"] = getColor("overlay_yield_corn",feature.properties)
            feature.properties["overlay_yield_soybean_color"] = getColor("overlay_yield_soybean",feature.properties)
            feature.properties["overlay_soc_color"] = getColor("overlay_soc",feature.properties)
            feature.properties["overlay_ghg_color"] = getColor("overlay_ghg",feature.properties)
            feature.properties["overlay_ch4_color"] = getColor("overlay_ch4",feature.properties)
            feature.properties["overlay_n2o_color"] = getColor("overlay_n2o",feature.properties)
            feature.properties["overlay_soc_avg_color"] = getColor("overlay_soc_avg",feature.properties)
            feature.properties["overlay_ghg_avg_color"] = getColor("overlay_ghg_avg",feature.properties)
            feature.properties["overlay_ch4_avg_color"] = getColor("overlay_ch4_avg",feature.properties)
            feature.properties["overlay_n2o_avg_color"] = getColor("overlay_n2o_avg",feature.properties)
            feature.properties["satellite_color"] = getColor("satellite",feature.properties)
            return feature
          })
          //geojson.crs = { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::4326" } }
          addNearbyFieldsTile(tile,geojson);
          unsetSearchingNearbyFieldsTile(tile)
        }else {
          console.log("GetNearbyFields Error: bad geojson",tile_x,tile_y)
          unsetSearchingNearbyFieldsTile(tile)
        }
        SearchingTiles[tile] = false
      })
      .catch((error)=>{
        SearchingTiles[tile] = false
        console.log("GetNearbyFields Error: " + error.toString())
        unsetSearchingNearbyFieldsTile(tile)
      });
}

export const  GetFavoriteFields = () => {
    FieldsApi.getFavorites().then((response) => {
        if(response.status != 200 || !response.data)
        {
          console.log("GetFavoriteFields Error: " + response.status)
          setFavoriteFields(null);
          return;
        }

        var fields = response.data
        if (fields != null) {
            setFavoriteFields(fields);
        }
      })
      .catch((error)=>{
        console.log("GetFavoriteFields Error: " + error.toString())
      });
}

export const AddFavoriteField = (field_id) => {
    FieldsApi.addFavorite(field_id)
    .then(response => {
      if(response.status != 200 || !response.data)
      {
        console.log("addFavorite error: " + response.status.toString())
      }
      GetFavoriteFields()
    })
    .catch((error)=>{
      console.log("addFavorite error: " + error.toString())
    })
}
export const RequestOverlay = (field_id) => {
    return FieldsApi.requestOverlay(field_id)
    .then(response => {
      if(response.status != 200 || !response.data)
      {
        console.log("requestOverlay error: " + response.status.toString())
      }
      return true
    })
    .catch((error)=>{
      console.log("requestOverlay error: " + error.toString())
      return false
    })
}
export const GetFieldsMap = () => {
    return FieldsApi.getFieldsMap()
    .then(response => {
      if(response.status != 200 || !response.data)
      {
        console.log("GetFieldsMap error: " + response.status.toString())
      }
      setFieldsMap(response.data)
    })
    .catch((error)=>{
      console.log("GetFieldsMap error: " + error.toString())
      return false
    })
}
export const RemoveFavoriteField = (field_id) => {
    FieldsApi.removeFavorite(field_id)
    .then(response => {
      if(response.status != 200 || !response.data)
      {
        console.log("removeFavorite error: " + response.status.toString())
      }
      GetFavoriteFields()
    })
    .catch((error)=>{
      console.log("removeFavorite error: " + error.toString())
    })
}
export const GetCountiesNASS = () => {
    return FieldsApi.getCountiesNASS()
    .then(response => {
      if(response.status != 200 || !response.data)
      {
        console.log("GetCountiesNASS error: " + response.status.toString())
      }
      
      var p = new pbf(response.data)
      var geojson = geobuf.decode(p);
      if (geojson != null) {
        geojson.features = geojson.features.map(feature => {
          for(var year = 2000;year<=2021;year=year+1)
          {
            var color = "clear"
            if(feature.properties["cover_crop"][year.toString()] != null)
            {
              var val = Math.floor(feature.properties["cover_crop"][year.toString()] ? feature.properties["cover_crop"][year.toString()]*100 : 0)
              var range = Math.floor(100*10/8)
              if(val > 10*100-1)
              {
                val = 10*100-1
              }
              var color = coverCropColorScale[Math.floor(val/range)](val%range)
            }
            feature.properties["cover_crop_" + year.toString() + "_color"] = color
          }
          return feature
        })
        setCountiesNASS(geojson)
      }
    })
    .catch((error)=>{
      console.log("GetCountiesNASS error: " + error.toString())
      return false
    })
}
export const  DownloadReport = (field_id) => {
    FieldsApi.downloadReport(field_id).then((response) => {
        if(response.status != 200 || !response.data)
        {
          console.log("DownloadReport Error: " + response.status)
          return;
        }
        var binaryData = [];
        binaryData.push(response.data);
        const href = URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
    
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'demo.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
    
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        
      })
      .catch((error)=>{
        console.log("DownloadReport Error: " + error.toString())
      });
}