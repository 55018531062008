import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { buttonGroupClasses } from "@mui/material";
import { button } from "aws-amplify";
import { color } from "d3-color";
import { Fixed32 } from "pbf";
import React, { useEffect, useRef, useLayoutEffect} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetNearbyFields } from "../../api/fieldsService/fieldsHelper";
import { setSelectedField,setUniqueCropColors,setOverlayTransparency } from "../../redux/actions/fields";
import { setSidebarOpen } from "../../redux/actions/main";
import shedsReducer from "../../redux/reducers/shedsReducer";
import LegendConfig from "./Overlays/FieldOverlay/legendConfig";

var shownTilesLoc = {};
var lastTile = null;
var lastZoom = null;
const deg2num = (lat_deg, lon_deg, zoom) => {
  var x = Math.round(Math.floor(((lon_deg + 180) / 360) * Math.pow(2, zoom)));
  var y = Math.round(
    Math.floor(
      ((1 -
        Math.log(
          Math.tan((lat_deg * Math.PI) / 180) +
            1 / Math.cos((lat_deg * Math.PI) / 180)
        ) /
          Math.PI) /
        2) *
        Math.pow(2, zoom)
    )
  );
  return [x, y];
};
var selectedFieldId = null
function FieldsLayer({ mapZoom, mapCenter, mapBounds, map }) {
  const selectedShedId = useSelector((state) => state.sheds.selectedShed);
  const currentView = useSelector((state) => state.main.currentView);

  const zoom  = 11;
  const nearbyFields = useSelector((state) => state.fields.nearbyFields);
  const shownTiles = useSelector((state) => state.fields.shownTiles);
  const selectedField = useSelector((state) => state.fields.selectedField);
  const zoomTo = useSelector((state) => state.fields.zoomTo);
  const force = useSelector((state) => state.fields.force);
  const selectedFavoriteFieldOverlayURL = useSelector((state) => state.fields.selectedFavoriteFieldOverlayURL);
  const fieldOverlayType = useSelector(
    (state) => state.fields.fieldOverlayType
  );
  const overlayTransparency = useSelector(
    (state) => state.fields.overlayTransparency
  );

  const geoJsonLayer = useRef(null);
  useEffect(()=>{
    shownTilesLoc = {}
    lastTile = null
    lastZoom = null
  },[])
  useEffect(() => {
    tryToGetNearbyFields();
    showTiles();
  }, [mapZoom, mapCenter]);
  useEffect(()=>{
    selectedFavoriteFieldOverlayURLChanged()
  }, [selectedFavoriteFieldOverlayURL])
  useEffect(() => {
    showTiles(true);
    redrawTiles()
  }, [nearbyFields]);
  useEffect(() => {
    redrawTiles();

  }, [fieldOverlayType, overlayTransparency, selectedField]);
  useEffect(() => {
    var transp = LegendConfig[fieldOverlayType].defaultTransparency;
    setOverlayTransparency(transp)
  },[fieldOverlayType])
  useEffect(()=>{
    zoomToField(selectedField)
  },[selectedField])
  useEffect(()=>{
    zoomToBounds()
  },[zoomTo,force])
  useEffect(()=>{
    drawSelectedField()
  },[selectedField,nearbyFields,fieldOverlayType])

  // added 1/22
  useEffect(()=> {
    drawShed()
  },[selectedShedId, currentView])
  useEffect(() => {
    viewChange()
  }, [currentView]);
  // draws sheds when shedID or view changes, removes outline when viewChange to Fields

  useLayoutEffect(() => {
    return () => {
        clearTiles()
    }
}, [])

  if (selectedField != null && selectedField.properties != null) {
    selectedFieldId = selectedField.properties.field_id;
    if(selectedFieldId)
    {
      selectedFieldId = parseInt(selectedFieldId)
    }
  }

  const tryToGetNearbyFields = () => {
    if (mapZoom >= 11) {
      if (mapBounds && mapBounds["_sw"] && mapBounds["_ne"]) {
        var xySW = deg2num(mapBounds["_sw"].lat, mapBounds["_sw"].lng, zoom);
        var xyNE = deg2num(mapBounds["_ne"].lat, mapBounds["_ne"].lng, zoom);

        for (var x = xySW[0]; x <= xyNE[0]; x++) {
          for (var y = xyNE[1]; y <= xySW[1]; y++) {
            if (!shownTiles[[x, y]]) {
              GetNearbyFields(x, y, zoom);
            } else {
            }
          }
        }
      }
    }
  };
  const selectedFavoriteFieldOverlayURLChanged = () =>{
    if(!map)
    {
      return
    }
    if(selectedFavoriteFieldOverlayURL == null)
    {
      if (map.getLayer('overlay-layer')) {
        map.removeLayer('overlay-layer');
      }
      if (map.getSource('overlay-source')) {
        map.removeSource('overlay-source');
      }
    }
    if(selectedField != null)
    {
      var max_0 = 0
      var min_0 = 0
      var max_1 = 0
      var min_1 = 0
      if(selectedField.geometry.bbox)
      {
              max_0 = selectedField.geometry.bbox[2]
              min_0 = selectedField.geometry.bbox[0]
              max_1 = selectedField.geometry.bbox[3]
              min_1 = selectedField.geometry.bbox[1]
      }else {
        max_0 = selectedField.geometry.coordinates[0][0][0]
              min_0 = selectedField.geometry.coordinates[0][0][0]
              max_1 = selectedField.geometry.coordinates[0][0][1]
              min_1 = selectedField.geometry.coordinates[0][0][1]
              for(var i = 0; i < selectedField.geometry.coordinates[0].length;i++)
              {
                var zero = selectedField.geometry.coordinates[0][i][0]
                var one = selectedField.geometry.coordinates[0][i][1]
                if(zero > max_0)
                {
                  max_0 = zero
                }
                if(zero < min_0)
                {
                  min_0 = zero
                }
                if(one > max_1)
                {
                  max_1 = one
                }
                if(one < min_1)
                {
                  min_1 = one
                }
              }
      }
              
    var imageUrl = selectedFavoriteFieldOverlayURL
    
    if (map.getLayer(
      'overlay-layer')) {
      map.removeLayer('overlay-layer');
    }
    if (map.getSource('overlay-source')) {
      map.removeSource('overlay-source');
    }
    map.addSource('overlay-source', {
      'type': 'image',
      'url': imageUrl,
      'coordinates': [
        [min_0, max_1],
      [max_0, max_1],
      [max_0, min_1],
      [min_0, min_1]
      ]
      });
      map.addLayer({
      id: 'overlay-layer',
      'type': 'raster',
      "minzoom":11,
      'source': 'overlay-source',
      'paint': {
      'raster-fade-duration': 0
      }
      });
    }
  }
  var options = {
    maxZoom: 16,
    tolerance: 3,
    debug: 0,
    style: {
      fillColor: "#1EB300",
      color: "#F2FF00",
      weight: 2,
    },
  };
  const clearTiles = () => {
    var tiles = Object.keys(shownTilesLoc);
    for (var i = 0; i < tiles.length; i++) {
      if (shownTilesLoc[tiles[i]]) {
        var tile = shownTilesLoc[tiles[i]].tile;
        var layer = shownTilesLoc[tiles[i]].layer;
        var source = shownTilesLoc[tiles[i]].source;
        if (tile && map.getLayer(layer)) {
          map.removeLayer(layer);
          if (map.getLayer(layer + "-outline")) {
            map.removeLayer(layer + "-outline");
          }
          if (map.getSource(source)) {
            map.removeSource(source);
          }
          shownTilesLoc[tiles[i]] = null;
        }
      }
    }
  };
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  const showTiles = (forceDraw) => {
    if (mapZoom < 11) {
      return;
    }

    if (mapBounds && mapBounds["_sw"] && mapBounds["_ne"]) {
      var xySW = deg2num(mapBounds["_sw"].lat, mapBounds["_sw"].lng, zoom);
      var xyNE = deg2num(mapBounds["_ne"].lat, mapBounds["_ne"].lng, zoom);
      if (
        !forceDraw &&
        lastTile &&
        lastZoom == mapZoom &&
        xySW[0] == lastTile[0] &&
        xySW[1] == lastTile[1] &&
        xyNE[0] == lastTile[2] &&
        xyNE[1] == lastTile[3]
      ) {
        return;
      }
      lastTile = [xySW[0], xySW[1], xyNE[0], xyNE[1]];
      lastZoom = mapZoom;

      //Remove already drawn tiles outside screen
      var tiles = Object.keys(shownTilesLoc);
      for (var i = 0; i < tiles.length; i++) {
        if (shownTilesLoc[tiles[i]]) {
          var tile = shownTilesLoc[tiles[i]].tile;
          var layer = shownTilesLoc[tiles[i]].layer;
          var source = shownTilesLoc[tiles[i]].source;
          if (
            tile &&
            map.getLayer(layer) &&
            (tile[0] < xySW[0] ||
              tile[0] > xyNE[0] ||
              tile[1] < xyNE[1] ||
              tile[1] > xySW[1])
          ) {
            map.removeLayer(layer);
            if (map.getLayer(layer + "-outline")) {
              map.removeLayer(layer + "-outline");
            }
            if (map.getSource(source)) {
              map.removeSource(source);
            }
            shownTilesLoc[tiles[i]] = null;
          }
        }
      }
      var colors = []
      var selectedFieldId = null;
  if (selectedField != null && selectedField.properties != null) {
    selectedFieldId = selectedField.properties.field_id;
    if(selectedFieldId)
    {
      selectedFieldId = parseInt(selectedFieldId)
    }
  }
      //Draw any new tiles to the screen
      for (var x = xySW[0]; x <= xyNE[0]; x++) {
        for (var y = xyNE[1]; y <= xySW[1]; y++) {
          if (!shownTilesLoc[[x, y]] && nearbyFields[[x, y]]) {
            var source_name = "tilex_" + x + "tiley_" + y + "source";
            var layer_name = "tilex_" + x + "tiley_" + y + "layer";
            var layer_properties = {
              id: layer_name,
              type: "fill",
              source: source_name,
              paint: {
                "fill-color": ["get", fieldOverlayType + "_color"],

                "fill-opacity": [
                  "case",
                  ["==", ["get", fieldOverlayType + "_color"], "clear"],
                  0,
                  overlayTransparency
                ],
              },
              minzoom:11
            };
            /*var outline_properties = {
              id: layer_name + "-outline",
              type: "line",
              source: source_name,
              paint: {
                "line-color": [
                  "case",
                  ["==", ["get", "field_id"], selectedFieldId],
                  fieldOverlayType ? LegendConfig[fieldOverlayType].selectedColor : 'green',
                  "#0088dc"
                ],
                "line-opacity": [
                  "case",
                  ["==", ["get", "field_id"], selectedFieldId],
                  1.0,
                  0.6
                ],
                "line-width": [
                  "case",
                  ["==", ["get", "field_id"], selectedFieldId],
                  4,
                  2
                ],
              }
            };*/
            var outline_properties = {
              id: layer_name + "-outline",
              type: "line",
              source: source_name,
              paint: {
                "line-color": "#0088dc",
                "line-opacity": 0.6,
                "line-width": 2,
              },
              minzoom:11
            };
            if(map.getSource(source_name))
            {map.removeSource(source_name)}
            if(map.getLayer(layer_name))
            {
              map.removeLayer(layer_name)
            }
            if(map.getLayer(layer_name + "-outline"))
            {
              map.removeLayer(layer_name + "-outline")
            }
            map.addSource(source_name, {
              type: "geojson",
              data: nearbyFields[[x, y]].fields,
            });
            map.addLayer(layer_properties);
            map.addLayer(outline_properties);
            map.on("click", layer_name, fieldClicked);
            shownTilesLoc[[x, y]] = {
              layer: layer_name,
              source: source_name,
              tile: [x, y],
            };
          } 
          /*if(nearbyFields[[x, y]] && nearbyFields[[x, y]].fields.features)
            {
              colors = colors.concat(nearbyFields[[x, y]].fields.features.map(field=>field.properties?field.properties.overlay_croptype:null))
            }*/
        }
      }
      //colors = colors.filter(onlyUnique)
      //setUniqueCropColors(colors)
    }
    if(map.getLayer("selected_field_outline"))
    {
      map.moveLayer("selected_field_outline")
    }

  };
  function fieldClicked (e) {
    var field = e.features[0].toJSON()
    if(selectedFieldId != field.properties.field_id)
    {
    setSelectedField(field);
    }
    setSidebarOpen(true);
  } 
  function zoomToField(field)
  {
    if(field==null || field.properties == null)
    {
      return;
    }
    map.resize()
    map.flyTo({
      center: [
        field.properties.lng,
        field.properties.lat
      ],
      duration:5000,
      zoom:13,
      essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });
  }
  function zoomToBounds()
  {
    if(zoomTo==null)
    {
      return;
    }
    // this is to accomodate for the shedDemo, can change values later
    if (currentView != 'fields') {
      map.fitBounds(zoomTo, {padding:100, duration:5000})
    } else {
      map.fitBounds(zoomTo,{padding:25,duration:5000})
    }
  }
  //This is called when we do something other than a move(aka when the fields should be colored differently)
  const redrawTiles = () => {
    var tiles = Object.keys(shownTilesLoc);
    for (var i = 0; i < tiles.length; i++) {
      if (shownTilesLoc[tiles[i]]) {
        var tile = shownTilesLoc[tiles[i]].tile;
        var layer = shownTilesLoc[tiles[i]].layer;
        var outline = layer + "-outline";
        if (tile && map.getLayer(layer)) {
          map.setPaintProperty(layer, "fill-color", ["get", fieldOverlayType + "_color"]);
          map.setPaintProperty(layer, "fill-opacity", [
            "case",
            ["==", ["get", fieldOverlayType + "_color"], "clear"],
            0,
            overlayTransparency
          ]);
          /*map.setPaintProperty(outline, "line-color", [
            "case",
            ["==", ["get", "field_id"], selectedFieldId],
            fieldOverlayType ? LegendConfig[fieldOverlayType].selectedColor : 'green',
            "#0088dc"
          ]);
          map.setPaintProperty(outline, "line-width", [
            "case",
            ["==", ["get", "field_id"], selectedFieldId],
            4,
            2
          ]);
          map.setPaintProperty(outline, "line-opacity", [
            "case",
            ["==", ["get", "field_id"], selectedFieldId],
            1.0,
            0.6
          ]);*/
          map.on("click", layer, fieldClicked);
        }
      }
    }
  };

  const drawSelectedField = () =>{
    if(selectedFieldId == null || nearbyFields == null)
    {
      return
    }
    var field_coll = null
    Object.keys(nearbyFields).map(tile => {
      if(nearbyFields[tile].fields && nearbyFields[tile].fields.features)
      {
        nearbyFields[tile].fields.features.map(field=>{
          if(field.properties && field.properties.field_id == selectedFieldId)
          {
            field_coll = {
              type:'FeatureCollection',
              features:[
                field
              ]
            }
          }
        })
      }
    })
    if(field_coll == null)
    {

    }
    var source_name = "selected_field_source";
    var outline_name = "selected_field_outline"
    var outline_properties = {
      id: outline_name,
      type: "line",
      source: source_name,
      paint: {
        "line-color": fieldOverlayType ? LegendConfig[fieldOverlayType].selectedColor : 'green',
        "line-opacity": 1,
        "line-width": 4,
      },
      minzoom:11
    };
    if(map.getLayer(outline_name))
    {
      map.removeLayer(outline_name)
    }
    if(map.getSource(source_name))
    {
      map.removeSource(source_name)
    }
    if(field_coll != null)
    {
  map.addSource(source_name, {
      type: "geojson",
      data: field_coll,
    });
    map.addLayer(outline_properties);
    map.moveLayer(outline_name)
  }
  }

  const drawShed = () => {
    if (selectedShedId == null) {
      return
    }
    // console.log(selectedShedId);
    var source_name = "shed_source";
    var outline_name = "shed_outline"
    var fill_name = "shed_fill"
    var outline_properties = {
        id: outline_name,
        type: "line",
        source: source_name,
        minzoom: 9, 
        paint: {
          "line-color": '#0c00f8',
          "line-opacity": 1,
          "line-width": 2,
        }
    };
    var fill_properties = {
        id: fill_name,
        type: "fill",
        source: source_name,
        minzoom: 9,
        paint: {
          "fill-color": '#0c00f8',
          "fill-opacity": 0.2
        }
    };

    if (map.getLayer (outline_name) ) {
      map.removeLayer(outline_name)
    }
    if (map.getLayer (fill_name) ) {
      map.removeLayer(fill_name)
    }
    if (map.getSource (source_name) ) {
      map.removeSource(source_name)
    }
    // console.log(selectedShedId.outline)
    if(selectedShedId.outline != null) {
        map.addSource(source_name, {
          type: "geojson",
          data: JSON.parse(selectedShedId.outline),
        });
        map.addLayer(outline_properties);
        map.addLayer(fill_properties);
        map.moveLayer(outline_name);
        map.moveLayer(fill_name);
    }     
  }

  // removes shed outline when clicking "Fields"
  const viewChange = () => {
    if (currentView == 'fields') {
      if (map.getLayer('shed_outline')) {
        map.removeLayer('shed_outline')
      }
      if (map.getLayer('shed_fill')) {
        map.removeLayer('shed_fill')
      }
      if (map.getSource('shed_source')) {
        map.removeSource('shed_source')
      }
    }
  }
  return <div></div>;
}


export default FieldsLayer;
