import store from "../index"
import {shedsSlice} from "../reducers/shedsReducer"

export const setSelectedShed = (shed) => {
  store.dispatch(shedsSlice.actions.reducerSetSelectedShed(shed));
};
export const setSelectedShedData = (data) => {
  store.dispatch(shedsSlice.actions.reducerSetSelectedShedData(data));
};
export const setFavoriteSheds = (sheds) => {
  store.dispatch(shedsSlice.actions.reducerSetFavoriteSheds(sheds));
};