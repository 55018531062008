import React, { useEffect,useState, useRef } from "react";
import { useSelector } from "react-redux";
import TableViewIcon from '@mui/icons-material/TableView';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotateLabels from 'chartjs-plugin-annotation'
import * as Theme from "../../../../statics/theme"

import { Bar } from 'react-chartjs-2';
const colors = ["#2697FF","#FB7F2D","#3EB96C","#33674E","#3D93A6","#1A9275"]
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartAnnotateLabels
);
//ChartJS.plugins.register(ChartAnnotateLabels);
const labels = [2011,2012,2013,2014,2015,2016,2017,2018,2019,2020];

const Graph = ({data}) =>{
  const ref = useRef(null);
  const [showTooltip,setShowTooltip] = useState(false);
  const [positionAndData,setPositionAndData] = useState(false);

  var labels = Object.keys(data)
  var baseData = {
    labels,
    datasets: [
      {
        data: [],
        borderRadius:15,
        backgroundColor: '#CCD0DA',
        barThickness:16,
        borderSkipped: false,
      },
    ],
  };

  baseData.datasets[0].data = labels.map(year => {
    if(data[year])
    {
      return [data[year].yield_min,data[year].yield_max]
    }

    return [0,0]
  })


  var options = {
    responsive: true,
    maintainAspectRatio:false,
    plugins: {
      legend: {
        position: 'top',
        display:false
      },
      title: {
        display: false,
      },
      datalabels: {
          display: false,
      },
      annotation: {
        annotations: {
        }
      },
      "tooltip": {
        "enabled": false,
        "mode": "x",
        "intersect": false,
        "external": (context) => {
          const {
            chart,
            tooltip
          } = context;
          // if chart is not defined, return early
          if (!chart) {
            return;
          }
  
          // hide the tooltip when chartjs determines you've hovered out
          if (tooltip.opacity === 0) {
            if(showTooltip)
            {
              setShowTooltip(false)
            }
            return;
          }
  
          const position = chart.canvas.getBoundingClientRect();
  
          // assuming your tooltip is `position: fixed`
          // set position of tooltip
          const left = position.left + tooltip.caretX + 21;
          const top = position.top + tooltip.caretY + 15;
  
          // set values for display of data in the tooltip
          const date = tooltip.dataPoints[0].label;
  
          if(!showTooltip && data[date].yield && (data[date].yield_min != 0 || data[date].yield_max != 0))
          {
            var yld = data[date].yield
            if(!yld)
            {
              yld = 0
            }
            setPositionAndData({top, left, date, yld});
            setShowTooltip(true)
          }
        },
      }
    },
    scales: {
      y: {
          ticks: {
              // Include a dollar sign in the ticks
              color:'#B4B8C4',
              beginAtZero:true,
              max:300
          },
          grid: {
            borderColor:'#B4B8C4',
            color:'#CCD0DA',
            borderDash: [2,2],
            borderWidth:1,
            lineWidth:1
          }
      },
      x: {
          grid: {
            borderColor:'#B4B8C4',
            color:'#E8EBF1',
            borderWidth:1,
            lineWidth:1
          }
      },
    }
  };
  options.plugins.annotation.annotations = {}
  labels.map((year,index) => {
    if(data[year] && data[year].yield)
    {
      options.plugins.annotation.annotations[year] = {
        type: 'point',
        xValue: index,
        yValue: data[year].yield,
        backgroundColor: '#18442F',
        radius:5,
        borderWidth:0
      }
    }
  })
  debugger
  return (
    <div style={{width:'100%',height:225,marginTop:30}}>
      <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
        <label style={{color:Theme.LIGHT_GRAY_4,fontSize:12,fontWeight:'500',marginLeft:30}}>Yield, bushels/acre</label>
        <div style={{flex:1}}/>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:60}}>
          <div style={{width:16,height:16,borderRadius:3,backgroundColor:Theme.LIGHT_GRAY_2}} />
          <label style={{color:Theme.LIGHT_GRAY_4,fontSize:12,fontWeight:'400',marginLeft:10}}>County Yeld Range (5%-95% Percentible)</label>
        </div>
        <div style={{flex:1}}/>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:35}}>
          <div style={{width:16,height:16,borderRadius:8,backgroundColor:Theme.DARK_GREEN_2}} />
          <label style={{color:Theme.LIGHT_GRAY_4,fontSize:12,fontWeight:'400',marginLeft:10}}>Selected Property Yield</label>
        </div>
      </div>
      <Bar ref={ref}options={options} plugins={[ChartDataLabels]}  data={baseData}/>
      {showTooltip && positionAndData!=null && 
        <div style={{position:'fixed',top:positionAndData.top,left:positionAndData.left,width:1000,height:47}}>
          <div style={{boxShadow:'1px 0px 20px rgba(82, 92, 118, 0.1)',backgroundColor:'white',
                      borderRadius:'0px 6px 6px 6px',border:'1px solid gray',width:'fit-content',borderColor:Theme.LIGHT_GRAY_1,display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'center'}}>
            <label style={{fontSize:14,color:Theme.DARK_GRAY_4,fontWeight:'600',marginLeft:12,marginTop:6,marginBottom:2}}>{parseInt(positionAndData.yld) + " bushels/acre"}</label>
            <label style={{fontSize:12,color:Theme.DARK_GRAY_4,fontWeight:'500',marginLeft:12,marginRight:12,marginBottom:6}}>better than 90% of the corn fields in the county</label>
          </div>
        </div>
      }
    </div>
  )
}
function Productivity({selectedField,fieldData}) {
  if(!selectedField || !fieldData)
  {
    return <div/>
  }
  var cornData = {}
  if(fieldData.productivity && fieldData.productivity.corn)
  {
    cornData = fieldData.productivity.corn
  }
  var soybeansData = {}
  if(fieldData.productivity && fieldData.productivity.soybeans)
  {
    soybeansData = fieldData.productivity.soybeans
  }
  return (
    <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
        <label style={{fontSize:32,fontWeight:"700",marginTop:30,color:Theme.BLACK_4}}>Productivity</label>
        <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:25}}/>

        <div className="custom-scrollbar" style={{width:'100%',flex:1,overflowY:'scroll',marginTop:48,display:'flex',flexDirection:'column',overflowX:'hidden'}}>
          <div style={{height:200,marginLeft:45,marginRight:45}}>
            <div style={{width:'100%',height:'fit-content',flex:1,display:'flex',alignItems:"center",justifyContent:'center',flexDirection:'column',}}>
              <div style={{backgroundColor:'#F8BB37',borderRadius:18,height:24,width:'fit-content',padding:'0px 14px 0px 14px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <label style={{fontWeight:'700',fontSize:16,color:Theme.BLACK_4}}>Corn Yield</label>
              </div>
              <Graph data={cornData}/>

              <div style={{width:640,height:1,border:"0px solid #DCE0E7",borderColor:Theme.LIGHT_GRAY_1,borderBottomWidth:1,marginTop:48}}/>
              <div style={{backgroundColor:'#18442F',borderRadius:18,height:24,width:'fit-content',padding:'0px 14px 0px 14px',marginTop:48,display:'flex',alignItems:'center',justifyContent:'center'}}>
                <label style={{fontWeight:'700',fontSize:16,color:'white'}}>Soybean Yield</label>
              </div>
              <Graph data={soybeansData}/>

              <div style={{height:50,width:10}}/>
            </div>
          </div>
        </div>
        
        
    </div>
  );
}
export default Productivity;
