import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import { AddFavoriteShed, RemoveFavoriteShed } from "../../api/fieldsService/fieldsHelper";
import { setFavoriteSheds } from "../../redux/actions/fields";

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
function FavoriteButtonSheds({selectedShed}) {
  const favoriteSheds = useSelector((state) => state.sheds.favoriteFields);

  var isFavorite = false;
  var selectedShedId = null;
  if (selectedShed != null) {
    selectedShedId = selectedShed.shed_id;
  }

  if(favoriteSheds)
  {
    isFavorite = favoriteSheds.filter(shed=>shed.shed_id==selectedShedId).length() > 0
  }
  

  const setIsFavorite = () => {
    //AddFavoriteShed(selectedShedId);
  };
  const removeIsFavorite = () => {
    //RemoveFavoriteShed(selectedShedId)
  };
  const favoriteButtonPressed = () => {
    if (isFavorite) {
      removeIsFavorite();
    } else {
      setIsFavorite();
    }
  };
  return (
        <div style={{border:"1px solid #F8BB37",borderRadius:20/2,height:20,width:20,display:"flex",justifyContent:"center",alignItems:"center",
          cursor:'pointer'}} onClick={favoriteButtonPressed}>
          { isFavorite
          ?
            <StarIcon style={{fontSize:16,color:"#F8BB37"}}/>
          :
            <StarBorderIcon style={{fontSize:16,color:"#F8BB37"}}/>
          }
        </div>
  );
}
export default FavoriteButtonSheds;
