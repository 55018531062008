import React from "react";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import * as Theme from "../../statics/theme"

function OpenButton({open,setOpen}) {

  const sidebarOpenClicked = () => {
    setOpen(!open)
  }
  
  return (
    <div style={{position:'absolute',cursor:'pointer',top:19,left:open?-21:-21,width:32,height:28, borderRadius:48,backgroundColor:'white',boxShadow:"0px 2px 8px rgba(70, 113, 70, 0.44)",
                display:'flex',alignItems:'center',justifyContent:'center'}} onClick={sidebarOpenClicked}>
    {
      open ? 
      <div style={{marginTop:3,marginLeft:3}}>
      <ArrowForwardIosIcon style={{cursor:'pointer',color:Theme.DARK_GRAY_4,fontSize:18}} /> 
        </div>
        :
        <div style={{marginTop:3,marginLeft:5}}>
        <ArrowBackIosIcon style={{cursor:'pointer',color:Theme.DARK_GRAY_4,fontSize:18}} /> 
        </div>
    }
    
        </div>
  )
}
export default OpenButton;
