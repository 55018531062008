import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import FieldsLayer from "./FieldsLayer";
import { setFIPS,setZoomInReminderVisible, setDrawOutline, setZoomTo, setSelectedField,setSelectedFavoriteFieldOverlayURL,setShowCountiesNASS } from "../../redux/actions/fields";
import FieldsApi from "../../api/fieldsService/index"
import ZoomControl from "./Overlays/ZoomControl"
import FieldOverlay from "./Overlays/FieldOverlay"
import ViewSelect from "./Overlays/ViewSelect"
function MapComponent() {
  const [map, setMap] = useState(null);
  const [mapZoom, setMapZoom] = useState(0);
  const [mapCenter, setMapCenter] = useState(0);
  const favoritesSidebarOpen = useSelector((state) => state.main.favoritesSidebarOpen);
  const sidebarOpen = useSelector((state) => state.main.open);

  useEffect(() => {
    createMap()
  }, []);
  useEffect(() => {
    if (map) {
      setupMapMovementTracking();
    }
  }, [map]);
  useEffect(()=>{
    setTimeout(()=>{
      if(map)
      {
        map.resize()
      }
    },1000)
  },[favoritesSidebarOpen])

  const createMap = () =>{
    const mapI = new window.maplibregl.Map({
      container: "map",
      style:
        "https://api.maptiler.com/maps/hybrid/style.json?key=0PCx4vmTR9PdEq7BIURL",
      center: [-98.5795,39.8283],//[-88.8647,40.5478],//[-88.2434, 40.1164],
      zoom: 4,//10,
    });
    //mapI.addControl(new window.maplibregl.NavigationControl({showCompass: false}), "bottom-right");

    var geocoder_api = {
      forwardGeocode: async (config) => {
        var features = await GetNominatimSearch(config.query)
        var csFeatures = await GetCountyStateSearch(config.query)
        csFeatures.push(...features)
        return {
          features:csFeatures,
        }
      },
    };
    var geocoder = new window.MaplibreGeocoder(geocoder_api, {
      maplibregl: window.maplibregl,
      flyTo: false,
      marker: false,
      showResultMarkers: false,
      showResultsWhileTyping: true,
      countries:"USA"
    });
    geocoder.on('result', function (e) {
      if(e.result.properties && e.result.properties.type == "administrative")
      {
        if(e.result.properties.bounds)
        {
          mapI.fitBounds(e.result.properties.bounds,{padding:25})
          setDrawOutline(e.result.geometry)
        }
      }
      else {
        mapI.flyTo({
          center: e.result.center,
          zoom:13,
          essential: true // this animation is considered essential with respect to prefers-reduced-motion
          });
      }
    })

    geocoder.onAdd(mapI);
    geocoder.addTo("#geocoder");

    mapI.on("load",()=>setMap(mapI))
  }
  const setupMapMovementTracking = () => {
    const mapMoved = () => {
      setMapZoom(map.getZoom());
      setMapCenter(map.getCenter());
    };
    map.on("resize", function () {
      mapMoved();
    });

    map.on("moveend", function () {
      mapMoved();
    });
  };
  return (
    <div style={{
      position:'absolute',
      height:'100%',
      width:sidebarOpen?"calc(100% + 600px)":"100%",
      top:0,
      left:sidebarOpen?"-100px":"0px"
    }}>
      <div style={{width:"100%",height:'100%',position:"relative"}}>
          <ZoomControl map={map} />
          <FieldOverlay />
          <ViewSelect />
        <div
          id="map"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          {map && <FieldsLayer mapZoom={mapZoom} mapCenter={mapCenter} map={map} mapBounds={map ? map.getBounds() : {}}/>}
          <div
            style={{
              position: "fixed",
              marginTop:80,
              width: "100%",
              display: "flex",
              flexDirction: "row",
              top: 20,
              left: 0,
              zIndex: 1000,
              justifyContent: "center",
            }}
          >
            <div id="geocoder"></div>
            {/* The style for the search geocoder comes from App.css*/}
          </div>
      </div>
    </div>
    </div>
  );
}

const GetNominatimSearch = async (term) => {
  const features = [];
        try {
          let request =
            "https://nominatim.openstreetmap.org/search?q=" +
            term +
            "&format=geojson&polygon_geojson=1&addressdetails=1&countrycodes=us";
          const response = await fetch(request);
          const geojson = await response.json();
          for (let feature of geojson.features) {
            if(!feature.properties || feature.properties.type != "administrative")
            {
            let center = [
              feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
              feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2,
            ];
            let point = {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: feature.bbox,
              },
              place_name: feature.properties.display_name,
              properties: feature.properties,
              text: feature.properties.display_name,
              place_type: ["place"],
              center: center,
            };
            features.push(point);
            }
          }
        } catch (e) {
          console.error(`Failed to forwardGeocode with error: ${e}`);
        }

        return features
}
const GetCountyStateSearch = async (term) => {
  var features = []
  try{
  const response = await FieldsApi.searchCountyState(term)
  const data = response.data;

  for(let feature of data)
  {
    let center = [
      feature.bounds[0] + (feature.bounds[2] - feature.bounds[0]) / 2,
      feature.bounds[1] + (feature.bounds[3] - feature.bounds[1]) / 2,
    ];
    let point = {
      type: "Feature",
      geometry: JSON.parse(feature.geometry ? feature.geometry:'{}'),
      place_name: feature.name,
      properties: {
        bounds:feature.bounds,
        type:"administrative"
      },
      text: feature.name,
      place_type: ["place"],
      center: center,
    };
    features.push(point);
  }
  }catch(e)
  {
    console.log(`error getting county state: ${e}`);
  }
  return features
}


export default MapComponent;
