import { Auth } from "aws-amplify";
import PermissionsService from "./service";

//These methods are the wrapper functions for the Fields API endpoints
class PermissionsAPI {
  static authenticationWrapper(args, serviceFunction) {
    if (args === null || serviceFunction == null) {
      console.log("PermissionsAPI: Invalid configs");

      //This function returns a JS Promise that the parent function is going to wait on.
      //If we return null here the parent function will throw on error trying to .then(...) it.
      return new Promise();
    }

    return Auth.currentSession()
      .then((res) => {
        let accessToken = res.idToken.jwtToken;

        return serviceFunction(accessToken, args);
      })
      .catch((error) => {
        // handle error.
        //TODO: Add external logging
        console.log("PermissionsAPI: Error getting fields: " + error.toString());
        return null;
      });
  }

  static getPermissions(permissionsApp) {
    return this.authenticationWrapper(
      {permissionsApp },
      PermissionsService.getPermissions
    );
  }
}

export default PermissionsAPI;
