import React from "react";
import { useSelector } from "react-redux";
import FieldLevelLegend from "./legend"
import TransparencySlider from "./transparencySlider"
import OverlaySelect from "./overlaySelect";
import {
  setFieldOverlayType
} from "../../../../redux/actions/fields";
import {
  setOverlayTransparency
} from "../../../../redux/actions/fields";

function FieldOverlay() {
  const fieldOverlayType = useSelector((state) => state.fields.fieldOverlayType);
  const overlayTransparency = useSelector((state) => state.fields.overlayTransparency);
  return (
    <div
      style={{
        position: "absolute",
        bottom: 20,
        left: 20,
        zIndex:1000,
        display:'flex',
        flexDirection:'column',
        backgroundColor:'white',
        borderRadius:16,
        padding:20,
        transition:'left 0.5s',
        alignItems:"center",
        justifyContent:"center",
        border:"1px solid #FAFBFF",
        backgroundColor:"#E8EBF1"
      }}
    >
      <TransparencySlider type={fieldOverlayType} transparency={overlayTransparency} setTransparency={setOverlayTransparency}/>
      <OverlaySelect type={fieldOverlayType} setType={setFieldOverlayType} />
      <FieldLevelLegend type={fieldOverlayType} />
    </div>
  );
}
export default FieldOverlay;
