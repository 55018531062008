import { Auth } from "aws-amplify";
import FieldsService from "./service";

//These methods are the wrapper functions for the Fields API endpoints
class FieldsApi {
  static authenticationWrapper(args, serviceFunction) {
    if (args === null || serviceFunction == null) {
      console.log("FieldsApi: Invalid configs");

      //This function returns a JS Promise that the parent function is going to wait on.
      //If we return null here the parent function will throw on error trying to .then(...) it.
      return new Promise();
    }

    return Auth.currentSession()
      .then((res) => {
        let accessToken = res.idToken.jwtToken;

        return serviceFunction(accessToken, args);
      })
      .catch((error) => {
        // handle error.
        //TODO: Add external logging
        console.log("FieldsApi: Error getting fields: " + error.toString());
        return null;
      });
  }

  static getFields(tile_x,tile_y,zoom) {
    return this.authenticationWrapper(
      { tile_x,tile_y,zoom },
      FieldsService.getFields
    );
  }
  static getField(field_id) {
    return this.authenticationWrapper({ field_id:field_id/*1327747/*field_id*/ }, FieldsService.getField);
  }
  static getFieldsMap() {
    return this.authenticationWrapper({}, FieldsService.getFieldsMap);
  }
  static getFavorites() {
    return this.authenticationWrapper({}, FieldsService.getFavorites);
  }
  static addFavorite(field_id) {
    return this.authenticationWrapper({ field_id }, FieldsService.addFavorite);
  }
  static getCounty(fips) {
    return this.authenticationWrapper({ fips }, FieldsService.getCounty);
  }
  static getCountiesNASS() {
    return this.authenticationWrapper({  }, FieldsService.getCountiesNASS);
  }
  static searchCountyState(term) {
    return this.authenticationWrapper({ term }, FieldsService.searchCountyState);
  }
  static requestOverlay(field_id) {
    return this.authenticationWrapper({ field_id }, FieldsService.requestOverlay);
  }
  static removeFavorite(field_id) {
    return this.authenticationWrapper({ field_id }, FieldsService.removeFavorite);
  }
  static downloadReport(field_id) {
    return this.authenticationWrapper({ field_id }, FieldsService.downloadReport);
  }
  static createFavorite(field_json) {
    return this.authenticationWrapper(
      { field_json },
      FieldsService.createFavorite
    );
  }
  static getTableData(filters, sorts, page, pageSize ) {
    return this.authenticationWrapper(
      { filters, sorts, page, pageSize  },
      FieldsService.getTableData
    );
  }
}

export default FieldsApi;
