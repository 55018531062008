import { createSlice } from "@reduxjs/toolkit";

export const fieldsSlice = createSlice({
  name: "fields",
  initialState: {
    selectedField: null,
    selectedFieldData: null,
    nearbyFields: {},
    fieldOverlayType:'overlay_croptype',
    selectedFavoriteField: null,
    favoriteFields: null,
    creatingFavoriteField:false,
    selectedFavoriteFieldOverlayURL:null,
    zoomInReminderVisible:true,
    overlayTransparency:0.4,
    shownTiles:{},
    searchingTiles:{},
    uniqueCropColors:[1,5],
    favoriteFieldsOpen:true,
    zoomTo:null,
    mapSidebarAll:true,
    fieldsMap:[],
    drawOutline:null,
    markerFields:[],
    currentSelectedFieldArea:null,
    countyData:null,
    fips:null,
    countiesNASS:{},
    showCountiesNASS:false,
    countyNassYear:2021,
    coverCropTransparency:.4,
    force:true,
  },
  reducers: {
    reducerSetFIPS: (state, action) => {
      state.fips = action.payload;
    },
    reducerSetCoverCropTransparency: (state, action) => {
      state.coverCropTransparency = action.payload;
    },
    reducerSetCountyNassYear: (state, action) => {
      state.countyNassYear = action.payload;
    },
    reducerSetCountiesNASS: (state, action) => {
      state.countiesNASS = action.payload;
    },
    reducerSetShowCountiesNASS: (state, action) => {
      state.showCountiesNASS = action.payload;
    },
    reducerSetCountyData: (state, action) => {
      state.countyData = action.payload;
    },
    reducerSetCurrentSelectedFieldArea: (state, action) => {
      state.currentSelectedFieldArea = action.payload;
    },
    reducerSetDrawOutline: (state, action) => {
      state.drawOutline = action.payload;
    },
    reducerSetMarkerFields: (state, action) => {
      state.markerFields = action.payload;
    },
    reducerSetFieldsMap: (state, action) => {
      state.fieldsMap = action.payload;
    },
    reducerSetMapSidebarAll: (state, action) => {
      state.mapSidebarAll = action.payload;
    },
    reducerSetSelectedField: (state, action) => {
      state.selectedField = action.payload;
      state.fips = null
    },
    reducerSetZoomTo: (state, action) => {
      state.zoomTo = action.payload;
      state.force = !state.force
    },
    reducerSetFavoriteFieldsOpen: (state, action) => {
      state.favoriteFieldsOpen = action.payload;
    },
    reducerSetSelectedFieldData: (state, action) => {
      state.selectedFieldData = action.payload;
    },
    reducerSetFieldOverlayType: (state, action) => {
      state.fieldOverlayType = action.payload;
    },
    reducerClearNearbyFields: (state, action) => {
      state.nearbyFields = {}
      state.shownTiles = {}
    },
    reducerAddNearbyFieldsTile: (state, action) => {
      if(action.payload.tile && action.payload.fields)
      {
        console.log(action.payload.tile,action.payload.fields)

        console.log(action.payload.tile,Object.keys(state.nearbyFields),state.nearbyFields)
        state.nearbyFields[action.payload.tile] = {
          fields:action.payload.fields,
          tile:action.payload.tile
        }
        state.shownTiles[action.payload.tile] = true
      }
    },
    reducerSetSearchingNearbyFieldsTile: (state, action) => {
      if(action.payload.tile)
      {
        state.searchingTiles[action.payload.tile] = true
      }
    },
    reducerUnsetSearchingNearbyFieldsTile: (state, action) => {
      if(action.payload)
      {
        state.searchingTiles[action.payload] = false
      }
    },
    reducerSetSelectedFavoriteField: (state, action) => {
      state.selectedFavoriteField = action.payload;
    },
    reducerSetFavoriteFields: (state, action) => {
      state.favoriteFields = action.payload;
    },
    reducerSetCreatingFavoriteField: (state, action) => {
      state.creatingFavoriteField = action.payload;
    },
    reducerSetSelectedFavoriteFieldOverlayURL: (state, action) => {
      state.selectedFavoriteFieldOverlayURL = action.payload;
    },
    reducerSetZoomInReminderVisible: (state, action) => {
      state.zoomInReminderVisible = action.payload;
    },
    reducerSetOverlayTransparency: (state, action) => {
      state.overlayTransparency = action.payload;
    },
    reducerSetUniqueCropColors: (state, action) => {
      state.uniqueCropColors = action.payload;
    },

  },
});


export default fieldsSlice.reducer;

