import { createSlice } from "@reduxjs/toolkit";

export const mainSlice = createSlice({
  name: "main",
  initialState: {
    page: 'Explore',//deprecated
    permissions: [],
    sidebarOpen:true,
    view:"Map",
    favoritesSidebarOpen:true,
    currentView:"fields"
  },
  reducers: {
    reducerSetPage: (state, action) => {
      state.page = action.payload;
    },
    reducerSetPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    reducerSetLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    reducerSetSidebarOpen: (state, action) => {
      state.sidebarOpen = action.payload;
    },
    reducerSetView: (state, action) => {
      state.view = action.payload;
    },
    reducerSetFavoritesSidebarOpen: (state, action) => {
      state.favoritesSidebarOpen = action.payload;
    },
    reducerSetCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
  },
});


export default mainSlice.reducer;

