import React from "react";

import { withAuthenticator } from "@aws-amplify/ui-react";

const LoginView = () => {
  return (
    <div />
  );
};

const AuthedLoginView = withAuthenticator(LoginView);

function LoginOverlay({ open }) {
  if (open) {
    console.log("showing auth login");
    return (
      <div style={{width:'100%',height:'100%',position:'fixed',top:0,left:0}}>
      <AuthedLoginView />
      </div>
    );
  } else {
    console.log("not showing auth login");
    return <div />;
  }
}
export default LoginOverlay;
