import React from "react";
import ProfileButton from "./ProfileButton";

function ProfileOverlay() {
  return (
    <div
      style={{
        position: "absolute",
        right: 15,
        height:80
      }}
    >
      <ProfileButton />
    </div>
  );
}
export default ProfileOverlay;
