import { configureStore } from '@reduxjs/toolkit'
import fieldsReducer from "./reducers/fieldsReducer"
import mainReducer from './reducers/mainReducer';
import userReducer from './reducers/userReducer';
import shedsReducer from './reducers/shedsReducer';
export default configureStore({
  reducer: {
    fields: fieldsReducer,
    main: mainReducer,
    user: userReducer,
    sheds: shedsReducer
  },
});
