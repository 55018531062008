import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LockIcon from '@mui/icons-material/Lock';

function ExpandButton({buttonPressed,isExpanded,label,isLocked,isEnabled, selectedColor}) {

  var color = "#3D414B"
  if(isLocked)
  {
    color = "#8C909B"
  }
  if(isExpanded)
  {
    color = "#FFFFFF"
  }
  if(!isEnabled)
  {
    color = "#8C909B"
  }

  var arrowColor = "#585A61"
  if(isLocked)
  {
    arrowColor = "#8C909B"
  }
  if(isExpanded)
  {
    arrowColor = "#FFFFFF"
  }
  if(!isEnabled)
  {
    arrowColor = "#8C909B"
  }

  const pressed = () => {
    if(!isLocked || true)//temp
    {
      buttonPressed()
    }
  }
  return (
    <div style={{cursor:"pointer",marginTop:10,width:275,height:45,display:'flex',flexDirection:"row",justifyContent:'center',alignItems:'center',borderRadius:8,backgroundColor:isExpanded?selectedColor:"#F1F3F8"}}
      onClick={pressed}>
      <label style={{marginLeft:24,cursor:"pointer",fontWeight:isExpanded?"600":"500",fontSize:14,color:color}}>{label}</label>
      {isLocked && isEnabled && <LockIcon style={{marginLeft:5,cursor:"pointer",color:arrowColor,fontSize:15}}/>}
      <div style={{flex:1}}/>
      <ArrowForwardIosIcon style={{marginRight:21,cursor:"pointer",color:arrowColor,fontSize:12}}/>
    </div>
  );
}
export default ExpandButton;
