import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import AuthService from "../../../statics/authService";
import { Auth } from "aws-amplify";
import { Hub } from "aws-amplify";
import AuthLogin from "./AuthLogin";
import { useSelector } from "react-redux";
import { setFavoriteFieldsOpen } from "../../../redux/actions/fields";
import { setLoggedIn } from "../../../redux/actions/user";
import * as Theme from "../../../statics/theme" 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ProfilePopUp from "./ProfilePopUp";

function ProfileButton() {
  const [loggingIn, setLoggingIn] = useState(false);
  const [profile, setProfile] = useState(null);
  const [hidden, setHidden] = useState(true);
  const userProfile = useSelector((state) => state.user.profile);

  //Update the static user function when it changes
  useEffect(() => {
    AuthService.user = profile;
  }, [profile]);

  useEffect(() => {
    //Check if we are already logged in on init
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user && user.attributes) {
          setLoggingIn(false);
          setProfile(user.attributes.email);
          setLoggedIn(true)
        }else{
          setLoggedIn(false)
        }
      })
      .catch((err) => {});

    //Listen for Auth events to update our state accordingly
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          setLoggingIn(false);
          if (data.payload.data && data.payload.data.attributes) {
            setProfile(data.payload.data.attributes.email);
          }
          console.log("AuthEvent: User signed in");
          setHidden(true)
          setLoggedIn(true)
          break;
        case "signUp":
          console.log("AuthEvent: User signed up");
          setHidden(true)
          setLoggedIn(true)
          break;
        case "signOut":
          setProfile(null);
          setLoggingIn(false);
          console.log("AuthEvent: User signed out");
          setFavoriteFieldsOpen(false)
          setHidden(true)
          setLoggedIn(false)
          break;
        default:
          console.log("AuthEvent:" + data.payload.event.toString())
          break;
      }
    });
  }, []);

  const handleProfileClicked = () => {
    if (profile == null) {
      //We aren't logged in, bring up the log in screen
      setLoggingIn(true);
    } else {
      //We are logged in
      setHidden(!hidden)
    }
  };
  return (
    <div
      style={{ cursor: "pointer", display:'flex',flexDirection:'row',justifyConten:'center',alignItems:'center',height:'100%'}}
    >
      <AuthLogin open={loggingIn} />
      {!hidden && <ProfilePopUp logout={()=>{Auth.signOut();setLoggingIn(false);}} profile={profile}/>}
      <div onClick={handleProfileClicked} style={{backgroundColor:"white", height:30,borderRadius:20,padding:5,paddingLeft:15,paddingRight:15,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
        <Avatar style={{ width: 25, height: 25,backgroundColor:"#0F330F"}} />
        {profile != null &&
          <label style={{color:'#585A61',marginLeft:10,fontWeight:'500',fontSize:14,cursor:'pointer'}}>{(userProfile && userProfile.firstName) + " " + (userProfile && userProfile.lastName)}</label>
        }
        {profile != null &&
          <KeyboardArrowDownIcon style={{fontSize:15,marginLeft:10,color:'#585A61'}}/>
        }
      </div>
    </div>
  );
}
export default ProfileButton;
