import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutImage from '../../../images/Logout.png';
import LogoutIcon from '@mui/icons-material/Logout';

function ProfilePopUp({profile,logout}) {
  const userProfile = useSelector((state) => state.user.profile);
  return (
   <div style={{display:'flex',flexDirection:'column',alignItems:"center",background:"white",width:265,borderRadius:20,borderTopRightRadius:0,boxShadow:"1px 0px 20px rgba(48, 68, 120, 0.5)",padding:'24px 22px',
              position:'fixed',top:75,right:15}}>
      <div style={{width:235,backgroundColor:"#F1F3F8",borderRadius:11,padding:"15px 20px",display:'flex',flexDirection:'row',alignItems:'center'}}>
        <Avatar style={{ width: 56, height: 56,backgroundColor:"#0F330F"}} />
        <div style={{marginLeft:20,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
          <label style={{fontWeight:'700',fontSize:16,marginBottom:5}}>{(userProfile && userProfile.firstName) + " " + (userProfile && userProfile.lastName)}</label>
          <label style={{fontWeight:'400',fontSize:14,color:"#585A61"}}>{(userProfile && userProfile.email)}</label>
        </div>
      </div>
      <div style={{width:235,marginTop:20,display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <div><ArticleIcon style={{fontSize:18}}/></div>
        <label style={{fontSize:14,height:25,marginLeft:10}}>Downloaded Reports</label>
        <div style={{flex:1}}/>
        <KeyboardArrowDownIcon style={{fontSize:15,marginLeft:10,color:'#585A61'}}/>
      </div>
      <div style={{width:235,height:25,marginTop:15,display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <div><SettingsIcon style={{fontSize:18}}/></div>
        <label style={{fontSize:14,marginLeft:10}}>Settings</label>
        <div style={{flex:1}}/>
        <KeyboardArrowDownIcon style={{fontSize:15,marginLeft:10,color:'#585A61'}}/>
      </div>
      <div style={{width:235,height:1,marginTop:10,border:"1px solid #DCE0E7",borderWidth:0,borderBottomWidth:2}}/>
      <div style={{width:235,height:25,marginTop:20,display:'flex',flexDirection:'row',justifyContent:'center',cursor:'pointer'}} onClick={()=>logout()}>
        <div> <LogoutIcon style={{fontSize:18}}/></div>
        <label style={{fontSize:14,marginLeft:10,fontWeight:'700',cursor:'pointer'}}>Log Out</label>
        <div style={{flex:1}}/>
      </div>
   </div>
  );
}
export default ProfilePopUp;
