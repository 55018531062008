import React from "react";
import FavoriteButtonSheds from "./FavoriteButtonSheds"
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import RoomIcon from '@mui/icons-material/Room';
import CropFreeIcon from '@mui/icons-material/CropFree';
import NumbersIcon from '@mui/icons-material/Numbers';
import * as Theme from "../../statics/theme"

function ShedHeader({ selectedShed, shedData }) {

  var selectedShedId = null
  if(selectedShed != null)
  {
    selectedShedId = selectedShed.shed_id
  }
  var shedBasicInfo = null
  if(shedData != null)
  {
    shedBasicInfo = shedData.basic_info
  }
  var acreage = 0
  if(shedBasicInfo && shedBasicInfo["acreage"] != null)
  {
    acreage = (parseInt(parseFloat(shedBasicInfo["acreage"])*100)/100).toLocaleString("en-US");
  }
  var fieldCount = 0
  if(shedBasicInfo && shedBasicInfo["field_count"] != null)
  {
    fieldCount = shedBasicInfo["field_count"].toLocaleString("en-US");
  }

  var svg = ''
  if(shedData && shedData.field_svg)
  {
    svg = shedData.field_svg
    svg = svg.replaceAll('opacity="0.6"','opacity="1.0"').replaceAll('fill="#66cc99"','fill="#0F330F"').replaceAll('fill="#ff3333"','fill="#0F330F"').replaceAll('stroke="#555555"','stroke="#ffffff"')
  }

  var countyName = ''
  if(shedBasicInfo && shedBasicInfo.county_name)
  {
    countyName = shedBasicInfo.county_name
  }
  var stateName = ''
  if(shedBasicInfo && shedBasicInfo.state_name)
  {
    stateName = shedBasicInfo.state_name
  }

  

  return (
    <div style={{width:275,height:140,backgroundColor:"#F1F3F8",borderRadius:11}}>
      {(!selectedShed || !shedData)
      ?
        <div style={{width:"100%",height:"100%",display:"flex",flexDirection:'column',justifyContent:"center",alignItems:'center'}}>
          <ZoomInIcon style={{fontSize:60,marginBottom:10,color:Theme.DARK_GRAY_1}} />
            <label style={{color:Theme.DARK_GRAY_1,fontSize:13,fontWeight:'600'}}>Zoom in to see and select sheds</label>
        </div>
      :
        <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"row",position:"relative"}}>
            <div style={{width:65,height:65,marginLeft:10,marginRight:10,marginTop:15,borderRadius:11,backgroundColor:"#0F330F",display:"flex",alignItems:"center",justifyContent:"center"}}>
              <img style={{width:50,height:50}} src={`data:image/svg+xml;base64,${btoa(svg)}`}/>
            </div>
            <div style={{height:"100%",flex:1,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}}>
              <label style={{fontWeight:'700',fontSize:18,color:Theme.BLACK_3}}>{'Cumberland'}</label>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:10}}>
                  <RoomIcon style={{color:Theme.DARK_GRAY_4,fontSize:15}} />
                  <label style={{color:Theme.DARK_GRAY_4,fontSize:12,marginLeft:5}}>{countyName + ", " + stateName}</label>
              </div>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:5}}>
                  <CropFreeIcon style={{color:Theme.DARK_GRAY_4,fontSize:15}} />
                  <label style={{color:Theme.DARK_GRAY_4,fontSize:12,marginLeft:5}}>{"Shed Size: " + acreage + " acres"}</label>
              </div>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:5}}>
                  <NumbersIcon style={{color:Theme.DARK_GRAY_4,fontSize:15}} />
                  <label style={{color:Theme.DARK_GRAY_4,fontSize:12,marginLeft:5}}>{"Count: " + fieldCount + " fields"}</label>
              </div>
            </div>
            <div style={{position:"absolute",top:15,right:15}}>
              <FavoriteButtonSheds selectedShed={selectedShed}/>
            </div>
        </div>
      }
    </div>
  )
}
export default ShedHeader;
