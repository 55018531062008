import React from "react";
import Logo from "./Logo";
import ProfileButton from "./ProfileButton"
import ViewSelect from "./ViewSelect"
import * as Theme from "../../statics/theme" 
//This is currently the main page for our website. It displays a map with
//overlays to interact with our fields
function Topbar() {
  return (
    <div style={{zIndex:10000000,width:'100%',height:80,backgroundColor:'#F2F2F2', display:'flex', justifyContent:'center',alignItems:'center',flexDirection:'row',backgroundColor:Theme.TOPBAR_NAVY}}>
      <Logo/>
      <ViewSelect />
      <ProfileButton />
    </div>
  );
}
export default Topbar;
