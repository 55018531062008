import React from "react";
import { useSelector } from "react-redux";
import Slider from '@mui/material/Slider';
import LegendConfig from "./legendConfig";
import { withStyles } from '@material-ui/styles';
import { ThemeContext } from "@emotion/react";
import * as Theme from "../../../../statics/theme"
function TransparencyLegend({type, transparency, setTransparency}) {
  
  const handleChange = (event, newValue) => {
    setTransparency(newValue);
  };

  if(!type || !LegendConfig[type] || !LegendConfig[type].showTransparency)
  {
    return <div/>
  }

  return (
    <div
      style={{
        width:290,
        display:'flex',
        flexDireciton:'row',
        alignItems:'center',
        marginBottom:10
      }}
    >
      <div style={{marginRight:10}}>
        <label style={{fontWeight:'600',fontSize:14,color:ThemeContext.BLACK_4}}>{"Transparency: "}</label>
      </div>
        <CustomSlider aria-label="Overlay Transparency" min={0} max={1.0} step={.1} value={transparency} onChange={handleChange} />
      {/*<div style={{marginLeft:10}}>
        <label style={{}}>{overlayTransparency}</label>
    </div>*/}
    </div>
  );
}
const CustomSlider = withStyles({
  root: {
    height: 4,
},
  rail: {
    color: '#B4B8C4',
    height:4,
    borderRadius:10
  },
    track: {
      color: '#467146',
      height:4,
      borderRadius:10
    },
    thumb: {
      color: "#274627",
      height:12,
      width:12
    },
})(Slider);
export default TransparencyLegend;
