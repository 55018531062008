import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotateLabels from 'chartjs-plugin-annotation'
import * as Theme from "../../../../statics/theme"

import { Bar } from 'react-chartjs-2';
const colors = ["#2697FF","#FB7F2D","#3EB96C","#33674E","#3D93A6","#1A9275"]
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartAnnotateLabels
);
//ChartJS.plugins.register(ChartAnnotateLabels);
const optionsAverageGraph = {
  responsive: true,
  maintainAspectRatio:false,
  plugins: {
    annotation: {
      annotations: [
        {
          type: 'line',
          scaleID: 'x',
          borderWidth: 1,
          borderColor: '#B4B8C4',
          value: 3.5,
        }
      ]
    },
    "tooltip": {
      "enabled": false
    },
    legend: {
      position: 'top',
      display:false
    },
    title: {
      display: false,
    },
    datalabels: {
      display: true,
      color: "black",
      formatter: function(value,{dataIndex}) {
          return value.toFixed(2)
      },
      color: function(context) {
        var index = context.dataIndex;
        return colors[index]
      },
      font: function(context) {
        var index = context.dataIndex;
        if(index == 3)
        {
          return {
            size:12,
            weight:'700'
          }
        }
        else {
          return {
            size:12,
            weight:'500'
          }
        }
      },
      anchor: "end",
      offset: -18,
      align: "start"
    }
  },
  scales: {
    y: {
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
              if(Number.isInteger(value) && value >= 0)
              {
                return value.toFixed(0);
              }
            },
            color:'#B4B8C4',
            beginAtZero:false
        },
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:1,
          lineWidth:1
        }
    },
    x: {
        // increase font for the labels b/c of the subscripted N2O / CH4 
        ticks: {
          font: {
            size: 14
          }
        },
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:0,
          lineWidth:0
        }
    },
  }
};
const optionsYearlyGraph = {
  responsive: true,
  maintainAspectRatio:false,
  plugins: {
    "tooltip": {
      "enabled": false
    },
    legend: {
      position: 'top',
      display:false
    },
    title: {
      display: false,
    },
    datalabels: {
      display: true,
      color: "black",
      formatter: function(value,{dataIndex}) {
          return value.toFixed(1)
      },
      color: Theme.DARK_GREEN_1,
      font: function(context) {
          return {
            size:12,
            weight:'500'
          }
      },
      anchor: "end",
      offset: -18,
      align: "start"
    }
  },
  scales: {
    y: {
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
              if(Number.isInteger(value))
              {
                return value.toFixed(0);
              }
            },
            color:'#B4B8C4',
            beginAtZero:true
        },
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:1,
          lineWidth:1
        }
    },
    x: {
        grid: {
          borderColor:'#DCE0E7',
          color:'#DCE0E7',
          borderWidth:0,
          lineWidth:0
        }
    },
  }
};
const labels = ['SOC','N\u2082O','CH\u2084','Total','State\naverage'];


const CarbonIntensityBox = ({emissionsData, label}) => {
  if(!emissionsData)
  {
    return <div/>
  }

  var averageVal = emissionsData["average_carbon_per_field"] ? emissionsData["average_carbon_per_field"] : 0
    return (
      <div style={{borderRadius:10,boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)',border:'1px solid #DCE0E7',height:137,flex:1}}>
        <div style={{width:'100%',display:'flex'}}>
          <label style={{marginLeft:24,marginTop:14,marginRight:24,color:Theme.BLACK_4,fontSize:16,fontWeight:'500',textAlign:'left'}}>{"Average GHG emissions of " + String(label) + " in this shed"}</label>
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
          <label style={{marginLeft:25,color:Theme.DARK_GREEN_1,fontWeight:'700',fontSize:48}}>{averageVal.toFixed(2)}</label>
          <div style={{display:'flex',marginLeft:10,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
            <label style={{color:Theme.DARK_GRAY_4,fontSize:16, marginTop: 5}}><b>Tons</b></label>
            <label style={{color:Theme.DARK_GRAY_4,fontSize:14,fontWeight:'500'}}>CO<sub>2</sub>eq / ac /yr</label>
          </div>
        </div>
      </div>
    )
}
const VersusNationalBox = ({emissionsData, label}) => {
  if(!emissionsData)
  {
    return <div/>
  }

  var perc = emissionsData["percentage_below_nat_per_acre"] ? emissionsData["percentage_below_nat_per_acre"] : 0
  if(perc < 0) {
    return (
      <div style={{marginLeft:12,borderRadius:10,boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)',border:'1px solid #DCE0E7',height:137,flex:1}}>
        <div style={{width:'100%',display:'flex'}}>
          <label style={{marginLeft:24,marginTop:14,marginRight:24,color:Theme.BLACK_4,fontSize:16,fontWeight:'500',textAlign:'left'}}>{"Average GHG emissions of " + String(label) + " in this shed"}</label>
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
          <label style={{marginLeft:25,color:Theme.RED_1,fontWeight:'700',fontSize:48}}>{perc.replace('-', '') + "%"}</label>
          <label style={{marginLeft:10, fontSize:14,color:Theme.DARK_GRAY_4,textAlign:'left'}}><b>above state average </b><br></br>(last 5 years)</label>
        </div>
      </div>
    )
  } else if (perc > 0) {
    return (
      <div style={{marginLeft:12,borderRadius:10,boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)',border:'1px solid #DCE0E7',height:137,flex:1}}>
        <div style={{width:'100%',display:'flex'}}>
          <label style={{marginLeft:24,marginTop:14,marginRight:24,color:Theme.BLACK_4,fontSize:16,fontWeight:'500',textAlign:'left'}}>{"Average GHG emissions of " + String(label) + " in this shed"}</label>
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
          <label style={{marginLeft:25,color:Theme.DARK_GREEN_1,fontWeight:'700',fontSize:48}}>{perc + "%"}</label>
          <label style={{marginLeft:10,fontSize:14,color:Theme.DARK_GRAY_4,textAlign:'left'}}><b>below state average</b><br></br>(last 5 years)</label>
        </div>
      </div>
    )
  } else {
    return (
      <div style={{marginLeft:12,borderRadius:10,boxShadow:'0px 2px 15px rgba(48, 68, 120, 0.16)',border:'1px solid #DCE0E7',height:137,flex:1}}>
        <div style={{width:'100%',display:'flex'}}>
          <label style={{marginLeft:24,marginTop:14,marginRight:24,color:Theme.BLACK_4,fontSize:16,fontWeight:'500',textAlign:'left'}}>{"Average GHG emissions of " + String(label) + " in this shed (last 5 years"}</label>
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
          <label style={{marginLeft:25,color:Theme.DARK_GREEN_1,fontWeight:'700',fontSize:48}}>{""}</label>
          <label style={{marginLeft:10,fontSize:14,color:Theme.DARK_GRAY_4,textAlign:'left'}}><b>equal to state average</b></label>
        </div>
      </div>
    )
  }
}

// Unused annual GHG emission graph as of 1/25/23 Demo Comments
const YearlyGraph = ({emissionsData}) =>{
  if(!emissionsData || !emissionsData["emissions_by_year"])
  {
    return
  }
  var baseData = {
    labels:Object.keys(emissionsData["emissions_by_year"]),
    datasets: [
      {
        data: [],
        borderRadius:6,
        backgroundColor: Theme.DARK_GREEN_1,
        barThickness:29
      },
    ],
  };

  var perc = "0"
  if(emissionsData["lower_than_percentage"])
  {
    perc = parseInt(emissionsData["lower_than_percentage"]*100)
  }

  baseData.datasets[0]['data'] = Object.keys(emissionsData["emissions_by_year"]).map(year=>emissionsData["emissions_by_year"][year])
  return (
    <div style={{flex:1,height:260,marginTop:20,display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
      <label style={{color:'#999DA9',fontSize:12,fontWeight:'500',float:'left',marginLeft:20}}>Tons CO<sub>2</sub>eq / ac / yr</label>
      <div style={{width:'90%',height:230}}>
        <Bar options={optionsYearlyGraph} plugins={[ChartDataLabels]}  data={baseData}/>
      </div>
      {/* <label style={{marginLeft:15,fontSize:12,fontWeight:'500',color:Theme.BLACK_4,marginTop:20}}>Carbon intensity of corn in this field</label>
      <label style={{marginLeft:15,fontSize:20,fontWeight:'700',color:Theme.DARK_GREEN_1,marginTop:2}}>{"is lower than " + perc + "%"}</label> */}
    </div>
  )
} 
const AverageGraph = ({emissionsData}) =>{
  if(!emissionsData || !emissionsData["emissions"])
  {
    return
  }
  
  var baseData = {
    labels,
    datasets: [
      {
        data: [],
        borderRadius:6,
        backgroundColor: colors,
        barThickness:50
      },
    ],
  };

  baseData.datasets[0]['data'] = [emissionsData["emissions"]['soc'],emissionsData["emissions"]['n2o'],emissionsData["emissions"]['ch4'],emissionsData["emissions"]['ghg'],emissionsData["emissions"]['state_average']]
  return (
    <div style={{width:'100%',height:275,marginTop:30}}>
      <label style={{color:'#999DA9',fontSize:12,fontWeight:'500',float:'left',marginLeft:20}}>Tons CO<sub>2</sub>eq / ac / yr</label>
      <Bar options={optionsAverageGraph} plugins={[ChartDataLabels]}  data={baseData}/>
    </div>
  )
}
const QuartileChart = ({emissionsData}) =>{
  if(!emissionsData || !emissionsData["quartiles"])
  {
    return
  }

  const getPerAcre = (quartile) => {
    var val = "0.0"
    if(emissionsData["quartiles"] && emissionsData["quartiles"][quartile] && emissionsData["quartiles"][quartile]["per_acre"])
    {
      val = emissionsData["quartiles"][quartile]["per_acre"].toFixed(1)
    }
    return val
  }
  const getPerc = (quartile) => {
    var val = "0.0"
    var prefix = "+"
    if(emissionsData["quartiles"] && emissionsData["quartiles"][quartile] && emissionsData["quartiles"][quartile]["vs_national"])
    {
      val = parseFloat(emissionsData["quartiles"][quartile]["vs_national"])
      if(val < 10 && val > -10)
      {
        val = val.toFixed(1)
      } else {
        val = parseInt(val).toString()
      }
      val = val.replace("-","")
      if(parseFloat(emissionsData["quartiles"][quartile]["vs_national"]) > 0)
      {
        prefix = "-"
      }
    }
    return "(" + prefix + val + "%" + ")"
  }
  const getPercColor = (quartile) => {
    var val = 0
    if(emissionsData["quartiles"] && emissionsData["quartiles"][quartile] && emissionsData["quartiles"][quartile]["vs_national"])
    {
      val = parseFloat(emissionsData["quartiles"][quartile]["vs_national"])
    }
    if(val < 0)
    {
      return Theme.RED_1
    } else if(val > 0)
    {
      return Theme.LIGHT_GREEN_2
    } else {
      return Theme.BLACK_4
    }
  }

  return (
    <div style={{width:'100%',height:'fit-content',marginTop:30,borderRadius:10,display:'flex',flexDirection:'row'}}>
      <div style={{flex:1,borderRight:'1px solid #DCE0E7',background:'linear-gradient(180deg, #F8F8FA 52.08%, rgba(241, 243, 248, 0.3) 100%)',borderRadius:'10px 0px 0px 10px',
                  paddingLeft:20,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
          <div style={{height:60,display:'flex',justifyContent:'center',alignItems:'center'}}>
            <label style={{fontSize:12,fontWeight:'700',color:Theme.BLACK_4}}>Quartile with least emission at top</label>
          </div>

          <div style={{borderBottom:'1px solid #DCE0E7',height:0,width:'100%'}} />

          <div style={{height:112,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <div style={{flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
              <label style={{fontSize:12,fontWeight:'500',color:Theme.BLACK_4}}>Top 25%</label>
            </div>
            <div style={{flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
              <label style={{fontSize:12,fontWeight:'500',color:Theme.BLACK_4}}>Upper middle 25%</label>
            </div>
            <div style={{flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
              <label style={{fontSize:12,fontWeight:'500',color:Theme.BLACK_4}}>Lower middle 25%</label>
            </div>
            <div style={{flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
              <label style={{fontSize:12,fontWeight:'500',color:Theme.BLACK_4}}>Bottom 25%</label>
            </div>
          </div>

          <div style={{borderBottom:'1px solid #DCE0E7',height:0,width:'100%'}} />

          <div style={{height:34,display:'flex',justifyContent:'center',alignItems:'center'}}>
            <label style={{fontSize:12,fontWeight:'500',color:Theme.BLACK_4}}>Shed Average</label>
          </div>

          <div style={{borderBottom:'1px solid #DCE0E7',height:0,width:'100%'}} />

          <div style={{height:42,display:'flex',justifyContent:'center',alignItems:'center'}}>
            <label style={{fontSize:12,fontWeight:'700',color:Theme.BLACK_4}}>State Average</label>
          </div>


      </div>
      <div style={{flex:1,background:'linear-gradient(180deg, #F8F8FA 52.08%, rgba(241, 243, 248, 0.3) 100%)',borderRadius:'0px 10px 10px 0px',
                  paddingRight:20,display:'flex',flexDirection:'column'}}>
          <div style={{height:60,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <label style={{fontSize:12,fontWeight:'700',color:Theme.BLACK_4}}>Unit: Tons CO<sub>2</sub>eq / ac / yr</label>
            <label style={{fontSize:12,fontWeight:'500',color:Theme.LIGHT_GREEN_1,marginTop:3}}>(v.s. state avg.)</label>
          </div>

          <div style={{borderBottom:'1px solid #DCE0E7',height:0,width:'100%'}} />

          <div style={{height:112,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <div style={{flex:1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <label style={{fontSize:12,fontWeight:'500',color:Theme.BLACK_4}}>{getPerAcre("top_25")}</label>
              <label style={{fontSize:12,fontWeight:'700',color:getPercColor("top_25"),marginLeft:2}}>{getPerc("top_25")}</label>
            </div>
            <div style={{flex:1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <label style={{fontSize:12,fontWeight:'500',color:Theme.BLACK_4}}>{getPerAcre("upper_middle_25")}</label>
              <label style={{fontSize:12,fontWeight:'700',color:getPercColor("upper_middle_25"),marginLeft:2}}>{getPerc("upper_middle_25")}</label>
            </div>
            <div style={{flex:1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <label style={{fontSize:12,fontWeight:'500',color:Theme.BLACK_4}}>{getPerAcre("lower_middle_25")}</label>
              <label style={{fontSize:12,fontWeight:'700',color:getPercColor("lower_middle_25"),marginLeft:2}}>{getPerc("lower_middle_25")}</label>
            </div>
            <div style={{flex:1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <label style={{fontSize:12,fontWeight:'500',color:Theme.BLACK_4}}>{getPerAcre("bottom_25")}</label>
              <label style={{fontSize:12,fontWeight:'700',color:getPercColor("bottom_25"),marginLeft:2}}>{getPerc("bottom_25")}</label>
            </div>
          </div>

          <div style={{borderBottom:'1px solid #DCE0E7',height:0,width:'100%'}} />

          <div style={{height:34,display:'flex',justifyContent:'center',alignItems:'center'}}>
              <label style={{fontSize:12,fontWeight:'500',color:Theme.BLACK_4}}>{getPerAcre("average")}</label>
              <label style={{fontSize:12,fontWeight:'700',color:getPercColor("average"),marginLeft:2}}>{getPerc("average")}</label>
          </div>

          <div style={{borderBottom:'1px solid #DCE0E7',height:0,width:'100%'}} />

          <div style={{height:42,display:'flex',justifyContent:'center',alignItems:'center'}}>
            <label style={{fontSize:12,fontWeight:'700',color:Theme.BLACK_4}}>
              {(emissionsData['emissions']["state_average"]).toFixed(2)}
            </label>
          </div>
      </div>
    </div>
  )
}


function Emissions({allowClose,label,emissionsData,hint}) {
  const [open, setOpen] = useState(true);
  if(!emissionsData)
  {
    return <div/>
  }
  var labelColor = "white"
  var labelTextColor = Theme.DARK_GREEN_2
  if(label != null)
  { 
    if(label == "corn")
    {
      labelColor = Theme.YELLOW
    }
    else
    {
      labelColor = "#33674E"
      labelTextColor = "white"
    }
  }

  var perc = "0"
  if(emissionsData["lower_than_percentage"])
  {
    perc = parseInt(emissionsData["lower_than_percentage"]*100)
  }
  return (
    <div style={{borderRadius:14,overflow:'hidden',marginBottom:38,position:'relative',marginLeft:45,marginRight:45,height:open?'fit-content':80,display:"flex",flexDirection:"column",alignItems:"flex-start",boxShadow:"0px 2px 15px rgba(48, 68, 120, 0.16)"}}>
          {label != null && <div style={{marginLeft:34,marginBottom:-16,display:"flex",flexDirection:'row',alignItems:'center',justifyContent:'flex-start',marginTop:25}}>
                <label style={{fontSize:32,fontWeight:"700",fontSize:22,color:Theme.BLACK_4}}>GHG Emissions</label>
                {hint != null &&
                  <div style={{marginLeft:7,width:16,height:16,border:'1px solid grey',cursor:'pointer', borderColor:Theme.DARK_GRAY_1,borderRadius:16,display:'flex',alignItems:'center',justifyContent:'center',position:'relative'}}
                    onMouseOver={()=>document.getElementById("hidden-div-sustainability").style.display="block"} onMouseOut={()=>document.getElementById("hidden-div-sustainability").style.display="none"}>
                    <label fitcon style={{color:Theme.DARK_GRAY_1,fontSize:12,cursor:'pointer'}}>i</label>
                    <div id="hidden-div-sustainability" style={{display:'none',height:30,width:500,position:'absolute',top:16,left:16}}>
                      <div style={{width:'fit-content',boxShadow:"1px 0px 20px rgba(82, 92, 118, 0.1)",borderRadius:"0px 6px 6px 6px",border:"1px solid gray",borderColor:Theme.LIGHT_GRAY_1,padding:'2px 12px 6px 12px'}}>
                        <label style={{color:Theme.DARK_GRAY_4,fontSize:12,width:'100%'}} >{hint}</label>
                      </div>
                    </div>
                  </div>
                }
                  <div style={{paddingLeft:14,marginLeft:10,paddingRight:14,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:18,height:30,backgroundColor:labelColor}}>
                      <label style={{color:labelTextColor,fontSize:20,fontWeight:700}}>{label}</label>
                  </div>
                
              </div>}
        {allowClose && <div style={{cursor:'pointer',position:'absolute',padding:5,right:32,top:32}} onClick={()=>setOpen(!open)}>
            {
              open
              ?
              <KeyboardArrowUpIcon style={{cursor:'pointer',fontSize:20, color:Theme.DARK_GRAY_4}}/>
              :
              <KeyboardArrowDownIcon style={{cursor:'pointer',fontSize:20, color:Theme.DARK_GRAY_4}}/>
            }
          </div>
        }
        {open &&
          <div style={{marginTop:40,lex:1,display:'flex',flexDirection:'row'}}>
            <div style={{marginLeft:34,flex:1,marginRight:34}}>
              <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                <CarbonIntensityBox emissionsData={emissionsData} label={label}/>
                <VersusNationalBox emissionsData={emissionsData} label={label}/>
              </div>
              <div style={{marginTop:40,display:"flex",flexDirection:'row',alignItems:'center',justifyContent:'flex-start'}}>
                <label style={{fontSize:32,fontWeight:"700",fontSize:20,color:Theme.BLACK_4}}>GHG emission (average over 2017-2021)</label>
                {/* <div style={{marginLeft:7,width:16,height:16,border:'1px solid grey',cursor:'pointer', borderColor:Theme.DARK_GRAY_1,borderRadius:16,display:'flex',alignItems:'center',justifyContent:'center',position:'relative'}} */}
                    {/* onMouseOver={()=>document.getElementById("hidden-div-sustainability-shed" + (label?label:"")).style.display="block"} onMouseOut={()=>document.getElementById("hidden-div-sustainability-shed" + (label?label:"")).style.display="none"}> */}
                    {/* <label fitcon style={{color:Theme.DARK_GRAY_1,fontSize:12,cursor:'pointer'}}>i</label> */}
                    <div id={"hidden-div-sustainability-shed" + (label?label:"")} style={{display:'none',height:30,width:500,position:'absolute',top:16,left:16}}>
                      <div style={{width:'fit-content',boxShadow:"1px 0px 20px rgba(82, 92, 118, 0.1)",borderRadius:"0px 6px 6px 6px",border:"1px solid gray",borderColor:Theme.LIGHT_GRAY_1,padding:'2px 12px 6px 12px'}}>
                        <label style={{color:Theme.DARK_GRAY_4,fontSize:12,width:'100%'}} >{"(2017-2021) by acre"}</label>
                      </div>
                    </div>
                  {/* </div> */}
              </div>

              <AverageGraph emissionsData={emissionsData}/>


              <div style={{marginTop:40,display:"flex",flexDirection:'row',alignItems:'center',justifyContent:'flex-start'}}>
                <label style={{fontSize:32,fontWeight:"700",fontSize:20,color:Theme.BLACK_4}}>Average emissions by quartile</label>
                <div style={{marginLeft:7,width:16,height:16,border:'1px solid grey',cursor:'pointer', borderColor:Theme.DARK_GRAY_1,borderRadius:16,display:'flex',alignItems:'center',justifyContent:'center',position:'relative'}}
                    onMouseOver={()=>document.getElementById("hidden-div-sustainability-shed-2" + (label?label:"")).style.display="block"} onMouseOut={()=>document.getElementById("hidden-div-sustainability-shed-2" + (label?label:"")).style.display="none"}>
                    <label fitcon style={{color:Theme.DARK_GRAY_1,fontSize:12,cursor:'pointer'}}>i</label>
                    <div id={"hidden-div-sustainability-shed-2" + (label?label:"")} style={{display:'none',height:30,width:500,position:'absolute',top:16,left:16}}>
                      <div style={{width:'fit-content',boxShadow:"1px 0px 20px rgba(82, 92, 118, 0.1)",borderRadius:"0px 6px 6px 6px",border:"1px solid gray",borderColor:Theme.LIGHT_GRAY_1,padding:'2px 12px 6px 12px'}}>
                        <label style={{color:Theme.DARK_GRAY_4,fontSize:12,width:'100%'}} >{"(2017-2021) by acre"}</label>
                      </div>
                    </div>
                  </div>
              </div>

              <QuartileChart emissionsData={emissionsData} />

              <div style={{width:'100%',height:20}}/>
            </div>
          </div>
            
            }
    </div>
  );
}
export default Emissions;
